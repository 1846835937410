import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, } from 'react';
import * as ProjectAPI from 'api/project-api';
import * as TeamAPI from 'api/team-api';
import { deriveTagColor } from 'libs/tag-style';
import { useSession } from 'components/Authenticated';
import { Spin, App, Flex, Select, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import { addCurrentTeamTag } from 'api/team-api';
import { filterOption } from 'libs/filterOption';
import BeeyTag from 'components/BeeyTag';
import styles from './style.module.less';
const TagsPanel = ({ project, enqueueProjectUpdate, onTagsChange, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [loadingTags, setLoadingTags] = useState([]);
    const [teamTags, setTeamTags] = useState('loading');
    const [selectValue, setSelectValue] = useState('');
    const [modalTagKey, setModalTagKey] = useState(null);
    const fetchTags = async () => {
        const tags = await TeamAPI.fetchCurrentTeamTags(session.connection);
        setTeamTags(tags);
    };
    useEffect(() => {
        void fetchTags();
    }, [project]);
    const handleTagCheckChange = async (tag, add) => {
        setLoadingTags([...loadingTags, tag]);
        await enqueueProjectUpdate(async (currentProject) => {
            const result = add ? await ProjectAPI.addTagToProject(session.connection, currentProject, tag) : await ProjectAPI.deleteTagFromProject(session.connection, currentProject, tag);
            if (result.isSuccess()) {
                return result.get();
            }
            void message.error(txt('failedToSaveTags'));
            throw Error(result.err());
        });
        setLoadingTags((previousLoadingTags) => previousLoadingTags.filter((loadingTag) => loadingTag !== tag));
    };
    const updateProjectTags = async () => {
        await fetchTags();
        if (onTagsChange) {
            onTagsChange();
        }
    };
    const handleSelect = async (value) => {
        setSelectValue(value);
        if (teamTags === 'loading')
            return;
        const updatedTags = [...new Set([...project.tags, value])];
        await enqueueProjectUpdate(async () => {
            const result = await ProjectAPI.updateTags(session.connection, project, updatedTags);
            if (result.isSuccess()) {
                return result.get();
            }
            void message.error(txt('failedToSaveTags'));
            throw Error(result.err());
        });
    };
    const options = teamTags !== 'loading' ? teamTags.list
        .filter((t) => !project.tags.includes(t.tag))
        .map((t) => ({ label: t.tag, value: t.tag })) : [];
    const saveToTeamTags = async (editedTag) => {
        var _a, _b;
        await addCurrentTeamTag(session.connection, editedTag.title, (_a = editedTag.abbreviation) !== null && _a !== void 0 ? _a : editedTag.title.slice(0, 2), (_b = editedTag.color) !== null && _b !== void 0 ? _b : deriveTagColor(editedTag.title), true);
        void updateProjectTags();
    };
    const pinnedTags = teamTags !== 'loading'
        ? teamTags.list.filter((t) => t.isPinned || project.tags.includes(t.tag))
            .map((t) => ({
            title: t.tag,
            key: t.tag,
            abbreviation: t.abbreviation,
            color: t.color,
        }))
        : [];
    return (teamTags !== 'loading' ? (_jsxs(Flex, { vertical: true, children: [_jsxs(Flex, { wrap: "wrap", children: [pinnedTags.map((tag) => (_jsx(BeeyTag, { tag: tag, isCheckable: true, checked: project.tags.includes(tag.key), onTagCheckChange: handleTagCheckChange, disabled: project.isReadOnly, loadingTags: loadingTags }, tag.title))), project.tags.length > 0 && project.tags.map((tag) => {
                        const teamTag = teamTags.list.find((tt) => tt.tag === tag);
                        if (teamTag === undefined) {
                            const foreignTag = {
                                title: tag,
                                key: tag,
                                color: deriveTagColor(tag),
                                abbreviation: tag.slice(0, 2),
                                isForeign: true,
                                showFullVersion: true,
                            };
                            return (_jsx(BeeyTag, { tag: foreignTag, isCheckable: false, isForeign: true, checked: true, onTagDelete: () => handleTagCheckChange(tag, false), onTagEdit: saveToTeamTags, modalAction: "edit", onModalOpen: (open) => setModalTagKey(open ? foreignTag.key : null), openAddEditModal: modalTagKey === foreignTag.key }, foreignTag.key));
                        }
                        return null;
                    })] }), _jsx(Select, { className: styles.select, value: selectValue, onBlur: () => setSelectValue(''), filterOption: filterOption, onChange: handleSelect, showSearch: true, options: options })] })) : _jsx(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }) }));
};
export default TagsPanel;
