import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Card, Popconfirm, Form, Input, } from 'antd';
import IconButton from 'components/IconButton';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined, } from '@ant-design/icons';
import DashboardPage from 'screens/DashboardScreen/components/DashboardPage';
import ResponsiveTable, { useTableView, empiricalScrollHeightAdjust, } from 'components/ResponsiveTable';
import AddEditModal from 'components/AddEditModal';
import LanguageSelect from 'components/LanguageSelect';
import { nanoid } from 'nanoid';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import * as UserlexAPI from 'api/userlex-api';
import { normalizeIncorrectTranscription } from 'api/userlex-api';
import AddButton from 'components/AddButton';
import DownloadButton from 'components/DownloadButton';
import { selectInitialTranscriptionOption } from 'libs/locales/recognition';
import { BeeyLocale } from 'libs/locales/locale';
import { fetchCurrentUserTranscriptionOptions } from 'api/user-api';
import Loading from 'components/Loading';
import { handleLexiconRulesExport } from 'libs/download-csv';
import AlertPage from '../AlertPage';
import './style.less';
const emptyEntry = () => ({
    correctTranscription: '',
    incorrectTranscription: '',
    key: nanoid(),
});
const USERLEX_TABLE_ID = 'userlex-table'; // Must be unique across all tables in Beey!!
const SCROLL_HEIGHT_ADJUST = empiricalScrollHeightAdjust(175);
const UserlexPage = () => {
    const { session } = useSession();
    const tableView = useTableView(USERLEX_TABLE_ID);
    const [userlex, setUserlex] = useState('loading');
    const [modal, setModal] = useState({
        action: 'add',
        visible: false,
        initialValues: emptyEntry(),
    });
    useEffect(() => {
        const loadUserlex = async () => {
            const transcriptionOptions = await fetchCurrentUserTranscriptionOptions(session.connection);
            const urlParams = new URLSearchParams(window.location.search);
            const lexlang = urlParams.get('lexlang');
            const suggestedLocale = lexlang === null ? null : BeeyLocale.fromCode(lexlang);
            const selectedOption = selectInitialTranscriptionOption(transcriptionOptions, suggestedLocale);
            if (selectedOption === null) {
                setUserlex('error');
                return;
            }
            const result = await UserlexAPI.fetchCurrentUserlex(session.connection, selectedOption.locale);
            if (result.isSuccess()) {
                setUserlex({
                    locales: transcriptionOptions.map((option) => option.locale),
                    entries: result.get(),
                    selectedLang: selectedOption.locale,
                });
            }
        };
        void loadUserlex();
    }, []);
    if (userlex === 'loading') {
        return (_jsx(DashboardPage, { title: txt('userlex'), children: _jsx(Loading, {}) }));
    }
    if (userlex === 'error') {
        return (_jsx(AlertPage, { title: txt('error'), header: txt('error'), children: _jsx("p", { children: txt('noTranscriptionOptions') }) }));
    }
    const openAddModal = () => {
        setModal({ action: 'add', visible: true, initialValues: emptyEntry() });
    };
    const openEditModal = (record) => {
        setModal({ action: 'edit', visible: true, initialValues: record });
    };
    const handleAddEntry = async (entry) => {
        const { correctTranscription, incorrectTranscription } = entry;
        const updatedEntries = [{
                correctTranscription,
                incorrectTranscription: normalizeIncorrectTranscription(incorrectTranscription),
                key: nanoid(5),
            }, ...userlex.entries];
        const result = await UserlexAPI.putCurrentUserlex(session.connection, userlex.selectedLang, updatedEntries);
        if (result.isSuccess()) {
            setUserlex(Object.assign(Object.assign({}, userlex), { entries: result.get() }));
        }
    };
    const handleEditEntry = async (editedEntry) => {
        const updatedEntries = userlex.entries.map((entry) => {
            if (entry.key === editedEntry.key) {
                return Object.assign(Object.assign({}, editedEntry), { incorrectTranscription: normalizeIncorrectTranscription(editedEntry.incorrectTranscription) });
            }
            return entry;
        });
        const result = await UserlexAPI.putCurrentUserlex(session.connection, userlex.selectedLang, updatedEntries);
        if (result.isSuccess()) {
            setUserlex(Object.assign(Object.assign({}, userlex), { entries: result.get() }));
        }
    };
    const handleDeleteEntry = async (deletedEntry) => {
        const result = await UserlexAPI.putCurrentUserlex(session.connection, userlex.selectedLang, userlex.entries.filter((entry) => entry.key !== deletedEntry.key));
        if (result.isSuccess()) {
            setUserlex(Object.assign(Object.assign({}, userlex), { entries: result.get() }));
        }
    };
    const columns = [
        {
            title: txt('incorrectTranscription'),
            key: 'incorrectTranscription',
            dataIndex: 'incorrectTranscription',
            className: 'name-cell',
        },
        {
            title: txt('correctTranscription'),
            key: 'correctTranscription',
            dataIndex: 'correctTranscription',
            className: 'name-cell',
        },
        {
            title: '',
            key: 'Edit',
            width: '40px',
            render: (record) => {
                return (_jsx(IconButton, { onClick: () => openEditModal(record), children: _jsx(EditOutlined, {}) }));
            },
        },
        {
            title: '',
            key: 'Delete',
            width: '40px',
            render: (record) => {
                return (_jsx(Popconfirm, { title: txt('deletePermanently'), okText: txt('delete'), okButtonProps: { danger: true }, okType: "primary", onConfirm: () => handleDeleteEntry(record), cancelText: txt('cancel'), placement: "topLeft", children: _jsx(IconButton, { danger: true, children: _jsx(DeleteOutlined, {}) }) }));
            },
        },
    ];
    return (_jsx(DashboardPage, { title: txt('userlex'), children: ({ contentHeight }) => (_jsx("section", { className: "userlex-page", children: _jsxs(Card, { bordered: false, children: [_jsxs("div", { className: "toolbar", children: [_jsx("div", { className: "toolbar__left", children: _jsx(AddButton, { title: txt('addWord'), onClick: () => openAddModal() }) }), _jsxs("div", { className: "toolbar__right toolbar__right--lex", children: [_jsx("span", { className: "input-label", children: `${txt('lexiconLanguage')}:` }), _jsx(LanguageSelect, { className: "choose-lang", locales: userlex.locales, value: userlex.selectedLang, onChange: async (newLanguage) => {
                                            const result = await UserlexAPI.fetchCurrentUserlex(session.connection, newLanguage);
                                            if (result.isSuccess()) {
                                                setUserlex(Object.assign(Object.assign({}, userlex), { entries: result.get(), selectedLang: newLanguage }));
                                            }
                                        } }), _jsx(DownloadButton, { onHandleExport: async () => handleLexiconRulesExport(session.connection, userlex.selectedLang.code), title: txt('downloadInCSV') })] })] }), _jsx(ResponsiveTable, { bordered: false, className: "userlex-table", columns: columns, dataSource: userlex.entries, rowKey: () => nanoid(5), tableView: tableView, recordCount: userlex.entries.length, pageSizeOptions: ['50', '100', '200'], scroll: { x: 400, y: contentHeight - SCROLL_HEIGHT_ADJUST }, stripeRows: true }), _jsxs(AddEditModal, { action: modal.action, visible: modal.visible, title: _jsx("span", { children: modal.action === 'add' ? txt('addWord') : txt('editUserWord') }), initialValues: modal.initialValues, onCancel: () => setModal(Object.assign(Object.assign({}, modal), { visible: false })), onAdd: async (entry) => handleAddEntry(entry), onEdit: async (entry) => handleEditEntry(entry), labelColSpan: 10, children: [_jsx(Form.Item, { label: txt('incorrectTranscription'), name: "incorrectTranscription", tooltip: {
                                    title: txt('userlexHelpBody'),
                                    icon: _jsx(InfoCircleOutlined, {}),
                                }, rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: txt('missingIncorrectTrancription'),
                                        min: 5,
                                    },
                                    {
                                        validator: async (_, value) => {
                                            const duplicitIncorrectTranscription = userlex.entries.find((entry) => (normalizeIncorrectTranscription(entry.incorrectTranscription)
                                                === normalizeIncorrectTranscription(value)
                                                && entry.key !== modal.initialValues.key));
                                            if (duplicitIncorrectTranscription !== undefined) {
                                                return Promise.reject(txt('duplicitIncorrectTranscription'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ], children: _jsx(Input, { autoFocus: true }) }), _jsx(Form.Item, { label: txt('correctTranscription'), name: "correctTranscription", rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: txt('missingCorrectTrancription'),
                                        min: 1,
                                    },
                                ], children: _jsx(Input, {}) }), _jsx(Form.Item, { className: "form__item--hidden", name: "key", children: _jsx(Input, { type: "hidden" }) })] })] }) })) }));
};
export default UserlexPage;
