import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Form, Input, Button, Spin, App, } from 'antd';
import FormModal from 'components/FormModal';
import { Alerts } from 'components/Alerts';
import { fetchPasswordSettings } from 'api/user-api';
import { modifyUser } from 'api/admin/users-api';
import { useSession } from 'components/Authenticated';
import { generatePassword } from 'libs/password';
import { txt } from 'libs/i18n';
const ChangePasswordForm = ({ userId, visible, onCancel }) => {
    const [form] = Form.useForm();
    const { session } = useSession();
    const { message } = App.useApp();
    const [alert, setAlert] = useState(null);
    const [passwordSettings, setPasswordSettings] = useState('loading');
    useEffect(() => {
        void (async () => {
            setPasswordSettings(await fetchPasswordSettings());
        })();
    }, []);
    const handleGeneratePassword = () => {
        if (passwordSettings !== 'loading') {
            form.setFieldsValue({
                newpass: generatePassword(passwordSettings),
            });
        }
    };
    const handleSubmit = async ({ newpass }) => {
        const result = await modifyUser(session.connection, userId, { password: newpass });
        if (result.isSuccess()) {
            void message.success(txt('successChange'));
        }
        else if (result.err() === 'short-password') {
            const requiredLength = passwordSettings === 'loading'
                ? ''
                : passwordSettings.requiredLength;
            setAlert(Alerts.crash(`${txt('minCharsPsw')}${requiredLength}.`));
        }
        else {
            setAlert(Alerts.crash(txt('savingFailed')));
        }
        form.resetFields();
        onCancel();
    };
    return (_jsx(FormModal, { title: txt('changePassword'), open: visible, onCancel: onCancel, footer: [
            _jsx(Button, { onClick: handleGeneratePassword, children: txt('generatePW') }, "back"),
            _jsx(Button, { onClick: () => form.submit(), id: "action-save-password", type: "primary", htmlType: "submit", children: txt('save') }, "submit")
        ], children: passwordSettings === 'loading'
            ? _jsx(Spin, {})
            : (_jsxs(Form, { onFinish: handleSubmit, form: form, wrapperCol: { span: 12 }, children: [alert, _jsx(Form.Item, { name: "newpass", label: txt('newPW'), rules: [
                            {
                                required: true,
                                message: txt('pleasePW'),
                            },
                            {
                                min: passwordSettings.requiredLength,
                                whitespace: true,
                                message: `${txt('minChars')}${passwordSettings.requiredLength}`,
                            },
                        ], children: _jsx(Input, { autoFocus: true }) })] })) }));
};
export default ChangePasswordForm;
