import { createProjectFromApi, } from 'api/model/project';
import { checkObjectsMatch } from 'libs/utils';
import camelcaseKeys from 'camelcase-keys';
import { blob, hasErrorMessage, json, text, } from '@newtontechnologies/beey-api-js-client/receivers';
import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { serializeToVttFormat } from 'libs/vtt-format-parser';
import { BeeyLocale } from 'libs/locales/locale';
import { Result } from 'monadix/result';
import { subSystemList } from './websocket-api';
const PAGE_LIMIT = (2 ** 31) - 1;
export const isSearchingProjectsQuery = (query) => ('searching' in query && query.searching === true);
export const isSearchedProject = (project) => ('searched' in project && project.searched === true);
const buildProjectsList = (apiList, query) => {
    const items = apiList.list.map((project) => (Object.assign(Object.assign({}, createProjectFromApi(project)), { queued: apiList.queued.includes(project.id), processingStateDetail: project.processingState === 'InProgress'
            ? {
                uploading: apiList.uploading.includes(project.id),
                transcribed: apiList.transcribing.includes(project.id) ? 'unknown' : 'none',
            }
            : null })));
    return {
        items,
        query,
        totalCount: apiList.totalCount,
    };
};
const buildSearchedProjectsList = (apiList, query) => {
    const simpleList = buildProjectsList(apiList, query);
    const items = apiList.list.map((project, index) => (Object.assign(Object.assign({}, simpleList.items[index]), { searched: true, notesHighlight: project.notesHighlight, projectNameHighlight: project.projectNameHighlight, speakerHighlight: project.speakerHighlight, transcriptionHighlight: project.transcriptionHighlight })));
    return {
        items,
        query,
        totalCount: apiList.totalCount,
    };
};
const fetchBasicProjectsList = async (connection, query) => {
    const { currentPage = 1, pageSize = PAGE_LIMIT, orderon = 'created', orderby = 'descending', tags = null, createdFrom, createdTo, updatedFrom, updatedTo, } = query;
    const url = apiV2.projects.search.url({
        skip: pageSize * (currentPage - 1),
        count: pageSize,
        orderon,
        orderby,
        from: updatedFrom,
        to: updatedTo,
        createdFrom,
        createdTo,
        tag: tags,
    });
    return buildProjectsList(await connection.authFetch()
        .url(url)
        .post()
        .receive(json())
        .fetch(), query);
};
export const fetchSearchedProjectsList = async (connection, query) => {
    const { currentPage = 1, pageSize = PAGE_LIMIT, orderon = 'created', orderby = 'descend', tags = null, all = '', projectname = '', transcription = '', speakers = '', notes = '', createdFrom = '', createdTo = '', updatedFrom = '', updatedTo = '', } = query;
    const url = apiV2.projects.fullTextSearch.url({
        allFullText: all,
        ProjectNameFullText: projectname,
        TranscriptionFullText: transcription,
        SpeakerFullText: speakers,
        NotesFullText: notes,
        Skip: pageSize * (currentPage - 1),
        Count: pageSize,
        Orderon: orderon,
        Orderby: orderby,
        UpdatedFrom: updatedFrom,
        UpdatedTo: updatedTo,
        CreatedFrom: createdFrom,
        CreatedTo: createdTo,
        tag: tags,
    });
    return buildSearchedProjectsList(await connection.authFetch()
        .url(url)
        .post()
        .receive(json())
        .fetch(), query);
};
export const fetchProjectsList = async (connection, query) => (isSearchingProjectsQuery(query)
    ? fetchSearchedProjectsList(connection, query)
    : fetchBasicProjectsList(connection, query));
export const fetchProject = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).url())
    .receive(json()
    .map(createProjectFromApi)
    .catchHttpError((response) => {
    if (response.status === 404 || response.status === 403) {
        return { error: 'not-found' };
    }
    return 'unknown';
}))
    .fetch();
const withAccessToken = (url, accessToken) => {
    const modifiedUrl = new URL(url);
    modifiedUrl.searchParams.set('accessToken', String(accessToken));
    return modifiedUrl.toString();
};
/* NOTE: Update project with retry on access token conflict.
   If server project matches expectedProjectMask, conflict is automatically
   resolved. Otherwise ApiError is raised. Values omitted
   in expectedProjectMask are not checked. Provide empty project to ignore
   force update without checking for conflicts.
*/
export const safelyUpdateProject = async (connection, url, project, projectFetch, expectedProjectMask = {}) => {
    const result = await projectFetch.base
        .url(withAccessToken(url, project.accessToken))
        .receive(projectFetch.receiver
        .finally((response) => {
        if (response.status === 409) {
            return 'conflict';
        }
        return 'unexpected';
    }))
        .fetchStrict();
    if (result.isSuccess()) {
        return result;
    }
    if (result.isFail()) {
        if (result.err() === 'conflict') {
            const serverProject = await fetchProject(connection, project.id);
            if (serverProject === 'not-found') {
                return Result.fail('not-found');
            }
            if (checkObjectsMatch(serverProject, expectedProjectMask)) {
                global.logger.info('AccessToken conflict resolved automatically');
                return projectFetch.base
                    .url(withAccessToken(url, serverProject.accessToken))
                    .receive(projectFetch.receiver)
                    .fetchStrict();
            }
        }
        else {
            return Result.fail(result.err());
        }
    }
    return Result.fail('unexpected');
};
export const setupNewProject = (connection, projectName) => connection.authFetch()
    .url(apiV2.projects.url())
    .postJson({
    Name: projectName,
    CustomPath: '/',
})
    .receive(json().map(createProjectFromApi))
    .fetch();
export const updateProjectName = async (connection, project, newName) => {
    const projectFetchBase = connection.authFetch()
        .postJson({
        Name: newName,
    });
    const projectReceiver = json().map(createProjectFromApi);
    return safelyUpdateProject(connection, apiV2.projects.id(project.id).name.url(), project, { base: projectFetchBase, receiver: projectReceiver }, { name: project.name });
};
export const fetchProjectMetadata = async (connection, projectId, key) => {
    const metadata = await connection.authFetch()
        .url(apiV2.projects.id(projectId).metadata.url({ key }))
        .receive(json())
        .fetch();
    return metadata === null ? null : metadata.value;
};
export const fetchProjectLanguage = async (connection, project) => {
    var _a, _b;
    const configLanguage = (_b = (_a = project.transcriptionConfig) === null || _a === void 0 ? void 0 : _a.language) !== null && _b !== void 0 ? _b : null;
    if (configLanguage !== null) {
        return configLanguage;
    }
    global.logger.error('project is missing config language');
    const fetchedLanguage = await fetchProjectMetadata(connection, project.id, 'language');
    const locale = fetchedLanguage === null ? null : BeeyLocale.fromCode(fetchedLanguage);
    if (locale !== null) {
        return locale;
    }
    global.logger.error('project is missing both config and metadata language');
    return null;
};
export const fetchHeadingsMetadata = async (connection, project) => {
    const headingsMetadata = await fetchProjectMetadata(connection, project.id, 'headings');
    if (headingsMetadata === null) {
        return null;
    }
    return headingsMetadata;
};
export const fetchSecondaryProjectId = async (connection, project) => {
    const secondaryProjectId = await fetchProjectMetadata(connection, project.id, 'secondaryProjectId');
    if (secondaryProjectId === null) {
        return null;
    }
    return String(secondaryProjectId);
};
export const addTagToProject = async (connection, project, tag) => {
    const projectFetchBase = connection.authFetch()
        .postJson({ Tag: tag });
    const projectReceiver = json().map(createProjectFromApi);
    return safelyUpdateProject(connection, apiV2.projects.id(project.id).tags.url(), project, { base: projectFetchBase, receiver: projectReceiver }, { tags: project.tags });
};
export const deleteTagFromProject = async (connection, project, tag) => {
    const projectFetchBase = connection.authFetch()
        .postJson({ Tag: tag })
        .delete();
    const projectReceiver = json().map(createProjectFromApi);
    return (safelyUpdateProject(connection, apiV2.projects.id(project.id).tags.url(), project, { base: projectFetchBase, receiver: projectReceiver }, { tags: project.tags }));
};
export const updateTags = async (connection, project, tags) => {
    const projectFetchBase = connection.authFetch()
        .put(JSON.stringify({ Tags: tags }))
        .headers({ 'Content-Type': 'application/json' });
    const projectReceiver = json().map(createProjectFromApi);
    return (safelyUpdateProject(connection, apiV2.projects.id(project.id).tags.url(), project, { base: projectFetchBase, receiver: projectReceiver }, { tags: project.tags }));
};
export const updateProjectMetadata = (connection, project, key, value) => connection.authFetch()
    .url(apiV2.projects.id(project.id).metadata.url({
    accessToken: project.accessToken,
    key,
}))
    .postJson(value)
    .receive(json().map(createProjectFromApi))
    .fetch();
export const updateProjectNotes = (connection, project, value) => connection.authFetch()
    .url(apiV2.projects.id(project.id).metadata.url({
    accessToken: project.accessToken,
    key: 'notes',
}))
    // NOTE: We send all metadata as JSON, even if it's just a string that is stringified.
    .postJson(value)
    .receive(json().map(createProjectFromApi)
    .finally(() => 'unexpected'))
    .fetchStrict();
export const updateProjectHeadingsMetadata = (connection, project, value) => connection.authFetch()
    .url(apiV2.projects.id(project.id).metadata.url({
    accessToken: project.accessToken,
    key: 'headings',
}))
    .postJson(value)
    .receive(json().map(createProjectFromApi)
    .finally(() => 'unexpected'))
    .fetchStrict();
export const uploadTrsx = async (connection, project, trsx, variant, isUnedited, label) => {
    const lz4 = lz4_require('lz4');
    const { Buffer } = lz4_require('buffer');
    const input = trsx instanceof File
        ? Buffer.from(await trsx.arrayBuffer())
        : Buffer.from(trsx);
    const output = lz4.encode(input, { blockChecksum: false });
    const trsxBlob = new Blob([output], { type: 'application/octet-stream' });
    const projectFetchBase = connection.authFetch()
        .postBlob(trsxBlob);
    const projectReceiver = json().map(createProjectFromApi);
    return safelyUpdateProject(connection, apiV2.projects.id(project.id).files[variant].url({
        accessToken: project.accessToken,
        isCompressed: true,
        label,
    }), project, { base: projectFetchBase, receiver: projectReceiver }, isUnedited ? undefined : { currentTrsxId: project.currentTrsxId });
};
export const fetchTrsx = (connection, project, variant, fileId) => connection.authFetch()
    .url(apiV2.projects.id(project.id).files[variant].url({ fileId }))
    .receive(text())
    .fetch();
export const shareProject = async (connection, project, email) => {
    const projectFetchBase = connection.authFetch()
        .postJson({ ShareTo: email });
    const projectReceiver = json().map(createProjectFromApi);
    return safelyUpdateProject(connection, apiV2.projects.id(project.id).sharings.url(), project, { base: projectFetchBase, receiver: projectReceiver });
};
export const listProjectSharing = async (connection, projectId, userEmail) => {
    const data = await connection.authFetch()
        .url(apiV2.projects.id(projectId).sharings.url())
        .receive(json())
        .fetch();
    return data.list
        .map((item) => item.email)
        .filter((email) => email !== userEmail);
};
export const deleteProject = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).url())
    .delete()
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const trashProject = async (connection, project) => {
    const projectFetchBase = connection.authFetch()
        .post();
    const projectReceiver = json();
    return safelyUpdateProject(connection, apiV2.projects.id(project.id).trash.trash.url(), project, { base: projectFetchBase, receiver: projectReceiver }, { currentTrsxId: project.currentTrsxId });
};
export const getProjectMessages = (connection, projectId, fromId, toId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).messageCache.url({
    fromId,
    toId,
    subsystemFilter: subSystemList.join(';'),
}))
    .receive(json())
    .fetch();
const buildExportURI = (projectId, format, withTimeStamps, isRightToLeft, exportTemplateId) => (apiV2.projects.id(projectId).export.url({
    formatId: format, withTimeStamps, exportTemplateId, isRightToLeft,
}));
export const downloadSubtitles = (connection, project, format, length, speakerSignPlacement, pauseBetweenCaptions, autofillPauseBetweenCaptions, upperCaseAllText, isRightToLeft, highlightingMode, unHighlightedColor) => connection.authFetch()
    .url(apiV2.projects.id(project.id).export.subtitles.url({
    fileFormatId: format,
    subtitleLineLength: length,
    speakerSignPlacement,
    pauseBetweenCaptionsMs: pauseBetweenCaptions * 1000, // in milisecs
    autofillPauseBetweenCaptionsMs: autofillPauseBetweenCaptions * 1000, // in milisecs
    formattingMode: 'DisableAll',
    makeAllUpperCase: upperCaseAllText,
    isRightToLeft,
    highlightingMode,
    unHighlightedColor,
}))
    .receive(blob()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const fetchExport = (connection, project, format, withTimeStamps, isRightToLeft, exportTemplateId) => connection.authFetch()
    .url(buildExportURI(project.id, format, withTimeStamps, isRightToLeft, exportTemplateId))
    .receive(blob())
    .fetch();
export const downloadSubtitlesFromLabeled = (connection, project, format, formattingMode, upperCaseAllText, keepInnerLinesStripped, isRightToLeft, highlightingMode, unHighlightedColor) => connection.authFetch()
    .url(apiV2.projects.id(project.id).export.subtitles.fromLabeled.url({
    fileFormatId: format,
    keepInnerLinesStripped,
    formattingMode,
    upperCaseAllText,
    isRightToLeft,
    highlightingMode,
    unHighlightedColor,
}))
    .receive(blob()
    .catchHttpError(async (response) => {
    const errorResponse = await response.json();
    const errorPayload = camelcaseKeys(errorResponse, { deep: true });
    if (response.status === 422) {
        switch (true) {
            case hasErrorMessage(errorPayload, 'Subtitle line length must be greater or equal 30.'):
                return { error: 'min-sub-length' };
            case hasErrorMessage(errorPayload, 'Subtitle line length must be less or equal 50.'):
                return { error: 'max-sub-length' };
            case hasErrorMessage(errorPayload, 'Subtitle line length must be a positive integer.'):
                return { error: 'negative-sub-length' };
            case hasErrorMessage(errorPayload, 'Invalid Format Id: FileFormatId'):
                return { error: 'invalid-file-format' };
            default:
                return 'unknown';
        }
    }
    return 'unknown';
})
    .finally(() => 'unexpected'))
    .fetchStrict();
export const labelTrsx = async (connection, project, captionParameters, isUnedited) => {
    const body = {
        projectId: Number(project.id),
        subtitleLineLength: captionParameters.maxLineLength,
        keepStripped: captionParameters.keepInnerLinesStripped,
        forceSingleLine: captionParameters.subtitlerMaxLineCount === 1,
        enablePresegmentation: captionParameters.enablePresegmentation,
        speakerSignPlacement: captionParameters.speakerSignPlacement,
        pauseBetweenCaptionsMs: captionParameters.pauseBetweenCaptions * 1000,
        autofillPauseBetweenCaptionsMs: captionParameters.autofillPauseBetweenCaptions * 1000,
        useSpeakerName: captionParameters.useSpeakerName,
        removeNoises: true,
        automaticSpeed: captionParameters.automaticSpeed,
        minLineDurationMs: captionParameters.minDuration * 1000,
        speakerSign: captionParameters.speakerSign,
        feMaxDurationMs: captionParameters.maxDuration * 1000,
        feSpeedWarning: captionParameters.speedWarning,
        feSpeedCriticalWarning: captionParameters.speedCriticalWarning,
        feTemplateName: captionParameters.templateName,
        defaultCaptionPosition: serializeToVttFormat(captionParameters.defaultCaptionPosition),
        defaultFontSize: String(captionParameters.defaultFontSize),
        defaultColor: captionParameters.defaultColor,
        defaultFontName: captionParameters.defaultFontName,
        defaultBackgroundColor: captionParameters.defaultBackgroundColor,
        defaultBackgroundTransparency: captionParameters.defaultBackgroundTransparency,
        upperCaseAllText: captionParameters.upperCaseAllText,
        highlightingMode: captionParameters.highlightingMode,
        unHighlightedColor: captionParameters.unHighlightedColor,
    };
    const projectFetchBase = connection.authFetch()
        .postJson(body);
    const projectReceiver = json()
        .catchHttpError((response, errorPayload) => {
        if (response.status === 422) {
            switch (true) {
                case hasErrorMessage(errorPayload, 'Subtitle line length must be between 15 and 60.'):
                    return { error: 'invalid-sub-length' };
                case hasErrorMessage(errorPayload, 'Subtitle line length must be a positive integer.'):
                    return { error: 'negative-sub-length' };
                case hasErrorMessage(errorPayload, 'Invalid file format.'):
                    return { error: 'invalid-file-format' };
                default:
                    return 'unknown';
            }
        }
        return 'unknown';
    })
        .map(createProjectFromApi);
    return safelyUpdateProject(connection, apiV2.projects.id(project.id).export.subtitles.labelTrsx.url(), project, { base: projectFetchBase, receiver: projectReceiver }, isUnedited ? undefined : { currentTrsxId: project.currentTrsxId });
};
export const buildMediaManifestUrl = (project, presentationDelay) => apiV2.projects.id(project.id).files.mpdManifest.url({ presentationDelay });
export const buildMediaFileUrl = async (project, connection) => (apiV2.projects.id(project.id).files.mediaFile.url({ Authorization: await connection.retrieveAuthString() }));
export const buildLowQualityAudioUrl = async (project, connection) => (apiV2.projects.id(project.id).lowQualityAudio.url({ Authorization: await connection.retrieveAuthString() }));
export const fetchVideoSceneChange = (project, connection) => connection.authFetch()
    .url((apiV2.projects.id(project.id).files.scenes.url()))
    .receive(json()
    .catchHttpError((response) => (response.status === 404 ? { error: 'not-found' } : 'unknown'))
    .finally(() => 'unexpected'))
    .fetchStrict();
export const fetchLastError = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).lastError.url())
    .receive(json())
    .fetch();
export const createDemoProject = (connection) => connection.authFetch()
    .url(apiV2.projects.createDemoProjects.url())
    .receive(json())
    .fetch();
export const exportBasicProjects = (connection, query) => {
    const { orderon, orderby = 'descending', tags, createdFrom = '', createdTo = '', updatedFrom = '', updatedTo = '', } = query;
    const url = apiV2.projects.export.list.url({
        OrderOn: orderon,
        OrderBy: orderby,
        From: updatedFrom,
        To: updatedTo,
        CreatedFrom: createdFrom,
        CreatedTo: createdTo,
        Tag: tags,
    });
    return connection.authFetch()
        .url(url)
        .post()
        .receive(blob())
        .fetch();
};
export const exportSearchedProjects = async (connection, query) => {
    const { orderon, orderby = 'descending', tags, all, projectname, transcription, speakers, notes, createdFrom = '', createdTo = '', updatedFrom = '', updatedTo = '', } = query;
    const url = apiV2.projects.export.fullTextSearchList.url({
        AllFullText: all,
        ProjectNameFullText: projectname,
        TranscriptionFullText: transcription,
        SpeakersFullText: speakers,
        NotesFullText: notes,
        OrderOn: orderon,
        OrderBy: orderby,
        From: updatedFrom,
        To: updatedTo,
        CreatedFrom: createdFrom,
        CreatedTo: createdTo,
        Tag: tags,
    });
    return connection.authFetch()
        .url(url)
        .post()
        .receive(blob())
        .fetch();
};
export const exportProjects = async (connection, query) => (isSearchingProjectsQuery(query)
    ? exportSearchedProjects(connection, query)
    : exportBasicProjects(connection, query));
export const calculateTranscriptionCredit = async (connection, ...durations) => (durations.length === 0
    ? []
    : connection.authFetch()
        .url(apiV2.projects.calculateCreditToTranscribe.url({ DurationsSeconds: durations }))
        .receive(json().map((data) => data.requiredCredit))
        .fetch());
export const fetchMediaSize = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).size.url())
    .receive(json())
    .fetch();
export const fetchChecklist = async (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).checklist.url())
    .receive(json())
    .fetch();
export const updateChecklistItem = async (connection, projectId, checklistItem) => connection.authFetch()
    .url(apiV2.projects.id(projectId).checklist.id(checklistItem.id).url())
    .putJson(checklistItem)
    .receive(json().catchHttpError((response) => (response.status === 422 ? { error: 'unprocessable-entity' } : 'unknown'))
    .finally(() => 'unexpected'))
    .fetchStrict();
export const fetchProjectRating = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).rating.url())
    .receive(json().map((data) => data.rating))
    .fetch();
export const addProjectRating = (connection, projectId, rating) => connection.authFetch()
    .url(apiV2.projects.id(projectId).rating.url())
    .postJson({ rating })
    .send();
export const unshareProject = (connection, project) => connection.authFetch()
    .url(apiV2.projects.id(project.id).unshare.url({ accessToken: project.accessToken }))
    .post()
    .send();
export const fetchProjectFiles = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).files.url())
    .receive(json())
    .fetch();
export const editProjectDescription = (connection, project, description) => connection.authFetch()
    .url(apiV2.projects.id(project.id).description.url({ accessToken: project.accessToken }))
    .postJson({ author: description.author })
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
