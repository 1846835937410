import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { mediaIsPackaged } from 'api/model/project';
import { Alert, App, Form, Spin, } from 'antd';
import Loading from 'components/Loading';
import IconButton from 'components/IconButton';
import { translateProject } from 'api/model/services/services-api';
import * as ProjectAPI from 'api/project-api';
import { useSession } from 'components/Authenticated';
import { localeTxt, txt } from 'libs/i18n';
import { defaultTranslationLocale, getTranslationLocales, } from 'libs/locales/translation';
import ModalFooter from 'components/ModalFooter';
import LanguageSelect from 'components/LanguageSelect';
import styles from './style.module.less';
const TranslateModalContent = ({ project, enqueueProjectUpdate, onCancel, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const { connection } = session;
    const [form] = Form.useForm();
    const [translation, setTranslation] = useState({ type: 'loading' });
    const [targetLanguage, setTargetLanguage] = useState(defaultTranslationLocale);
    const handleLanguageChange = (selectValue) => {
        setTargetLanguage(selectValue.language);
    };
    useEffect(() => {
        const fetchProjectLanguage = async () => {
            const language = await ProjectAPI.fetchProjectLanguage(connection, project);
            if (language === null) {
                setTranslation({ type: 'no-lang' });
                return;
            }
            setTranslation({ type: 'selecting', sourceLanguage: language });
        };
        void fetchProjectLanguage();
    }, []);
    // FIXME: @podlomar This is an interim bandaid solution of a bug: Translate is
    // enabled before media packaging completes when uploading a recording with trsx.
    // Remove this check after disabling and enabling of the button works correctly.
    const checkMedia = async () => {
        if (!mediaIsPackaged(project)) {
            const currentProject = await ProjectAPI.fetchProject(connection, project.id);
            if (currentProject === 'not-found') {
                void message.error(txt('notFound'));
                logger.error('translate blocked, project not found.');
                return false;
            }
            if (!mediaIsPackaged(currentProject)) {
                void message.error(txt('preMediaReady'));
                logger.error('translate blocked, project is missing packaged media.');
                return false;
            }
        }
        return true;
    };
    const handleTranslate = async () => {
        if (translation.type !== 'selecting') {
            return;
        }
        setTranslation({ type: 'in-progress' });
        const isMediaReady = await checkMedia();
        if (!isMediaReady) {
            setTranslation(translation);
            return;
        }
        await enqueueProjectUpdate(async (initialProject) => {
            const result = await translateProject(connection, translation.sourceLanguage, targetLanguage, initialProject.id);
            if (result.isSuccess()) {
                setTranslation({
                    type: 'success',
                    sourceLanguage: translation.sourceLanguage,
                    projectId: result.get().translatedProjectId.toString(),
                });
            }
            else {
                setTranslation({ type: 'error' });
            }
            return initialProject;
        });
    };
    if (translation.type === 'loading') {
        return _jsx(Spin, { size: "large" });
    }
    if (translation.type === 'no-lang') {
        return (_jsx(Alert, { message: (_jsxs("p", { children: [txt('noLangError'), _jsx("br", {}), txt('problemPersists'), _jsx("a", { href: txt('contactLink'), rel: "noreferrer", target: "_blank", children: txt('contactUs') }), "."] })), type: "error", showIcon: true }));
    }
    if (translation.type === 'selecting') {
        const sameLocales = translation.sourceLanguage.lang === targetLanguage.lang;
        return (_jsxs(Form, { form: form, preserve: false, className: styles.form, layout: "vertical", onFinish: handleTranslate, onValuesChange: handleLanguageChange, initialValues: {
                language: targetLanguage,
            }, children: [_jsxs("div", { className: styles.fields, children: [_jsxs("div", { children: [_jsxs("div", { className: styles.fieldLabel, children: [txt('translateFromLanguage'), ":"] }), _jsx("div", { children: localeTxt(translation.sourceLanguage) })] }), _jsx(Form.Item, { name: "language", label: `${txt('targetLanguage')}:`, children: _jsx(LanguageSelect, { locales: getTranslationLocales() }) })] }), sameLocales && (_jsx(Alert, { message: txt('sameLanguagesAlert'), type: "warning", showIcon: true })), _jsx(ModalFooter, { onCancel: onCancel, disabled: sameLocales, children: txt('translate') })] }));
    }
    if (translation.type === 'error') {
        return (_jsx(Alert, { message: (_jsxs("p", { children: [txt('translationError'), _jsx("br", {}), txt('problemPersists'), _jsx("a", { href: txt('contactLink'), rel: "noreferrer", target: "_blank", children: txt('contactUs') }), "."] })), type: "error", showIcon: true }));
    }
    if (translation.type === 'in-progress') {
        return (_jsxs("div", { className: styles.progress, children: [_jsx(Loading, { size: "sm" }), _jsx("p", { children: txt('translationInProgress') })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Alert, { message: txt('translationSuccess'), type: "success", showIcon: true }), _jsxs("div", { className: styles.actions, children: [_jsx(IconButton, { onClick: () => setTranslation({ type: 'selecting', sourceLanguage: translation.sourceLanguage }), children: txt('newTranslation') }), _jsx("a", { className: styles.projectLink, href: `/edit/${translation.projectId}`, children: txt('openTranslation') })] })] }));
};
export default TranslateModalContent;
