import { isNoise } from 'libs/is-noise';
import ProjectStateHandler from './project-state-handler';
// State transitions:
// PreparingMedia ─┬────────────────────────────┬──▸ Transcribing
//    ▴            └──▸ NofaststartConversion ──┘          │
//    │                                                    ▾
//    │                                              WordsArriving
//    │                                                    │
//    │                                                    ▾
// LoadingProject ───────────────────────────────────▸ Completed
export default class MediaProcessingHandler extends ProjectStateHandler {
    constructor(messageStream) {
        super(messageStream, 'loading-project');
        this.handleMediaIdentificationProgress = ({ data }) => {
            // When transcripiton is completed (e.g. in case of uploaded trsx)
            // we do not want to change the state during recording conversion
            if (this.status === 'completed') {
                return;
            }
            if (data.kind === 'Error' && data.error === 'Unknown duration.') {
                this.updateStatus('nofaststart-conversion');
            }
        };
        this.handleTranscriptionTrackingStarted = () => this.updateStatus('transcribing');
        this.handleRecognitionProgress = (message) => {
            if (message.data.word !== null) {
                if (isNoise(message.data.word.text)) {
                    return;
                }
                if (this.status === 'transcribing') {
                    this.updateStatus('words-arriving');
                }
            }
        };
        this.handleTranscriptionTrackingCompleted = () => this.updateStatus('completed');
        this.addStreamListener('MediaIdentification', 'Progress', this.handleMediaIdentificationProgress);
        this.addStreamListener('TranscriptionTracking', 'Started', this.handleTranscriptionTrackingStarted);
        this.addStreamListener('TranscriptionStreaming', 'Progress', this.handleRecognitionProgress);
        this.addStreamListener('TranscriptionTracking', 'Completed', this.handleTranscriptionTrackingCompleted);
    }
    projectReloaded(project) {
        if (project.initialTrsxId !== null) {
            this.updateStatus('completed');
        }
    }
}
