import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { json, } from '@newtontechnologies/beey-api-js-client/receivers';
export const createStripeOrder = async (connection, lookupKey, quantity, currency) => connection.authFetch()
    .url(apiV2.orders.checkout.url({ lookupKey, quantity, currency }))
    .post()
    .receive(json({ camelcaseResponse: false })
    .catchHttpError((response) => (response.status === 422 ? { error: 'invalid-user-data' } : 'unknown'))
    .finally(() => 'unexpected'))
    .fetchStrict();
export const listOrders = async (connection, params = { skip: 0, count: 0 }) => connection.authFetch()
    .url(apiV2.orders.url(params))
    .receive(json())
    .fetch();
