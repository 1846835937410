import { createListedUserFromApi, createUserFromApi, createListedSimplifiedUserFromApi, } from 'api/model/user';
import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { defaultUserSettings } from 'api/settings/user-settings';
import { json, hasErrorMessage, } from '@newtontechnologies/beey-api-js-client/receivers';
const PAGE_LIMIT = (2 ** 31) - 1;
export const fetchAllUsersSimplified = async (connection) => {
    const apiUsersList = (await connection.authFetch()
        .url(apiV2.admin.users.url())
        .receive(json())
        .fetch());
    return Object.assign(Object.assign({}, apiUsersList), { list: apiUsersList.list.map((apiUser) => (Object.assign(Object.assign({}, apiUser), createListedSimplifiedUserFromApi(apiUser)))) });
};
export const fetchAllUsers = async (connection, filter, teamId) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const skip = (_a = filter === null || filter === void 0 ? void 0 : filter.skip) !== null && _a !== void 0 ? _a : 0;
    const count = (_b = filter === null || filter === void 0 ? void 0 : filter.count) !== null && _b !== void 0 ? _b : PAGE_LIMIT;
    const order = (_c = filter === null || filter === void 0 ? void 0 : filter.order) !== null && _c !== void 0 ? _c : 'ascending';
    const orderby = (_d = filter === null || filter === void 0 ? void 0 : filter.orderby) !== null && _d !== void 0 ? _d : 'email';
    const substr = (filter === null || filter === void 0 ? void 0 : filter.substr) === undefined ? '' : filter.substr;
    const role = (_e = filter === null || filter === void 0 ? void 0 : filter.role) !== null && _e !== void 0 ? _e : [];
    const language = (_f = filter === null || filter === void 0 ? void 0 : filter.language) !== null && _f !== void 0 ? _f : '';
    const managementgroupid = (_g = filter === null || filter === void 0 ? void 0 : filter.groupId) !== null && _g !== void 0 ? _g : '';
    const isNonEmptyTeam = (_h = filter === null || filter === void 0 ? void 0 : filter.email) !== null && _h !== void 0 ? _h : '';
    const isTeamOwner = (_j = filter === null || filter === void 0 ? void 0 : filter.email) !== null && _j !== void 0 ? _j : '';
    const url = apiV2.admin.users.search.url({
        skip,
        count,
        orderby,
        order,
        substr,
        role,
        language,
        managementgroupid,
        isNonEmptyTeam,
        isTeamOwner,
        teamId,
    });
    const apiUsersList = await connection.authFetch()
        .post()
        .url(url)
        .receive(json())
        .fetch();
    return Object.assign(Object.assign({}, apiUsersList), { list: apiUsersList.list.map((apiUser) => (Object.assign(Object.assign({}, apiUser), createListedUserFromApi(apiUser)))) });
};
export const fetchAllTeamOwnerUsers = async (connection) => {
    const url = apiV2.admin.users.url({
        isTeamOwner: true,
    });
    const apiOwnersList = (await connection.authFetch()
        .url(url)
        .receive(json())
        .fetch());
    return apiOwnersList.list.map((apiOwner) => (Object.assign(Object.assign({}, apiOwner), createListedSimplifiedUserFromApi(apiOwner))));
};
export const fetchUser = async (connection, userId) => {
    return connection.authFetch()
        .url(apiV2.admin.users.id(userId).url())
        .receive(json().map(createUserFromApi).finally(() => 'unexpected'))
        .fetchStrict();
};
export const catchUserError = (_, errorPayload) => {
    if (hasErrorMessage(errorPayload, 'Low password complexity: password')) {
        return { error: 'short-password' };
    }
    if (hasErrorMessage(errorPayload, 'Invalid email address')) {
        return { error: 'invalid-email' };
    }
    if (hasErrorMessage(errorPayload, 'Duplicate email address')) {
        return { error: 'duplicit-email' };
    }
    if (hasErrorMessage(errorPayload, 'Missing number of credits.')) {
        return { error: 'missing-credit' };
    }
    return 'unknown';
};
export const createUser = async (connection, email, role, password, credit, tutorial, sendEmail, uiLocale) => {
    const settings = Object.assign(Object.assign({}, defaultUserSettings), { startBeeyTutorial: tutorial });
    const body = {
        Email: email.trim(),
        Role: role,
        Password: password,
        CreditMinutes: credit,
        Language: uiLocale.code,
        Settings: settings,
    };
    return connection.authFetch()
        .url(apiV2.admin.users.url({ sendEmail }))
        .postJson(body)
        .receive(json().map(createUserFromApi)
        .catchHttpError(catchUserError)
        .finally(() => 'unexpected'))
        .fetchStrict();
};
export const fetchIsUserDeletable = async (connection, userId) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).isDeletable.url())
    .receive(json())
    .fetch();
export const modifyUser = async (connection, userId, values) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).url())
    .putJson({
    Password: values.password,
    Role: values.role,
})
    .receive(json().map(createUserFromApi)
    .catchHttpError((response, errorPayload) => {
    if (response.status === 422 && hasErrorMessage(errorPayload, 'Low password complexity: password')) {
        return { error: 'short-password' };
    }
    if (response.status === 422 && hasErrorMessage(errorPayload, 'Invalid role')) {
        return { error: 'invalid-role' };
    }
    return 'unknown';
})
    .finally(() => 'unexpected'))
    .fetchStrict();
export const deleteUser = async (connection, userId) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).url())
    .delete()
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const fetchUserClaims = async (connection, userId) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).claimSet.url())
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const addUserClaims = async (connection, userId, claims) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).claimSet.add.url())
    .postJson(claims)
    .receive(json())
    .fetch();
export const removeUserClaims = async (connection, userId, claims) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).claimSet.remove.url())
    .postJson(claims)
    .receive(json())
    .fetch();
export const resetUserClaims = async (connection, userId, claims) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).claimSet.reset.url())
    .postJson(claims)
    .receive(json())
    .fetch();
export const fetchParallelTranscriptionLimit = async (connection, userId) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).queueTranscriptionLimit.url())
    .receive(json())
    .fetch();
export const fetchDirectTranscriptionLimit = async (connection, userId) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).directTranscriptionLimit.url())
    .receive(json())
    .fetch();
export const changeParallelTranscriptionLimit = async (connection, userId, limit) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).queueTranscriptionLimit.url({ value: limit }))
    .post()
    .send();
export const changeDirectTranscriptionLimit = async (connection, userId, limit) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).directTranscriptionLimit.url({ value: limit }))
    .post()
    .send();
export const abandonProjectsIn = async (connection, userId, abandonProjects) => {
    const body = {
        Age: abandonProjects.inDays === null ? null : `${String(abandonProjects.inDays)}.00:00:00`,
    };
    return connection.authFetch()
        .postJson(body)
        .url(apiV2.admin.users.id(Number(userId)).abandonProjectsIn.url())
        .receive(json()
        .finally(() => 'unexpected'))
        .fetchStrict();
};
export const fetchStorageStatistics = async (connection, userId, userDataOnly) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).storageStatistics.url({ userDataOnly }))
    .receive(json())
    .fetch();
export const fetchStripeExternalInfo = async (connection, userId) => connection.authFetch()
    .url(apiV2.admin.users.id(userId).externalInfo.url())
    .receive(json())
    .fetch();
export const createStripeExternalInfo = async (connection, userId, stripeCustomerId, lastRoleClaim) => {
    const body = {
        ExternalInfo: {
            StripeCustomerId: stripeCustomerId,
            UserId: userId,
            LastRoleClaim: lastRoleClaim,
        },
    };
    return connection.authFetch()
        .postJson(body)
        .url(apiV2.admin.users.id(userId).externalInfo.url())
        .send();
};
