import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Card, Tabs, } from 'antd';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import DashboardPage from 'screens/DashboardScreen/components/DashboardPage';
import * as TeamAPI from 'api/team-api';
import MissingSubpage from 'components/MissingSubpage';
import CreditInfo from './CreditInfo';
import TranscriptionInfo from './TranscriptionInfo';
import TeamPreferences from './TeamPreferences/index';
import MyProfile from './MyProfile';
import TeamMembers from './TeamMembers';
import './style.less';
const AccountPage = ({ match }) => {
    const { session, refetchLogin } = useSession();
    const { login } = session;
    const { subpage } = match.params;
    const canChangePassword = !login.hasClaim('sso:enabled');
    const canBuyCredit = login.hasClaim('orders:create') && login.hasClaim('team:owner');
    const [team, setTeam] = useState('loading');
    const history = useHistory();
    const handleTabChange = (key) => {
        if (key === 'transcription') {
            history.push('/account');
        }
        else {
            history.push(`/account/${key}`);
        }
    };
    const fetchTeam = async () => {
        const fetchedTeam = await TeamAPI.fetchCurrentTeam(session.connection);
        setTeam(fetchedTeam);
    };
    useEffect(() => {
        void fetchTeam();
        void refetchLogin();
    }, []);
    const tabPanes = [
        {
            key: 'transcription',
            label: txt('transcriptions'),
        },
        {
            key: 'credit',
            label: txt('creditAndPayments'),
        },
        {
            key: 'members',
            label: txt('members'),
        },
        {
            key: 'preferences',
            label: txt('preferences'),
        },
        {
            key: 'myprofile',
            label: txt('myProfile'),
        },
    ];
    return (_jsx(DashboardPage, { title: txt('account'), children: _jsx(Card, { className: "account-page-card", bordered: false, children: _jsxs("div", { className: "account-page-card__content", children: [_jsx(Tabs, { items: canBuyCredit ? tabPanes : tabPanes.filter((pane) => pane.key !== 'credit'), activeKey: subpage !== null && subpage !== void 0 ? subpage : 'transcription', onChange: (key) => handleTabChange(key) }), _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: "/account", render: () => (_jsx(TranscriptionInfo, { credit: team !== 'loading' ? team.remainingTotalCredit : 'loading' })) }), _jsx(Route, { exact: true, path: "/account/credit", render: () => {
                                    if (session.login.hasClaim('orders:create')) {
                                        return (_jsx(CreditInfo, { totalCredit: team !== 'loading' ? team.remainingTotalCredit : 'loading', payAsYouGoCredit: team !== 'loading' ? team.remainingPayAsYouGoCredit : 'loading', onRefetchCredit: fetchTeam }));
                                    }
                                    return (_jsx(MissingSubpage, { backLink: "/account", backName: "account" }));
                                } }), _jsx(Route, { exact: true, path: "/account/members", render: () => (_jsx(TeamMembers, { ownerEmail: team !== 'loading' ? team.ownerEmail : 'loading' })) }), _jsx(Route, { exact: true, path: "/account/preferences", render: () => (_jsx(TeamPreferences, { team: team })) }), _jsx(Route, { exact: true, path: "/account/myprofile", render: () => (_jsx(MyProfile, { canChangePassword: canChangePassword, teamOwner: team !== 'loading' ? team.ownerEmail : 'loading' })) }), _jsx(Route, { render: () => (_jsx(MissingSubpage, { backLink: "/account", backName: "account" })) })] })] }) }) }));
};
export default AccountPage;
