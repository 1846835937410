import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useContext, createContext } from 'react';
import Screen from 'components/Screen';
import { getAuthString } from 'libs/client-env';
import { useAppContext } from 'App/app-context';
import { Redirect } from 'react-router-dom';
import equals from 'fast-deep-equal';
import Loading from 'components/Loading';
import { openByAuthString } from 'api/session';
import { fetchLoginInformation, storeUserSettings } from 'api/user-api';
import { storeCurrentTeamSettings } from 'api/team-api';
import ActivationScreen from './ActivationScreen';
export const sessionContext = createContext({});
export const useSession = () => useContext(sessionContext);
const setApaFavicon = () => {
    const faviconLink = document.querySelector('link[type="image/x-icon"]');
    faviconLink === null || faviconLink === void 0 ? void 0 : faviconLink.remove();
    const iconLinks = document.querySelectorAll('link.favicon');
    Array.from(iconLinks).forEach((link) => {
        const url = new URL(link.href);
        // eslint-disable-next-line no-param-reassign
        link.href = url.pathname.replace('beey', 'apa');
    });
};
export const Authenticated = ({ children }) => {
    const { session, updateSession, clearSession, triggerVersionMismatch, } = useAppContext();
    useEffect(() => {
        const resumeSession = async () => {
            if (session === 'continue') {
                const authString = getAuthString();
                if (authString === null) {
                    void clearSession();
                    return;
                }
                const sessionResult = await openByAuthString(authString, clearSession, triggerVersionMismatch);
                if (sessionResult.isFail()) {
                    global.logger.error('resuming session failed', { error: sessionResult.err() });
                    void clearSession();
                }
                else {
                    updateSession(sessionResult.get());
                }
            }
        };
        void resumeSession();
    }, []);
    useEffect(() => {
        if (session === 'continue' || session === 'closed') {
            return;
        }
        if (session.login.hasClaim('dataTheme:apa')) {
            setApaFavicon();
        }
    }, [session]);
    if (session === 'continue') {
        return (_jsx(Screen, { intro: true, children: _jsx(Loading, {}) }));
    }
    if (session === 'closed') {
        return _jsx(Redirect, { to: "/login" });
    }
    if (session.login.user.role === 'Promo') {
        return (_jsx(ActivationScreen, { session: session }));
    }
    const updateUser = (updateFn) => {
        const newUser = updateFn(session.login.user);
        if (!equals(newUser.settings, session.login.user.settings)) {
            void storeUserSettings(session.connection, newUser.settings);
            // storeSettings are used in UsersSubpage in API list users, orderby: updated.
            // Param Updated is actualised accord. date from storeSetting
        }
        updateSession(session.withUser(newUser));
    };
    const refetchLogin = async () => {
        const login = await fetchLoginInformation(session.connection);
        updateSession(session.withLogin(login));
    };
    const updateUserSettings = (values) => {
        updateUser((user) => (Object.assign(Object.assign({}, user), { settings: Object.assign(Object.assign({}, user.settings), values) })));
    };
    const updateTeamSettings = async (values) => {
        const newSettings = Object.assign(Object.assign({}, session.teamSettings), values);
        const result = await storeCurrentTeamSettings(session.connection, newSettings);
        if (result.isSuccess()) {
            updateSession(session.withTeamSettings(newSettings));
        }
    };
    return (
    // NOTE: Neser!
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    _jsx(sessionContext.Provider, { value: {
            session, updateUser, updateUserSettings, updateTeamSettings, refetchLogin, clearSession,
        }, children: children }));
};
