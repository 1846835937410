import { durationInSeconds } from 'libs/duration';
import ProjectStateHandler from './project-state-handler';
export default class TranscriptionProgressHandler extends ProjectStateHandler {
    constructor(messageStream) {
        super(messageStream, 'not-in-progress');
        this.handleMediaIdentificationProgress = (message) => {
            if (message.data.kind === 'ApproximateDuration' || message.data.kind === 'Duration') {
                this.recordingDuration = durationInSeconds(message.data.duration);
            }
        };
        this.handleTranscriptionTrackingStarted = () => this.updateStatus({ transcribed: 0, duration: this.recordingDuration });
        this.handleRecognitionProgress = (message) => {
            if (message.source === 'buffer') {
                return;
            }
            if (message.data.word !== null) {
                const transcribed = durationInSeconds(message.data.word.end);
                const progress = {
                    transcribed,
                    duration: this.recordingDuration === 0 ? 'invalid' : this.recordingDuration,
                };
                this.updateStatus(progress);
            }
        };
        this.handleTranscriptionTrackingCompleted = () => this.updateStatus('not-in-progress');
        this.recordingDuration = 'invalid';
        this.addStreamListener('MediaIdentification', 'Progress', this.handleMediaIdentificationProgress);
        this.addStreamListener('TranscriptionTracking', 'Started', this.handleTranscriptionTrackingStarted);
        this.addStreamListener('TranscriptionStreaming', 'Progress', this.handleRecognitionProgress);
        this.addStreamListener('TranscriptionTracking', 'Completed', this.handleTranscriptionTrackingCompleted);
    }
    projectReloaded(project) {
        if (project.initialTrsxId !== null) {
            this.updateStatus('not-in-progress');
        }
    }
}
