import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ColumnHeader from 'components/ColumnHeader';
import { txt } from 'libs/i18n';
import { Flex, Space } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import DateRangeFilterDropdown from 'components/DateRangeFilterDropdown';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import clsx from 'clsx';
import IconButton from 'components/IconButton';
import { CloseOutlined } from '@ant-design/icons';
import { ExpandedContentWithProjects } from '../TeamExtendedContent';
import { formatDuration } from '../TranscriptionOverviewForTeamMember';
import styles from '../style.module.less';
const CREDITINTO_TEAMOWNER_TABLE_ID = 'creditInfo-teamowner-table'; // Must be unique across all tables in Beey!!
const getCreationDateRange = (from, to) => {
    const fromDate = from.slice(0, 7);
    const toDate = to.slice(0, 7);
    const fromFormatted = dayjs(fromDate).format('MM/YYYY');
    const toFormatted = dayjs(toDate).format('MM/YYYY');
    const isDateRangeWithinSameMonth = fromDate.localeCompare(toDate);
    if (isDateRangeWithinSameMonth === 0) {
        return `${fromFormatted}`;
    }
    return `${fromFormatted} - ${toFormatted}`;
};
export const getDateCellContent = (member, filterDates) => {
    if (member.from === null || member.to === null) {
        return filterDates.length !== 0 ? getCreationDateRange(filterDates[0], filterDates[1]) : '';
    }
    return `${getCreationDateRange(member.from, member.to)}`;
};
const CreditInfoForTeamOwner = ({ teamCredit, memberProjects, onFetchTeamTranscriptionPerPeriod, onFetchMemberTranscriptionLogState, }) => {
    var _a;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [expandedRowProperties, setExpandedRowProperties] = useState({
        key: [],
        email: '',
    });
    const defaultSieve = {
        filterDates: [],
        sortBy: 'Email',
        sortOrder: 'ascend',
    };
    const tableView = useTableView(CREDITINTO_TEAMOWNER_TABLE_ID, {
        pageSize: 10,
        sieve: defaultSieve,
    });
    const { sortBy, sortOrder, filterDates } = (_a = tableView.getViewParams().sieve) !== null && _a !== void 0 ? _a : defaultSieve;
    const isDefaultSetting = (sortBy === 'Email'
        && filterDates.length === 0
        && sortOrder);
    const handleFilterDatesChanged = (dates) => {
        const oldSieve = tableView.getViewParams().sieve;
        tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { filterDates: dates }));
    };
    const handleClearSieve = () => {
        const oldSieve = tableView.getViewParams().sieve;
        tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { filterDates: [], sortBy: 'Email', sortOrder: 'ascend' }));
    };
    const handleSort = (columnKey, sortingOrder) => {
        tableView.setSieve({
            filterDates,
            sortBy: columnKey,
            sortOrder: sortingOrder,
        });
    };
    const handleRowExpanded = (expanded, member) => {
        setExpandedRowProperties({
            key: expanded ? [member.id] : [],
            email: (expanded && member.email !== null) ? member.email : '',
        });
        void onFetchMemberTranscriptionLogState(member.id, filterDates);
    };
    const columnsCredit = [
        {
            title: (_jsx(ColumnHeader, { title: "teamMembers", columnKey: "Email", sortOrder: sortOrder, isSorting: sortBy === 'Email', onSort: handleSort })),
            key: 'Email',
            render: (member) => {
                if (member.email === null) {
                    return _jsx("em", { children: txt('deletedMembers') });
                }
                return member.email;
            },
        },
        {
            title: (_jsx(ColumnHeader, { title: "chargedCredit", columnKey: "ChargedMinutes", sortOrder: sortOrder, isSorting: sortBy === 'ChargedMinutes', onSort: handleSort })),
            dataIndex: 'chargedCredit',
            key: 'ChargedMinutes',
            render: (minutes) => `${formatDuration(minutes)}`,
        },
        {
            title: txt('forPeriod'),
            render: (member) => getDateCellContent(member, filterDates),
            onFilterDropdownOpenChange: (visibility) => setDropdownVisible(visibility),
            filterDropdownOpen: dropdownVisible,
            filterDropdown: (_jsx(DateRangeFilterDropdown, { filterDates: filterDates, onDropdownVisibility: setDropdownVisible, onClearSieve: handleClearSieve, onFilterDatesChanged: handleFilterDatesChanged })),
        },
    ];
    const totalTranscribedFooter = () => {
        if (teamCredit !== 'loading') {
            return (_jsxs("div", { className: styles.footer, children: [_jsxs(Space, { children: [_jsx("span", { className: styles.total, children: filterDates.length === 0 ? txt('total') : txt('totalPerPeriod') }), _jsx("span", { children: formatDuration(teamCredit.totalCharged - teamCredit.fromDeleted) })] }), _jsx("div", { className: styles.deleted, children: _jsxs(Space, { children: [_jsxs("span", { children: [txt('deletedAccounts'), ":"] }), _jsx("span", { children: formatDuration(teamCredit.fromDeleted) })] }) })] }));
        }
        return null;
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, { gap: "middle", align: "baseline", children: [_jsx("h3", { className: styles.tableTitle, children: txt('credit') }), isDefaultSetting === false ? (_jsxs(IconButton, { className: clsx(styles.toolbarButton, styles.clearSortIcon), onClick: handleClearSieve, children: [_jsx(CloseOutlined, {}), _jsx("span", { children: txt('clearAll') })] })) : null] }), _jsx(ResponsiveTable, { onViewChange: () => onFetchTeamTranscriptionPerPeriod(filterDates, sortBy !== null && sortBy !== void 0 ? sortBy : 'Email', sortOrder !== null && sortOrder !== void 0 ? sortOrder : 'ascend'), bordered: true, className: styles.table, columns: columnsCredit, dataSource: teamCredit === 'loading' ? [] : teamCredit.data.list, rowKey: (teamMember) => teamMember.id, tableView: tableView, recordCount: teamCredit === 'loading' ? 0 : teamCredit.data.totalCount, loading: teamCredit === 'loading', footer: () => totalTranscribedFooter(), expandable: {
                    expandedRowRender: (record) => ExpandedContentWithProjects(record.email !== null ? record.email : '', record.id, memberProjects, filterDates, true),
                    onExpand: handleRowExpanded,
                    // NOTE: "key === -1" represents aggregated data for deleted members.
                    rowExpandable: (member) => member.id !== -1,
                    expandedRowKeys: expandedRowProperties.key,
                }, pageSizeOptions: ['10', '20', '50', '100'] })] }));
};
export default CreditInfoForTeamOwner;
