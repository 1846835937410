import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormModal from 'components/FormModal';
import { useSession } from 'components/Authenticated';
import TimerangeSelectionForm, { formatTimeToISOString, getTimeInSeconds } from 'components/TimerangeSelectionForm';
import { Form, App } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { summarizeProject } from 'api/model/services/services-api';
import { CopyOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import { useEffect, useState } from 'react';
import { getCurrentUiLocale, getUiLocales } from 'libs/locales/ui';
import LanguageSelect from 'components/LanguageSelect';
import styles from './style.module.less';
const SummarizeModal = ({ visible, onSetSummarizeModalVisible, project, selectedTextTimestamps, }) => {
    var _a, _b;
    const { session } = useSession();
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const [isLoading, setIsLoading] = useState(false);
    const durationInSeconds = getTimeInSeconds(project.length);
    const selectedRangeEnd = selectedTextTimestamps === null
        || selectedTextTimestamps.end === Infinity ? durationInSeconds : selectedTextTimestamps.end;
    const mediaBegin = Form.useWatch('begin', form);
    const mediaEnd = Form.useWatch('end', form);
    const isUncutMedia = mediaBegin === '00:00:00.000' && mediaEnd === formatTimeToISOString(durationInSeconds);
    const initialValues = {
        begin: selectedTextTimestamps === null
            ? '00:00:00.000'
            : formatTimeToISOString(selectedTextTimestamps.begin),
        end: selectedTextTimestamps === null
            // NOTE: some projects return duration without milliseconds
            ? formatTimeToISOString(durationInSeconds)
            : formatTimeToISOString(selectedRangeEnd),
        language: (_b = (_a = project.transcriptionConfig) === null || _a === void 0 ? void 0 : _a.language) !== null && _b !== void 0 ? _b : getCurrentUiLocale(),
    };
    useEffect(() => {
        if (visible) {
            form.setFieldsValue(initialValues);
        }
    }, [visible]);
    const handleFullLengthSelected = () => {
        form.setFieldsValue({
            begin: '00:00:00.000',
            end: formatTimeToISOString(durationInSeconds),
        });
    };
    const handleSummarize = async () => {
        setIsLoading(true);
        const result = await summarizeProject(session.connection, project.id, form.getFieldValue('language'), mediaBegin, mediaEnd);
        if (result.isSuccess()) {
            setIsLoading(false);
            form.setFieldValue('summarization', result.get().summarization[0]);
        }
    };
    const handleCopy = async () => {
        await navigator.clipboard.writeText(form.getFieldValue('summarization'));
        void message.success(txt('textCopied'));
    };
    const handleClose = () => {
        onSetSummarizeModalVisible(false);
        form.resetFields();
    };
    return (_jsxs(FormModal, { open: visible, onCancel: handleClose, footer: false, children: [_jsx("h3", { children: txt('summarizeProject') }), _jsxs(Form, { form: form, initialValues: initialValues, onFinish: handleSummarize, children: [_jsx(Form.Item, { name: "language", label: txt('language'), children: _jsx(LanguageSelect, { locales: getUiLocales() }) }), _jsx(TimerangeSelectionForm, { buttonText: txt('generateSummary'), loading: isLoading, onFullLengthSelected: handleFullLengthSelected, wholeLength: isUncutMedia, durationInSeconds: durationInSeconds }), _jsxs("div", { className: styles.summary, children: [_jsx("div", { className: styles.copyIcon, children: _jsx(CopyOutlined, { onClick: handleCopy }) }), _jsx(Form.Item, { name: "summarization", children: _jsx(TextArea, { className: styles.textArea, autoSize: { minRows: 4, maxRows: 8 } }) })] })] })] }));
};
export default SummarizeModal;
