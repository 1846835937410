import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { json, } from '@newtontechnologies/beey-api-js-client/receivers';
export const getBillingInfo = async (connection) => connection.authFetch()
    .url(apiV2.currentUser.paymentInfo.url())
    .receive(json()
    .catchHttpError((response) => (response.status === 401 ? { error: 'unauthorized' } : 'unknown'))
    .finally(() => 'unexpected'))
    .fetchStrict();
export const getCountryList = async (connection) => connection.authFetch()
    .url(apiV2.currentUser.paymentInfo.countryList.url())
    .receive(json())
    .fetch();
export const saveBillingInfo = async (connection, params) => {
    const body = {
        email: params.email.trim(),
        phoneNumber: params.phoneNumber.trim(),
        name: params.name.trim(),
        taxIdentificationNumber: params.taxIdentificationNumber.trim(),
        companyIdentificationNumber: params.companyIdentificationNumber.trim(),
        reverseCharge: params.reverseCharge,
        address: params.address.trim(),
        addressComplement: params.addressComplement.trim(),
        postalCode: params.postalCode.trim(),
        city: params.city.trim(),
        country: params.country,
        countryCode: params.countryCode,
    };
    return connection.authFetch()
        .url(apiV2.currentUser.paymentInfo.url())
        .postJson({ paymentInfo: body })
        .receive(json()
        .finally(() => 'unexpected'))
        .fetchStrict();
};
const getFieldError = (errorMessage) => {
    const field = errorMessage.split(':')[0];
    const error = errorMessage.split(':')[1];
    return { field, error };
};
export const validateBillingInfo = (connection, paymentInfo) => connection.authFetch()
    .url(apiV2.currentUser.paymentInfo.validate.url())
    .postJson({ paymentInfo })
    .receive(json()
    .catchHttpError((_, errorPayload) => {
    const errors = errorPayload.errors.reduce((acc, billingError) => {
        const { field, error } = getFieldError(billingError.message);
        // eslint-disable-next-line no-param-reassign
        acc.fields[field] = error;
        return acc;
    }, { fields: {} });
    if (errorPayload.data !== undefined) {
        return { error: Object.assign(Object.assign({}, errors), { data: errorPayload.data }) };
    }
    return { error: errors };
}))
    .fetchStrict();
