import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { labelTrsx } from 'api/project-api';
import deepEqual from 'fast-deep-equal';
import { Button, Form, App, Tabs as TabKeys, Divider, Flex, } from 'antd';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import * as ProjectAPI from 'api/project-api';
import * as clientEnv from 'libs/client-env';
import FormModal from 'components/FormModal';
import TemplateSection from './TemplateSection';
import { useSubModeTutorial } from '../../subModeTutorial';
import { captionParametersFromSubtitlesSettings, subtitlesSettingsFromCaptionParameters } from './parameters-conversion';
import ConvertToSubsContent from './ConvertToSubsContent';
import FormatTab from './FormatTab';
import WarningsTab from './WarningsTab';
import AdvancedSettingsTab from './AdvancedSettingsTab';
import ResetTab from './ResetTab';
import AnimationTab from './AnimationTab';
import styles from './style.module.less';
import CaptionPreview from './CaptionPreview';
export const SUBTITLE_LENGTH = { min: 15, max: 60 };
const CaptionReviewModal = ({ visible, onCancel, project, isCaptionMode, enqueueProjectUpdate, editorController, isWaveVisible, onWaveVisibilityChange, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { captions } = editorController;
    const { session, updateUserSettings } = useSession();
    const { message } = App.useApp();
    const [isConverting, setIsConverting] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState('format');
    const [form] = Form.useForm();
    const { tutorialContinue, tutorialGoNext, retrieveTutorialState } = useSubModeTutorial();
    const isTutorialRunning = retrieveTutorialState() === 'running';
    const getFormInitialValues = () => {
        const userSavedSubtitlesSettings = session.login.user.settings.subtitles;
        const currentDocumentSettings = captions.parameters === null
            ? null
            : subtitlesSettingsFromCaptionParameters(captions.parameters);
        return Object.assign(Object.assign({}, userSavedSubtitlesSettings), currentDocumentSettings);
    };
    const initialValues = getFormInitialValues();
    const getTeamTemplate = (templateName) => {
        const teamTemplate = session.teamSettings.subtitlesTemplates.find((template) => template.name === templateName);
        return teamTemplate !== null && teamTemplate !== void 0 ? teamTemplate : null;
    };
    const hasSelectedTemplateChanged = (template) => {
        const teamTemplate = getTeamTemplate(template.name);
        return teamTemplate === null || !deepEqual(teamTemplate.settings, template.settings);
    };
    const userSavedTemplate = session.login.user.settings.teamSubtitlesTemplate;
    const [selectedTemplate, setSelectedTemplate] = useState({
        name: (userSavedTemplate === null || hasSelectedTemplateChanged(userSavedTemplate) ? '' : userSavedTemplate.name),
        settings: initialValues,
        isEditable: (_a = userSavedTemplate === null || userSavedTemplate === void 0 ? void 0 : userSavedTemplate.isEditable) !== null && _a !== void 0 ? _a : true,
        edited: false,
    });
    useEffect(() => {
        var _a, _b, _c, _d;
        if (visible === true) {
            setActiveTabKey('format');
            tutorialContinue();
            form.setFieldsValue(initialValues);
            if (isCaptionMode) {
                setSelectedTemplate(Object.assign(Object.assign({}, selectedTemplate), { name: (_d = (_c = getTeamTemplate((_b = (_a = captions.parameters) === null || _a === void 0 ? void 0 : _a.templateName) !== null && _b !== void 0 ? _b : '')) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '', edited: hasSelectedTemplateChanged(selectedTemplate) }));
            }
        }
    }, [visible]);
    const updatePreview = (values) => {
        try {
            editorController.captions.updateCaptionParameters(Object.assign({}, captionParametersFromSubtitlesSettings(values)));
            updateUserSettings({
                subtitles: values,
                teamSubtitlesTemplate: selectedTemplate.edited ? null : selectedTemplate,
            });
            void message.success(txt('subtitleUpdateSuccess'));
            onCancel();
        }
        catch (error) {
            global.logger.error('failed to update captions preview for unhandled reason', {}, error);
            void message.error(txt('unexpectedError'));
        }
    };
    const generatePreview = async (values) => {
        const captionParameters = captionParametersFromSubtitlesSettings(values);
        await enqueueProjectUpdate(async (initialProject) => {
            const labelTrsxResult = await labelTrsx(session.connection, initialProject, captionParameters, initialProject.currentTrsxId === null);
            if (labelTrsxResult.isSuccess()) {
                const trsx = await ProjectAPI.fetchTrsx(session.connection, project, 'trsx');
                editorController.importTrsx(trsx, true, labelTrsxResult.get(), false);
                return labelTrsxResult.get();
            }
            if (labelTrsxResult.err() === 'conflict') {
                void message.error(txt('subtitleMessageErrorTryAgain'));
            }
            else if (labelTrsxResult.err() === 'invalid-sub-length') {
                void message.error(`${txt('invalidSubLength')} ${SUBTITLE_LENGTH.min}-${SUBTITLE_LENGTH.max}`);
            }
            else if (labelTrsxResult.err() === 'negative-sub-length') {
                void message.error(txt('integerSubtitleLineLength'));
            }
            else {
                void message.error(txt('subtitleMessageErrorTryAgain'));
            }
            throw Error(labelTrsxResult.err());
        });
        updateUserSettings({
            subtitles: values,
            teamSubtitlesTemplate: selectedTemplate.edited ? null : selectedTemplate,
        });
        void message.success(txt('subtitleMessageSuccess'));
    };
    const handleValuesChange = () => {
        if (selectedTemplate.name === '') {
            return;
        }
        const changedSettings = Object.assign(Object.assign({}, selectedTemplate.settings), form.getFieldsValue());
        setSelectedTemplate(Object.assign(Object.assign({}, selectedTemplate), { edited: !deepEqual(selectedTemplate.settings, changedSettings) }));
    };
    const handleSubmit = async (formValues) => {
        if (isCaptionMode) {
            const subsSettings = Object.assign(Object.assign({}, formValues), { templateName: selectedTemplate.name });
            updatePreview(subsSettings);
        }
        else {
            const { maxLineLength, subtitlerMaxLineCount, enablePresegmentation } = formValues;
            const predefinedSettings = selectedTemplate.name !== '' ? selectedTemplate.settings : initialValues;
            const mergedSettings = Object.assign(Object.assign({}, predefinedSettings), { maxLineLength,
                subtitlerMaxLineCount,
                enablePresegmentation, templateName: selectedTemplate.name });
            await generatePreview(mergedSettings);
        }
        if (clientEnv.getIsWaveVisible() && !isWaveVisible) {
            onWaveVisibilityChange(true);
        }
    };
    const handleCancel = () => {
        var _a;
        setSelectedTemplate(Object.assign(Object.assign({}, selectedTemplate), { name: (_a = userSavedTemplate === null || userSavedTemplate === void 0 ? void 0 : userSavedTemplate.name) !== null && _a !== void 0 ? _a : '', settings: initialValues, edited: false }));
        onCancel();
    };
    const modalHeader = (isCaptionMode ? (_jsx("h2", { children: txt('subtitleSettings') })) : (_jsxs(_Fragment, { children: [_jsx("h2", { children: txt('convertToSubtitles') }), _jsx("p", { className: styles.subHeader, children: txt('subtitleModeDescription') })] })));
    const onModalOK = () => {
        form.submit();
        tutorialGoNext();
    };
    const tabs = [
        {
            key: 'format',
            label: txt('format'),
            forceRender: true,
            children: (_jsx(FormatTab, { form: form, isCaptionMode: isCaptionMode, onValuesChange: handleValuesChange, initialValues: initialValues })),
        },
        {
            key: 'animation',
            label: txt('animation'),
            disabled: isTutorialRunning,
            forceRender: true,
            children: (_jsx(AnimationTab, { highlightingMode: Form.useWatch('highlightingMode', form) })),
        },
        {
            key: 'advancedSettings',
            label: txt('advancedSettings'),
            disabled: isTutorialRunning,
            forceRender: true,
            children: (_jsx(AdvancedSettingsTab, { form: form, onValuesChange: handleValuesChange, isCaptionMode: isCaptionMode })),
        },
        {
            key: 'warningSettings',
            label: txt('warningSettings'),
            disabled: isTutorialRunning,
            forceRender: true,
            children: (_jsx(WarningsTab, { form: form, onValuesChange: handleValuesChange })),
        },
        {
            key: 'switchToTranscription',
            label: txt('switchToTranscription'),
            disabled: isTutorialRunning,
            children: (_jsx(ResetTab, { editorController: editorController, onCancel: handleCancel, project: project })),
        },
    ];
    // NOTE: Values from useWatch are undefined on first render. See https://github.com/ant-design/ant-design/issues/49010.
    const subtitleSettings = {
        defaultFontSize: (_b = Form.useWatch('defaultFontSize', form)) !== null && _b !== void 0 ? _b : initialValues.defaultFontSize,
        defaultBackgroundTransparency: (_c = Form.useWatch('defaultBackgroundTransparency', form)) !== null && _c !== void 0 ? _c : initialValues.defaultBackgroundTransparency,
        defaultCaptionPosition: (_d = Form.useWatch('defaultCaptionPosition', form)) !== null && _d !== void 0 ? _d : initialValues.defaultCaptionPosition,
        defaultColor: (_e = Form.useWatch('defaultColor', form)) !== null && _e !== void 0 ? _e : initialValues.defaultColor,
        defaultFontName: (_f = Form.useWatch('defaultFontName', form)) !== null && _f !== void 0 ? _f : initialValues.defaultFontName,
        upperCaseAllText: (_g = Form.useWatch('upperCaseAllText', form)) !== null && _g !== void 0 ? _g : initialValues.upperCaseAllText,
        subtitlerMaxLineCount: (_h = Form.useWatch('subtitlerMaxLineCount', form)) !== null && _h !== void 0 ? _h : initialValues.subtitlerMaxLineCount,
        speakerSignPlacement: (_j = Form.useWatch('speakerSignPlacement', form)) !== null && _j !== void 0 ? _j : initialValues.speakerSignPlacement,
        useSpeakerName: (_k = Form.useWatch('useSpeakerName', form)) !== null && _k !== void 0 ? _k : initialValues.useSpeakerName,
        highlightingMode: (_l = Form.useWatch('highlightingMode', form)) !== null && _l !== void 0 ? _l : initialValues.highlightingMode,
        unHighlightedColor: (_m = Form.useWatch('unHighlightedColor', form)) !== null && _m !== void 0 ? _m : initialValues.unHighlightedColor,
    };
    const onSubmit = async (values) => {
        setIsConverting(true);
        await handleSubmit(Object.assign(Object.assign({}, initialValues), values));
        setIsConverting(false);
    };
    return (_jsx(FormModal, { className: styles.captionReviewModal, title: modalHeader, footer: false, open: visible, onCancel: handleCancel, width: 750, destroyOnClose: true, keyboard: !isTutorialRunning, closable: !isTutorialRunning, children: _jsxs(Form, { form: form, className: "sub-mode-tutorial-step-save-settings", labelCol: { span: 12 }, labelAlign: "left", initialValues: initialValues, onFinish: onSubmit, labelWrap: true, onValuesChange: handleValuesChange, children: [!isCaptionMode ? (_jsx(ConvertToSubsContent, { form: form, isCaptionMode: isCaptionMode, selectedTemplate: selectedTemplate, onSelectTemplate: (template) => {
                        setSelectedTemplate(Object.assign(Object.assign({}, template), { edited: false }));
                    } })) : (_jsxs(_Fragment, { children: [_jsx(TemplateSection, { currentTemplate: selectedTemplate, onCurrentTemplateUpdated: setSelectedTemplate, form: form }), _jsx(TabKeys, { activeKey: activeTabKey, onChange: setActiveTabKey, items: tabs }), (activeTabKey !== 'warningSettings' && activeTabKey !== 'switchToTranscription') && (_jsxs(_Fragment, { children: [_jsx(Divider, { className: styles.divider }), _jsx(CaptionPreview, { subtitleSettings: subtitleSettings })] }))] })), _jsxs(Flex, { className: styles.buttons, gap: "small", justify: "end", children: [_jsx(Button, { type: "default", onClick: handleCancel, disabled: retrieveTutorialState() === 'running', children: txt('cancel') }, "back"), _jsx(Button, { type: "primary", onClick: onModalOK, disabled: isConverting, children: isCaptionMode ? txt('save') : txt('convertToSubtitles') }, "submit")] })] }) }));
};
export default CaptionReviewModal;
