import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { FundOutlined } from '@ant-design/icons';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import * as TeamAPI from 'api/team-api';
import dayjs from 'dayjs';
import TranscriptionOverviewForTeamMember from './TranscriptionOverviewForTeamMember';
import CreditInfoForTeamMember from './CreditInfoForTeamMember';
import CreditInfoForTeamOwner from './CreditInfoForTeamOwner';
import StorageInfoForTeamOwner from './StorageInfoForTeamOwner';
import styles from './style.module.less';
const getCreationDateRange = (from, to) => {
    const fromDate = from.slice(0, 7);
    const toDate = to.slice(0, 7);
    const fromFormatted = dayjs(fromDate).format('MM/YYYY');
    const toFormatted = dayjs(toDate).format('MM/YYYY');
    const isDateRangeWithinSameMonth = fromDate.localeCompare(toDate);
    if (isDateRangeWithinSameMonth === 0) {
        return `${fromFormatted}`;
    }
    return `${fromFormatted} - ${toFormatted}`;
};
export const getDateCellContent = (member, filterDates) => {
    if (member.from === null || member.to === null) {
        return filterDates.length !== 0 ? getCreationDateRange(filterDates[0], filterDates[1]) : '';
    }
    return `${getCreationDateRange(member.from, member.to)}`;
};
const TranscriptionInfo = ({ credit }) => {
    const { session } = useSession();
    const { login } = session;
    const [memberProjects, setMemberProjects] = useState('loading');
    const [teamCredit, setTeamCredit] = useState('loading');
    const isTeamOwner = session.login.hasClaim('team:owner');
    const fetchTeamCreditPerPeriod = async (filterDates, sortBy, sortOrder) => {
        const fetchedTeamCredit = await TeamAPI.fetchCurrentTeamTranscriptionPerPeriod(session.connection, filterDates, sortBy, sortOrder === 'ascend');
        setTeamCredit(fetchedTeamCredit);
    };
    const fetchMemberTranscriptionLogState = async (memberId, filterDates) => {
        const memberTranscriptionLog = await TeamAPI.fetchMemberTranscriptionLog(session.connection, memberId, filterDates, 0, 0);
        setMemberProjects(memberTranscriptionLog);
    };
    return (_jsx("div", { className: styles.overview, children: isTeamOwner ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.overviewTranscriptions, children: [_jsxs("h3", { className: styles.monthlyOverviewOfTeam, children: [_jsx(FundOutlined, { style: { marginRight: '5px', fontSize: '20px' } }), txt('monthlyOverviewOfTeam')] }), !session.login.hasClaim('signIn:apa') && (_jsxs("p", { className: styles.joinTeam, children: [txt('joinTeam'), _jsx("a", { href: txt('contactOptions'), target: "_blank", rel: "noreferrer", children: txt('contactOurSupport') }), "."] })), _jsx(CreditInfoForTeamOwner, { teamCredit: teamCredit, memberProjects: memberProjects, onFetchTeamTranscriptionPerPeriod: (filterDates, sortBy, sortOrder) => fetchTeamCreditPerPeriod(filterDates, sortBy !== null && sortBy !== void 0 ? sortBy : 'Email', sortOrder !== null && sortOrder !== void 0 ? sortOrder : 'ascend'), onFetchMemberTranscriptionLogState: (memberId, filterDates) => fetchMemberTranscriptionLogState(memberId, filterDates) }), _jsx(StorageInfoForTeamOwner, { memberProjects: memberProjects, onFetchMemberTranscriptionLogState: (memberId, filterDates) => fetchMemberTranscriptionLogState(memberId, filterDates) })] }), !login.hasClaim('orders:create') ? (_jsx(CreditInfoForTeamMember, { credit: credit })) : null] })) : (_jsxs(_Fragment, { children: [_jsx(TranscriptionOverviewForTeamMember, {}), _jsx(CreditInfoForTeamMember, { credit: credit })] })) }));
};
export default TranscriptionInfo;
