import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleFilled } from '@ant-design/icons';
import { Alert, App, Button, Flex, Space, Typography, } from 'antd';
import { Speaker } from 'api/speaker';
import { addSpeaker, saveVoiceSample } from 'api/speaker-api';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import { useState } from 'react';
import styles from './style.module.less';
const { Text } = Typography;
const SpeakerIdTab = ({ speaker, transcriptionLanguage, projectId, speakerNode, onSaveToDb, editorController, }) => {
    const [sampleStatus, setSampleStatus] = useState('new');
    const { message } = App.useApp();
    const { session } = useSession();
    const createNewSpeaker = async () => {
        var _a, _b, _c;
        const newSpeaker = new Speaker(((_a = speaker.firstName) !== null && _a !== void 0 ? _a : '').trim(), speaker.lastName.trim(), ((_b = speaker.role) !== null && _b !== void 0 ? _b : '').trim(), null, null, speaker.diarizationCode, false, (_c = transcriptionLanguage === null || transcriptionLanguage === void 0 ? void 0 : transcriptionLanguage.code) !== null && _c !== void 0 ? _c : null, new Map(), speaker.captionColor, speaker.isDefaultColor);
        const result = await addSpeaker(session.connection, newSpeaker.exportXml());
        if (result.isSuccess()) {
            const dbSpeaker = Speaker.fromXMLString(result.get());
            onSaveToDb(dbSpeaker);
            return dbSpeaker;
        }
        void message.error(txt('unexpectedError'));
        return newSpeaker;
    };
    const saveSpeakerSample = async (currentSpeaker) => {
        setSampleStatus('loading');
        const speakerToSave = currentSpeaker.dbid !== null ? currentSpeaker : await createNewSpeaker();
        const speakerTimeRange = editorController.speakers.getSpeakerTimeRange(speakerNode);
        if (speakerToSave.dbid === null || speakerTimeRange === null) {
            void message.error(txt('sampleSavingFailed'));
            return;
        }
        const sampleLength = speakerTimeRange.end - speakerTimeRange.begin;
        const result = await saveVoiceSample(session.connection, projectId, speakerToSave.dbid, speakerTimeRange.begin, sampleLength);
        if (result.isSuccess()) {
            void message.success(txt('voiceSampleSaved'));
            setSampleStatus('added');
        }
        else if (result.err() === 'invalid-id') {
            const newSpeaker = await createNewSpeaker();
            void saveSpeakerSample(newSpeaker);
        }
        else {
            void message.error(txt('sampleSavingFailed'));
            setSampleStatus('new');
        }
    };
    return (_jsx(Alert, { type: "info", className: styles.alert, message: (_jsxs("div", { children: [`${txt('clearUtterance')}: `, _jsx(Text, { italic: true, className: styles.speakerName, children: speaker.composeLabel() }), "?"] })), description: (_jsxs("div", { children: [_jsx("div", { children: txt('trainSpeaker') }), _jsx(Flex, { justify: "end", children: sampleStatus !== 'added' ? (_jsx(Button, { onClick: () => saveSpeakerSample(speaker), loading: sampleStatus === 'loading', children: txt('apply') })) : (_jsxs(Space, { children: [_jsx(CheckCircleFilled, { className: styles.successIcon }), txt('voiceSampleSaved')] })) })] })) }));
};
export default SpeakerIdTab;
