import { setupNewProject, updateProjectMetadata, uploadTrsx, } from 'api/project-api';
import { Upload } from 'tus-js-client';
import { Observable } from 'rxjs';
import { apiV1 } from '@newtontechnologies/beey-api-js-client/endpoints';
const createProject = async (connection, projectName, transcribeAttrs) => {
    let project = await setupNewProject(connection, projectName);
    project = await updateProjectMetadata(connection, project, 'language', transcribeAttrs.language.code);
    if (transcribeAttrs.trsxFile !== null) {
        const result = await uploadTrsx(connection, project, transcribeAttrs.trsxFile, 'initialTrsx', true);
        if (result.isSuccess()) {
            project = result.get();
        }
        else {
            return { project, status: 'fail' };
        }
        return { project, status: 'success' };
    }
    return { project, status: 'success' };
};
const startTusUpload = (connection, project, mediaFile, subscriber) => {
    const upload = new Upload(mediaFile, {
        endpoint: apiV1.project.uploadMediaFile.url(),
        chunkSize: 2 * 1024 * 1024,
        // NOTE: Logarithmically increasing intervals adding up to 115 seconds -
        // just under server timeout of two minutes
        // SEE: python: [0] + [1000 * round(math.log(x + 2) * 10 - 4) for x in range(9)]
        retryDelays: [0, 3000, 7000, 10000, 12000, 14000, 15000, 17000, 18000, 19000],
        metadata: {
            projectId: String(project.id),
            filename: mediaFile.name,
            filetype: mediaFile.type,
        },
        storeFingerprintForResuming: false,
        async onBeforeRequest(req) {
            req.setHeader('Authorization', await connection.retrieveAuthString());
        },
        onError(error) {
            if ('message' in error) {
                if (error.message.startsWith('tus: failed to upload chunk')) {
                    subscriber.next({ phase: 'severed', projectId: project.id, progress: -1 });
                }
                else if (error.message.startsWith('tus: failed to resume upload')) {
                    subscriber.next({ phase: 'severed', projectId: project.id, progress: -1 });
                }
                else {
                    subscriber.next({ phase: 'failed', projectId: project.id, progress: -1 });
                }
                global.logger.error('upload error', { message: error.message });
            }
            else {
                global.logger.error('upload error', {}, error);
                subscriber.next({ phase: 'failed', projectId: project.id, progress: -1 });
            }
            subscriber.complete();
        },
        onShouldRetry(error, attempt) {
            if ('originalResponse' in error) {
                const status = error.originalResponse.getStatus();
                if (status === 400) {
                    // NOTE: Upload is canceled on backend
                    return false;
                }
                if (status === 401) {
                    // NOTE: The session has expired. There is no point in retrying.
                    return false;
                }
                if (status === 403) {
                    return false;
                }
            }
            global.logger.info('upload retry', { attempt }, error);
            return true;
        },
        onProgress(bytesUploaded, bytesTotal) {
            const progress = Math.round((bytesUploaded / bytesTotal) * 100);
            subscriber.next({ phase: 'running', projectId: project.id, progress });
        },
        onSuccess() {
            subscriber.next({ phase: 'success', projectId: project.id, progress: 100 });
            subscriber.complete();
            global.logger.info('upload success');
        },
    });
    upload.start();
};
export const initiateUpload = (connection, uploadTarget, mediaFile) => new Observable((subscriber) => {
    const { transcribeAttributes } = uploadTarget;
    if (uploadTarget.type === 'new') {
        const { projectName } = uploadTarget;
        void createProject(connection, projectName, transcribeAttributes).then(({ project, status }) => (status === 'success'
            ? startTusUpload(connection, project, mediaFile, subscriber)
            : subscriber.next({
                phase: 'failed',
                failedReason: 'invalid-trsx',
                projectId: project.id,
                progress: NaN,
            })));
    }
    else {
        void updateProjectMetadata(connection, uploadTarget.project, 'language', transcribeAttributes.language.code).then(((project) => startTusUpload(connection, project, mediaFile, subscriber)));
    }
});
