import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { App, Button, Flex, Form, Input, Popconfirm, Spin, } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { updateSpeaker, addSpeaker, deleteVoiceSample, } from 'api/speaker-api';
import { Speaker } from 'api/speaker';
import { useSession } from 'components/Authenticated';
import { getCurrentUiLocale } from 'libs/locales/ui';
import { txt } from 'libs/i18n';
import FormModal from 'components/FormModal';
import dayjs from 'dayjs';
import IconButton from 'components/IconButton';
import SpeakerRole from '../SpeakerRole';
import styles from './style.module.less';
import VoiceSamplePlayer from './VoiceSamplePlayer';
const speakerXmlFromRow = ({ firstName, lastName, role, key, unmaintainedMetadata, }) => {
    const speakerXml = new Speaker(firstName, lastName, role, null, key, null, false, getCurrentUiLocale().code, unmaintainedMetadata);
    return speakerXml.exportXml();
};
const SpeakersModal = ({ visible, action, onClose, speaker = null, onUpdateTable, samples, onUpdateSamples, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const headerText = action === 'addSpeaker' ? txt('addSpeaker') : txt('editSpeaker');
    const buttonText = action === 'addSpeaker' ? txt('add') : txt('save');
    const initialValues = {
        firstName: speaker !== null ? speaker.firstName : '',
        lastName: speaker !== null ? speaker.lastName : '',
        role: speaker !== null ? speaker.role : '',
        key: speaker !== null ? speaker.key : '',
        unmaintainedMetadata: speaker !== null ? speaker.unmaintainedMetadata : new Map(),
    };
    useEffect(() => {
        if (visible) {
            form.setFieldsValue(initialValues);
        }
    }, [visible]);
    const addNewSpeaker = async (newSpeaker) => {
        const result = await addSpeaker(session.connection, speakerXmlFromRow(newSpeaker));
        if (result.isSuccess()) {
            void message.success(txt('speakerSaved'), 2);
        }
        else {
            void message.error(txt('failedToSaveSpeaker'));
        }
    };
    const updateExistingSpeaker = async (updatedSpeaker) => {
        const result = await updateSpeaker(session.connection, speakerXmlFromRow(updatedSpeaker));
        if (result.isSuccess()) {
            void message.success(txt('speakerUpdated'), 2);
        }
        else {
            void message.error(txt('failedToUpdate'));
        }
    };
    const handleSubmit = async (formValues) => {
        const { firstName, lastName, role, key, unmaintainedMetadata, } = formValues;
        const trimmedFormValues = {
            firstName: (firstName !== null && firstName !== void 0 ? firstName : '').trim(),
            lastName: lastName.trim(),
            role: (role !== null && role !== void 0 ? role : '').trim(),
            key,
            unmaintainedMetadata,
        };
        setIsLoading(true);
        if (action === 'addSpeaker') {
            await addNewSpeaker(trimmedFormValues);
        }
        if (action === 'editSpeaker') {
            await updateExistingSpeaker(Object.assign(Object.assign({}, speaker), trimmedFormValues));
        }
        form.resetFields();
        setIsLoading(false);
        onUpdateTable();
        onClose();
    };
    const deleteSample = async (sampleId) => {
        const result = await deleteVoiceSample(session.connection, sampleId);
        if (result.isSuccess()) {
            onUpdateSamples();
            void message.success(txt('voiceSampleDeleted'));
        }
        else {
            void message.error(txt('voiceSampleDeleteFailed'));
        }
    };
    return (_jsxs(FormModal, { footer: [
            _jsx(Button, { onClick: onClose, children: txt('cancel') }, "cancel"),
            _jsxs(Button, { type: "primary", htmlType: "submit", onClick: () => form.submit(), disabled: isLoading, children: [isLoading
                        ? _jsx(Spin, { className: styles.spinnerIcon, indicator: _jsx(LoadingOutlined, {}) })
                        : null, buttonText] }, "submit"),
        ], open: visible, onCancel: onClose, title: headerText, children: [_jsxs(Form, { name: "speaker-modal", form: form, initialValues: initialValues, labelCol: { span: 6 }, labelAlign: "left", onFinish: handleSubmit, preserve: false, children: [_jsx(Form.Item, { name: "firstName", label: txt('firstName'), children: _jsx(Input, { autoFocus: true }) }), _jsx(Form.Item, { name: "lastName", label: txt('lastName'), children: _jsx(Input, {}) }), _jsx(SpeakerRole, {}), _jsx(Form.Item, { name: "key", className: styles.hiddenField, dependencies: ['firstName', 'lastName', 'role'], rules: [
                            ({ getFieldValue }) => ({
                                validator() {
                                    const isAllFieldsEmpty = getFieldValue('lastName').trim() === '' && getFieldValue('firstName').trim() === '' && getFieldValue('role').trim() === '';
                                    if (isAllFieldsEmpty) {
                                        return Promise.reject(txt('addAtLeastOne'));
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ], children: _jsx(Input, { type: "hidden" }) }), _jsx(Form.Item, { name: "unmaintainedMetadata", className: styles.hiddenField, children: _jsx(Input, { type: "hidden" }) })] }), action === 'editSpeaker' && samples.length > 0 && (_jsxs("div", { className: styles.voiceSampleList, children: [_jsx("div", { className: styles.label, children: txt('voiceSamples') }), _jsx(Flex, { vertical: true, gap: "small", children: samples.map((sample) => (_jsxs("div", { className: styles.voiceSample, children: [_jsx("div", { children: dayjs(sample.uploaded).format('DD. MM. YYYY') }, sample.id), _jsx(VoiceSamplePlayer, { sampleId: sample.id }), _jsx(Popconfirm, { title: txt('deleteTemplate'), okType: "primary", okButtonProps: { danger: true }, onConfirm: async () => deleteSample(sample.id), okText: txt('yesDelete'), cancelText: txt('cancel'), children: _jsx(IconButton, { danger: true, children: _jsx(DeleteOutlined, {}) }) })] }, sample.id))) })] }))] }));
};
export default SpeakersModal;
