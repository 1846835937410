import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Divider, Form, InputNumber, message, Switch, } from 'antd';
import FormModal from 'components/FormModal';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import { downloadSubtitles, downloadSubtitlesFromLabeled } from 'api/project-api';
import { subtitlesDefaults } from 'api/settings/user-settings';
import { createBurntCaptionsJob, uploadCaptionsForBurn, startJob, } from 'api/model/services/services-api';
import TimerangeSelectionForm, { formatTimeToISOString, getTimeInSeconds } from 'components/TimerangeSelectionForm';
import TranscodeJobsInfo from 'components/TranscodeJobsInfo';
import AlertWithButton from 'components/AlertWithButton';
import clsx from 'clsx';
import { SUBTITLE_LENGTH } from '../CaptionReviewModal';
import styles from './style.module.less';
import CaptionPreview from '../CaptionReviewModal/CaptionPreview';
import { subtitlesSettingsFromCaptionParameters } from '../CaptionReviewModal/parameters-conversion';
const BurnSubtitlesModal = ({ visible, onCancel, project, isCaptionMode, selectedTextTimestamps, enqueueProjectUpdate, captionsParameters, isRightToLeft, onOpenCaptionReviewModal, }) => {
    const { session } = useSession();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const { id, name, length } = project;
    const { login: { user: { settings: { subtitlesExport: { maxLineLength }, }, }, }, } = session;
    const { connection } = session;
    const durationInSeconds = getTimeInSeconds(length);
    const selectedRangeEnd = selectedTextTimestamps === null
        || selectedTextTimestamps.end === Infinity ? durationInSeconds : selectedTextTimestamps.end;
    const initialValues = {
        subtLength: maxLineLength || SUBTITLE_LENGTH.max,
        begin: selectedTextTimestamps === null
            ? '00:00:00.000'
            : formatTimeToISOString(selectedTextTimestamps.begin),
        end: selectedTextTimestamps === null
            // some projects return duration without milliseconds
            ? formatTimeToISOString(durationInSeconds)
            : formatTimeToISOString(selectedRangeEnd),
    };
    const handleFullLengthSelected = () => {
        form.setFieldsValue({
            begin: '00:00:00.000',
            end: formatTimeToISOString(durationInSeconds),
        });
    };
    const selectedBegin = Form.useWatch('begin', form);
    const selectedEnd = Form.useWatch('end', form);
    const isUncutMedia = selectedBegin === '00:00:00.000' && selectedEnd === formatTimeToISOString(durationInSeconds);
    const uploadCaptions = async (formValues) => {
        setIsLoading(true);
        await enqueueProjectUpdate(async (initialProject) => {
            const jobId = await createBurntCaptionsJob(connection, name, selectedBegin, selectedEnd, id, `${name}_${selectedBegin}_${selectedEnd}`);
            setIsLoading(false);
            const { subtLength, upperCaseAllText } = formValues;
            const result = (isCaptionMode && captionsParameters !== null)
                ? await downloadSubtitlesFromLabeled(session.connection, project, 'ass', 'AllowAll', captionsParameters.upperCaseAllText, captionsParameters.keepInnerLinesStripped, isRightToLeft, captionsParameters.highlightingMode, captionsParameters.unHighlightedColor)
                : await downloadSubtitles(session.connection, project, 'ass', Number(subtLength), subtitlesDefaults.speakerSignPlacement, subtitlesDefaults.pauseBetweenCaptions, subtitlesDefaults.autofillPauseBetweenCaptions, upperCaseAllText, isRightToLeft);
            if (result.isSuccess()) {
                await uploadCaptionsForBurn(connection, result.get(), jobId.toString(), name);
                await startJob(connection, jobId);
                return initialProject;
            }
            void message.error(txt('unexpectedError'));
            throw Error(result.err());
        });
    };
    return (_jsxs(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], getContainer: false, open: visible, className: styles.modal, onCancel: onCancel, destroyOnClose: true, footer: false, children: [_jsx("h3", { children: txt('burnSubtitlesInVideo') }), _jsxs("div", { className: styles.content, children: [_jsxs(Form, { form: form, className: styles.settings, onFinish: uploadCaptions, children: [isCaptionMode ? null : (_jsxs(_Fragment, { children: [_jsxs(Form.Item, { label: txt('subtitleLineLength'), children: [_jsx(Form.Item, { name: "subtLength", noStyle: true, rules: [
                                                    {
                                                        required: true,
                                                        type: 'number',
                                                        min: SUBTITLE_LENGTH.min,
                                                        max: SUBTITLE_LENGTH.max,
                                                        message: `${txt('range')} ${SUBTITLE_LENGTH.min} - ${SUBTITLE_LENGTH.max}`,
                                                    },
                                                ], children: _jsx(InputNumber, { type: "number", autoFocus: true }) }), ' ', txt('charactersPerLine')] }), _jsx(Form.Item, { name: "upperCaseAllText", label: txt('setAllCaps'), valuePropName: "checked", children: _jsx(Switch, { size: "small" }) })] })), _jsx("h4", { className: styles.heading, children: txt('selectedTimeRange') }), _jsx("p", { children: txt('canSelectText') }), _jsx(TimerangeSelectionForm, { onFullLengthSelected: handleFullLengthSelected, alertText: isCaptionMode ? txt('editSettings') : txt('animationInfo'), wholeLength: isUncutMedia, buttonText: txt('burnSubs'), durationInSeconds: durationInSeconds, children: onOpenCaptionReviewModal !== undefined && (_jsx(AlertWithButton, { type: "info", onClick: () => onOpenCaptionReviewModal('burnSubtitles'), message: isCaptionMode ? txt('editSettings') : txt('animationInfo'), buttonText: isCaptionMode ? txt('goToSettings') : txt('convertToSubtitles') })) })] }), _jsx("div", { className: clsx(styles.preview, { [styles.smallerPreview]: isCaptionMode }), children: _jsx(CaptionPreview, { subtitleSettings: captionsParameters !== null
                                ? subtitlesSettingsFromCaptionParameters(captionsParameters)
                                : Object.assign(Object.assign({}, subtitlesDefaults), { defaultColor: subtitlesDefaults.speakerColor }) }) })] }), _jsx(Divider, { className: styles.divider }), _jsx("h4", { className: styles.heading, children: txt('burntVideos') }), _jsx(TranscodeJobsInfo, { workerType: "burn-subs", project: project, visible: visible, form: form, initialValues: initialValues, isLoading: isLoading })] }));
};
export default BurnSubtitlesModal;
