import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { txt } from 'libs/i18n';
import { formatDuration } from '../TranscriptionOverviewForTeamMember';
import ExpandedRowFooter from './ExpandedRowFooter/index';
import styles from './style.module.less';
dayjs.extend(duration);
export const ExpandedContentWithProjects = (email, id, projects, filterDates, showDownload) => {
    const expandedColumns = [
        {
            title: txt('projectID'),
            dataIndex: 'projectId',
        },
        {
            title: txt('projectName'),
            dataIndex: 'projectName',
        },
        {
            title: txt('length'),
            dataIndex: 'transcribedMinutes',
            className: styles.duration,
            render: formatDuration,
        },
        {
            title: txt('created'),
            dataIndex: 'created',
            render: (date) => dayjs(date).format('DD. MM. YYYY'),
        },
    ];
    return (_jsx(Table, { className: "expanded-content-with-projects", columns: expandedColumns, rowKey: (project) => project.projectId, 
        // eslint-disable-next-line react/destructuring-assignment
        dataSource: projects === 'loading' ? [] : projects.list.slice(0, 10), pagination: false, loading: projects === 'loading', footer: () => ExpandedRowFooter(email, id, projects === 'loading' ? [] : projects.list, filterDates, showDownload) }));
};
