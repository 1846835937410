import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component, createRef, } from 'react';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import { throttle } from 'throttle-debounce';
import { sessionContext } from 'components/Authenticated';
import * as ProjectAPI from 'api/project-api';
import { fetchQueuedProjectsIds } from 'api/transcription-api';
import { MessageStream } from 'libs/media-processing/message-stream';
import MediaProcessingHandler from 'libs/media-processing/media-processing-handler';
import TranscriptionProgressHandler from 'libs/media-processing/transcription-progress-handler';
import MediaHandler from 'libs/media-processing/media-handler';
import ProjectErrorHandler from 'libs/media-processing/project-error-handler';
import * as clientEnv from 'libs/client-env';
import { txt } from 'libs/i18n';
import * as TeamAPI from 'api/team-api';
import MediaSource from 'libs/media-processing/media-source';
import { Mutex } from 'async-mutex';
import CircleButton from 'components/CircleButton';
import { SettingOutlined } from '@ant-design/icons';
import { BeeyLocale } from 'libs/locales/locale';
import { getAllAbbreviations } from 'api/expandable-abbreviations-api';
import { fetchHeadingsMetadata } from 'api/project-api';
import clsx from 'clsx';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import AlertPage from '../AlertPage';
import LoadingPage from '../LoadingPage';
import DashboardPage from '../../components/DashboardPage';
import EditorKeyboardControls from './EditorKeyboardControls';
import MediaControls from './MediaControls';
import EditorToolbar from './EditorToolbar';
import EditorController from './DocumentEditor/editor-controller';
import EditorContent from './EditorContent';
import EditorSettings from './EditorSettings';
import WithMediaPlayback from './WithMediaPlayback';
import Gamepad from './Gamepad';
import Playback, { PlaybackEvents } from './MediaPlayer/playback';
import SoundWave from './SoundWave';
import ProjectMenuPanel from './ProjectMenuPanel';
import { SubModeTutorial, SubModeTutorialContext, getSubModeTutorialSteps } from './subModeTutorial';
import { savingStateHandler } from './DocumentEditor/saving-state-handler';
import styles from './style.module.less';
import ProblemsDialog from './ProblemsDialog';
export default class EditorPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.editorController = null;
        this.secondaryEditorController = null;
        this.enqueueProjectUpdate = async (action) => {
            const release = await this.projectChangeMutex.acquire();
            try {
                // NOTE: We need to make sure that the project passed to action is up to date.
                // The component launching the action may have older version that got outdated
                // while awaiting mutex acquire.
                const { projectState } = this.state;
                if (projectState.phase === 'loaded') {
                    const updatedProject = await Promise.race([
                        action(projectState.project),
                        // NOTE: Sometimes the project update queue got stuck forever.
                        // This timeout prevents that - if we wait too long, the action will fail
                        // and the queue will get unstuck.
                        new Promise((resolve, reject) => {
                            setTimeout(() => { reject(new Error('timeout when updating project')); }, 40000);
                        }),
                    ]);
                    await this.refreshProject(updatedProject);
                }
                else {
                    global.logger.error('cannot do action on project', { project: projectState });
                }
                release();
            }
            catch (error) {
                release();
                global.logger.error('failed to update project', {}, error);
                throw error;
            }
        };
        this.savingStateListener = (newState) => {
            this.updateLeavingPrevented(newState !== 'saved');
        };
        this.initializeProjectMessageStream = (project, location) => {
            const { session } = this.context;
            const { connection } = session;
            const handleProjectUpdateMessage = async ({ data }) => {
                if (data.changed === 'KeywordsHighlight' || data.changed === 'Tags') {
                    return this.refreshProject();
                }
                return undefined;
            };
            this.messageStream = new MessageStream(connection, project.id);
            this.messageStream.addMessageListener('ProjectUpdates', 'Progress', handleProjectUpdateMessage);
            this.mediaProcessingHandler = new MediaProcessingHandler(this.messageStream);
            this.mediaProcessingHandler.addEventListener('status-update', this.handleUpdateMediaProcessingStatus);
            this.mediaProcessingHandler.projectReloaded(project);
            this.transcriptionProgressHandler = new TranscriptionProgressHandler(this.messageStream);
            this.transcriptionProgressHandler.addEventListener('status-update', throttle(2000, this.handleUpdateTranscriptionProgress));
            this.transcriptionProgressHandler.projectReloaded(project);
            this.mediaHandler = new MediaHandler(connection, this.messageStream);
            this.mediaHandler.addEventListener('status-update', this.handleUpdateMediaSource);
            void this.mediaHandler.projectReloaded(project);
            if (location.state !== undefined && !this.mediaHandler.getStatus().isAvailable()) {
                // NOTE: We have newly created local project
                this.mediaHandler.useLocalSource(project, location.state.mediaFile);
            }
            this.projectErrorHandler = new ProjectErrorHandler(this.messageStream, this.refreshProject);
            this.projectErrorHandler.addEventListener('status-update', this.handleServerProjectError);
            this.projectErrorHandler.projectReloaded(project);
            return {
                mediaSource: this.mediaHandler.getStatus(),
                mediaProcessingStatus: this.mediaProcessingHandler.getStatus(),
                transcriptionProgressStatus: this.transcriptionProgressHandler.getStatus(),
                projectErrorStatus: this.projectErrorHandler.getStatus(),
            };
        };
        this.handleUpdateMediaProcessingStatus = (status, oldStatus) => {
            if (status === 'transcribing') {
                this.setState({
                    mediaProcessingStatus: status,
                    projectQueuePosition: null,
                });
            }
            else {
                this.setState({ mediaProcessingStatus: status });
            }
            if (status === 'completed' && oldStatus === 'words-arriving') {
                this.handleUpdateTranscribedMinutes();
            }
        };
        this.handleUpdateTranscriptionProgress = (transcriptionProgressStatus) => this.setState({ transcriptionProgressStatus });
        this.handleUpdateMediaSource = (mediaSource) => {
            this.setState({ mediaSource });
        };
        this.handleServerProjectError = (serverProjectErrorStatus) => {
            const { projectErrorStatus } = this.state;
            if (projectErrorStatus === 'no-error') {
                this.setState({ projectErrorStatus: serverProjectErrorStatus });
            }
        };
        this.handleTranscriptionLoadFailed = () => {
            const { projectErrorStatus } = this.state;
            if (projectErrorStatus === 'no-error') {
                this.setState({
                    projectErrorStatus: {
                        category: 'Client',
                        reason: 'TrsxLoadFail',
                    },
                });
            }
        };
        this.displayMessage = (type, text, duration) => {
            const { message } = this.props;
            if (type === 'info') {
                void message.info(text, duration);
            }
            if (type === 'error') {
                void message.error(text, duration);
            }
            if (type === 'success') {
                void message.success(text, duration);
            }
            if (type === 'warning') {
                void message.warning(text, duration);
            }
        };
        this.handlePlaybackDurationLoaded = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const timestamp = urlParams.get('timestamp');
            if (timestamp !== null) {
                const timestampOfResult = Number(timestamp) / 1000; // time in millisecs
                this.playback.seekTo(Number(timestampOfResult));
            }
        };
        this.updateLeavingPrevented = (prevent) => {
            const { history } = this.props;
            if (this.leavingPrevented === prevent)
                return;
            const unblock = history.block((_, action) => {
                if (action === 'POP') {
                    return `${txt('confirmLeaving')} ${txt('unsavedChanges')}`;
                }
                return undefined;
            });
            if (!prevent) {
                unblock();
            }
            this.leavingPrevented = prevent;
            if (prevent) {
                window.addEventListener('beforeunload', this.beforeUnloadHandler);
            }
            else {
                window.removeEventListener('beforeunload', this.beforeUnloadHandler);
            }
        };
        this.beforeUnloadHandler = (e) => {
            e.preventDefault();
            // Chrome requires returnValue to be set
            // eslint-disable-next-line no-param-reassign
            e.returnValue = '';
        };
        this.handleUpdateTranscribedMinutes = () => {
            const { refetchLogin } = this.context;
            void refetchLogin();
        };
        this.handleCriticalEditorError = () => {
            // eslint-disable-next-line no-alert
            alert(txt('criticalEditorError'));
            this.leaveEditor();
        };
        this.leaveEditor = () => {
            const { history } = this.props;
            history.push('/projects');
        };
        this.refreshProject = async (newProject) => {
            const { projectState } = this.state;
            const { session } = this.context;
            if (newProject === undefined) {
                if (projectState.phase !== 'loaded') {
                    throw new Error(`unexpected project value: ${String(projectState)}`);
                }
                const serverProject = await ProjectAPI.fetchProject(session.connection, projectState.project.id);
                if (serverProject === 'not-found') {
                    this.setState({ projectState: { phase: 'not-found' } });
                    return;
                }
                await this.refreshProject(serverProject);
                return;
            }
            void this.mediaHandler.projectReloaded(newProject);
            // wait until the state is really set
            await new Promise((resolve) => {
                this.setState({ projectState: { phase: 'loaded', project: newProject } }, () => resolve(true));
            });
        };
        this.handleOpenSettings = () => {
            if (this.playback.playing) {
                this.playback.pause();
            }
            this.setState({ settingsOpened: true });
        };
        this.handleCloseSettings = () => {
            this.setState({ settingsOpened: false });
        };
        this.stopTranscription = async () => {
            const { projectState } = this.state;
            const { message } = this.props;
            const { session } = this.context;
            if (projectState.phase !== 'loaded') {
                throw new Error(`unexpected project value: ${String(projectState)}`);
            }
            this.setState({ projectState: { phase: 'deleting' } });
            // First we need to close the message stream to not
            // receive confusing failed messages during project deletion
            this.closeProjectMessageStream();
            const deleteResult = await ProjectAPI.deleteProject(session.connection, projectState.project.id);
            if (deleteResult.isSuccess()) {
                this.leaveEditor();
                void this.handleUpdateTranscribedMinutes();
                void message.success(txt('transcriptionStopped'));
            }
            else {
                void message.error(txt('transcriptionStopFailed'));
            }
        };
        this.setAndSaveWaveCanvasVisibility = (isVisible) => {
            this.setWaveCanvasVisibility(isVisible);
            clientEnv.setIsWaveVisible(isVisible);
        };
        this.setWaveCanvasVisibility = (isVisible) => {
            this.setState({ isWaveCanvasVisible: isVisible });
        };
        this.setWaveCanvas = (waveCanvas) => {
            this.setState({ waveCanvas });
        };
        this.state = {
            projectState: { phase: 'loading' },
            secondaryProjectState: { phase: 'loading' },
            projectFiles: null,
            settingsOpened: false,
            checklist: {
                problems: {
                    totalCount: 0,
                    listedCount: 0,
                    list: [],
                },
                modalOpened: false,
            },
            isCaptionMode: false,
            projectQueuePosition: null,
            isWaveCanvasVisible: clientEnv.getIsWaveVisible(),
            waveCanvas: null,
            mediaProcessingStatus: 'loading-project',
            transcriptionProgressStatus: 'not-in-progress',
            projectErrorStatus: 'no-error',
            mediaSource: MediaSource.NONE,
        };
        savingStateHandler.addEventListener(this.savingStateListener);
        this.playback = new Playback(0, 0);
        this.playback.oneTimeEventListener(PlaybackEvents.DurationUpdate, this.handlePlaybackDurationLoaded);
        this.caretTimeRef = createRef();
        // @ts-ignore
        this.caretTimeRef.current = 0;
        this.projectChangeMutex = new Mutex();
    }
    componentDidMount() {
        const { match: { params: { projId } } } = this.props;
        void this.loadProject(Number(projId));
    }
    componentDidUpdate() {
        var _a, _b;
        const { session } = this.context;
        const { projectState, isWaveCanvasVisible } = this.state;
        if (projectState.phase === 'loaded' && projectState.project.isReadOnly && isWaveCanvasVisible) {
            this.setWaveCanvasVisibility(false);
        }
        try {
            (_a = this.editorController) === null || _a === void 0 ? void 0 : _a.updateSettings(session.login.user.settings);
        }
        catch (error) {
            global.logger.error('failed to update settings in editor', {}, error);
        }
        if (projectState.phase === 'loaded') {
            (_b = this.editorController) === null || _b === void 0 ? void 0 : _b.updateMediaStartDatetime(projectState.project.start);
        }
    }
    componentWillUnmount() {
        var _a;
        const { projectState } = this.state;
        if (projectState.phase === 'loaded' || projectState.phase === 'deleting') {
            this.updateLeavingPrevented(false);
            this.closeProjectMessageStream();
            (_a = this.editorController) === null || _a === void 0 ? void 0 : _a.destroy();
            savingStateHandler.updateStatus('saved');
            savingStateHandler.removeEventListener(this.savingStateListener);
        }
    }
    render() {
        var _a, _b;
        const { projectErrorStatus, projectQueuePosition, mediaSource, projectState, projectFiles, secondaryProjectState, settingsOpened, checklist, mediaProcessingStatus, transcriptionProgressStatus, isCaptionMode, } = this.state;
        if (projectState.phase === 'loading') {
            return _jsx(LoadingPage, {});
        }
        if (projectState.phase === 'deleting') {
            return _jsx(LoadingPage, { message: txt('deletingProject') });
        }
        if (projectState.phase === 'no-last') {
            return (_jsx(AlertPage, { title: txt('noProj'), header: txt('noLastProj'), children: _jsxs("p", { children: [`${txt('noLastProjMsg')} `, _jsx(Link, { to: "/projects", children: txt('backToProjs') })] }) }));
        }
        if (projectState.phase === 'not-found') {
            return (_jsxs(AlertPage, { title: txt('notFound'), header: txt('projNotFound'), children: [_jsx("p", { children: txt('projNotFoundMsg') }), _jsx(Link, { to: "/projects", children: txt('backHome') })] }));
        }
        if (projectState.phase === 'load-error') {
            return (_jsxs(AlertPage, { title: txt('error'), header: txt('error'), children: [_jsx("p", { children: projectState.message }), _jsx(Link, { to: "/projects", children: txt('backHome') })] }));
        }
        if (this.editorController === null) {
            return _jsx(LoadingPage, {});
        }
        const { isWaveCanvasVisible, waveCanvas } = this.state;
        const isRightToLeft = (_b = (_a = this.editorController.getLanguage()) === null || _a === void 0 ? void 0 : _a.isRightToLeft()) !== null && _b !== void 0 ? _b : false;
        return (_jsx(DashboardPage, { title: `${projectState.project.name} | Beey`, toolbar: (_jsx(EditorToolbar, { project: projectState.project, onProjectUpdated: this.refreshProject, progressStatus: transcriptionProgressStatus, projectErrorStatus: projectErrorStatus, onStopTranscription: this.stopTranscription, enqueueProjectUpdate: this.enqueueProjectUpdate })), extraControls: (_jsxs(_Fragment, { children: [checklist.problems.totalCount !== 0 && (_jsx(CircleButton, { className: styles.buttonHighlight, onClick: () => this.setState({ checklist: Object.assign(Object.assign({}, checklist), { modalOpened: true }) }), content: checklist.problems.totalCount, tooltip: txt('settingsLabel'), tooltipPlacement: "bottomLeft" })), _jsx(CircleButton, { onClick: this.handleOpenSettings, content: _jsx(SettingOutlined, {}), tooltip: txt('settingsLabel'), tooltipPlacement: "bottomLeft" })] })), children: _jsxs(SubModeTutorial, { tutorialSteps: getSubModeTutorialSteps(), children: [_jsxs(WithMediaPlayback, { mediaSource: mediaSource, playback: this.playback, captions: this.editorController.captions, projectErrorStatus: projectErrorStatus, children: [_jsx(Gamepad, {}), _jsx(SubModeTutorialContext.Consumer, { children: ({ retrieveTutorialState }) => (this.editorController === null ? _jsx(Spin, {})
                                    : (_jsxs(EditorKeyboardControls, { className: styles.editor, waveCanvas: waveCanvas, replayCaption: this.editorController.captions.handleReplayCaption, children: [_jsx(MediaControls, { mediaStart: projectState.project.start, caretTimeRef: this.caretTimeRef, isWaveVisible: isWaveCanvasVisible || retrieveTutorialState() === 'running', onWaveVisibilityChange: this.setAndSaveWaveCanvasVisibility, captions: this.editorController.captions }), isCaptionMode ? (_jsx(SoundWave, { editorController: this.editorController, project: projectState.project, onWaveVisibilityChange: this.setWaveCanvasVisibility, isWaveVisible: isWaveCanvasVisible || retrieveTutorialState() === 'running', setWaveCanvas: this.setWaveCanvas })) : null, _jsxs("div", { className: clsx(styles.editorWithSider, { [styles.documentRtl]: isRightToLeft }), children: [_jsx(ProjectMenuPanel, { project: projectState.project, mediaProcessingStatus: mediaProcessingStatus, projectErrorStatus: projectErrorStatus, mediaSource: mediaSource, isCaptionMode: isCaptionMode, editorController: this.editorController, enqueueProjectUpdate: this.enqueueProjectUpdate, isWaveVisible: (isCaptionMode && isWaveCanvasVisible) || retrieveTutorialState() === 'running', onWaveVisibilityChange: this.setWaveCanvasVisibility }), _jsx(EditorContent, { mediaSource: mediaSource, project: projectState.project, 
                                                        // NOTE: secondaryProject is handled for backward compatibility
                                                        // and can be deleted later:
                                                        secondaryProject: secondaryProjectState.phase === 'loaded' ? Object.assign(Object.assign({}, secondaryProjectState.project), { isReadOnly: true }) : null, projectFiles: projectFiles, onProjectUpdated: this.refreshProject, editorController: this.editorController, secondaryEditorController: this.secondaryEditorController, mediaProcessingStatus: mediaProcessingStatus, messageStream: this.messageStream, projectErrorStatus: projectErrorStatus, projectQueuePosition: projectQueuePosition, onTranscriptionLoadFailed: this.handleTranscriptionLoadFailed, isCaptionMode: isCaptionMode, onLeaveEditorRequested: this.leaveEditor, enqueueProjectUpdate: this.enqueueProjectUpdate })] })] }))) })] }), _jsx(EditorSettings, { opened: settingsOpened, onClose: this.handleCloseSettings, editorController: this.editorController, project: projectState.project }), checklist.modalOpened && (_jsx(ProblemsDialog, { projectId: projectState.project.id, checklist: checklist.problems.list, onClose: () => this.setState({ checklist: Object.assign(Object.assign({}, checklist), { modalOpened: false }) }), onHighlightProblem: (time) => { var _a; return (_a = this.editorController) === null || _a === void 0 ? void 0 : _a.selectWrapline(time); } }))] }) }));
    }
    closeProjectMessageStream() {
        this.mediaProcessingHandler.destroy();
        this.transcriptionProgressHandler.destroy();
        this.mediaHandler.destroy();
        this.projectErrorHandler.destroy();
    }
    async fetchChecklist(projectId) {
        const { session } = this.context;
        return ProjectAPI.fetchChecklist(session.connection, projectId);
    }
    async fetchProjectQueuePosition(connection, project) {
        if (project.processingState !== 'InProgress') {
            return null;
        }
        const queuedIds = await fetchQueuedProjectsIds(connection);
        const index = queuedIds.findIndex((id) => id === project.id);
        return index === -1 ? null : index + 1;
    }
    async loadProject(projectId) {
        const { history, location, message } = this.props;
        const { checklist } = this.state;
        const { session, updateUserSettings } = this.context;
        const { connection, login: { user } } = session;
        const userSettings = user.settings;
        let openId = projectId;
        if (openId === undefined || Number.isNaN(openId)) {
            if (userSettings.lastProjId === null) {
                this.setState({ projectState: { phase: 'no-last' } });
                return;
            }
            openId = Number(userSettings.lastProjId);
            history.replace(`/edit/${openId}`);
        }
        try {
            if (openId !== Number(userSettings.lastProjId)) {
                updateUserSettings({ lastProjId: String(openId) });
            }
            const project = await ProjectAPI.fetchProject(connection, openId);
            if (project === 'not-found') {
                this.setState({ projectState: { phase: 'not-found' } });
                return;
            }
            const projectQueuePosition = await this.fetchProjectQueuePosition(connection, project);
            const projectChecklist = await this.fetchChecklist(project.id);
            const headingsMetadata = await fetchHeadingsMetadata(connection, project);
            const team = await TeamAPI.fetchCurrentTeam(connection);
            const projectLanguage = await ProjectAPI.fetchProjectLanguage(connection, project);
            const secondaryProjectIdMetadata = await ProjectAPI.fetchSecondaryProjectId(connection, project);
            const secondaryProjectId = secondaryProjectIdMetadata !== null && secondaryProjectIdMetadata !== void 0 ? secondaryProjectIdMetadata : (new URLSearchParams(window.location.search)).get('secondaryProjectId');
            const projectFiles = await ProjectAPI.fetchProjectFiles(connection, project.id);
            this.setState({ projectFiles });
            const { sectionTagsAllowed } = team.settings;
            this.editorController = new EditorController(session, this.caretTimeRef, this.playback, (value) => { this.setState({ isCaptionMode: value }); }, this.handleCriticalEditorError, this.displayMessage, sectionTagsAllowed, project.isReadOnly, headingsMetadata);
            this.editorController.setLanguage(projectLanguage !== null && projectLanguage !== void 0 ? projectLanguage : BeeyLocale.const('en-US'));
            this.editorController.summary.setEnabled(team.settings.summariesAllowed);
            if (session.login.hasClaim('editor:abbreviations') === true) {
                const result = await getAllAbbreviations(session.connection);
                if (result.isSuccess()) {
                    this.editorController.expandableAbbreviations.setEnabled(true);
                    this.editorController.expandableAbbreviations.setAbbreviationsList(result.get());
                }
                else {
                    void message.error(txt('failedToFetchAbbreviations'));
                }
            }
            const handlersState = this.initializeProjectMessageStream(project, location);
            this.setState(Object.assign({ projectState: { phase: 'loaded', project }, projectQueuePosition, checklist: Object.assign(Object.assign({}, checklist), { problems: projectChecklist }) }, handlersState));
            // NOTE: secondaryProject is handled for backward compatibility
            // and can be deleted later:
            if (secondaryProjectId !== null) {
                const secondaryProject = await ProjectAPI.fetchProject(session.connection, Number(secondaryProjectId));
                if (secondaryProject === 'not-found') {
                    this.setState({ secondaryProjectState: { phase: 'not-found' } });
                    global.logger.error('failed to load secondary project.');
                    return;
                }
                this.setState({
                    secondaryProjectState: { phase: 'loaded', project: secondaryProject },
                });
                this.secondaryEditorController = new EditorController(session, {}, this.playback, () => { }, () => { }, () => { }, sectionTagsAllowed, true, null);
            }
            // NOTE: End of obsolete code
            if (projectFiles.files.currentTrsx !== undefined
                && projectFiles.files.currentTrsx.length > 0
                && projectFiles.files.currentTrsx.some((trsx) => trsx.label !== null)) {
                this.secondaryEditorController = new EditorController(session, {}, this.playback, () => { }, () => { }, () => { }, sectionTagsAllowed, true, null);
            }
        }
        catch (error) {
            global.logger.error('Failed to open project', {}, error);
            if (error instanceof ApiError) {
                this.setState({ projectState: { phase: 'load-error', message: String(error) } });
            }
        }
    }
}
EditorPage.contextType = sessionContext;
