import { param, segment } from '@newtontechnologies/beey-api-js-client/endpoints';
export const BASE_SERVICE_API_URL = `${window.location.protocol}//${window.location.host}/services`;
export const BASE_TRANSCODE_API_URL = `${BASE_SERVICE_API_URL}/transcode/api`;
export const BASE_TRANSLATE_API_URL = `${BASE_SERVICE_API_URL}/translate/api`;
export const BASE_LINK_API_URL = `${BASE_SERVICE_API_URL}/link/api`;
export const BASE_SUMMARIZE_API_URL = `${BASE_SERVICE_API_URL}/summarize/api`;
export const BASE_AISERVANT_API_URL = `${BASE_SERVICE_API_URL}/aiservant/api`;
export const transcodeApi = segment(BASE_TRANSCODE_API_URL, {
    burnSubs: segment('burn-subs'),
    cut: segment('cut-length'),
    cutVideo: segment('cut-video'),
    cutAudio: segment('cut-audio'),
    upload: segment('upload'),
    download: segment('download'),
    job: segment('job', {
        id: param({
            groupStatus: segment('beey-project-group-status'),
            remove: segment('remove'),
            start: segment('start'),
            status: segment('status'),
        }),
    }),
});
export const translateApi = segment(`${BASE_TRANSLATE_API_URL}`, {
    translate: segment('Translate', {
        project: segment('Project'),
    }),
});
export const linkApi = segment(`${BASE_LINK_API_URL}`, {
    ytDl: segment('YtDl', {
        transcribe: segment('Transcribe'),
        urlInfo: segment('UrlInfo'),
    }),
});
export const summarizeApi = segment(`${BASE_SUMMARIZE_API_URL}`, {
    summarize: segment('summarize', {
        project: segment('project'),
    }),
});
export const aiServantApi = segment(`${BASE_AISERVANT_API_URL}`, {
    buildInformation: segment('BuildInformation'),
    summarize: segment('Summarize', {
        project: segment('Project'),
    }),
    translate: segment('Translate', {
        project: segment('Project'),
    }),
    prompts: segment('Prompts'),
});
