import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, } from 'react';
import Helmet from 'react-helmet';
import clsx from 'clsx';
import { App, Button, Steps, } from 'antd';
import IntroScreen from 'components/IntroScreen';
import { Redirect } from 'react-router-dom';
import { useAppContext } from 'App/app-context';
import { openByRegistering, openByAuthString } from 'api/session';
import { getCurrentUiLocale } from 'libs/locales/ui';
import Loading from 'components/Loading';
import { txt } from 'libs/i18n';
import * as UserAPI from 'api/user-api';
import SignUpFormEmail from './SignUpFormEmail';
import SurveyForm from './SurveyForm';
import styles from './style.module.less';
import SignUpFormGeneral from './SignUpFormGeneral';
const SignUpScreen = ({ location }) => {
    const { updateSession, clearSession, isLoginEnabled, triggerVersionMismatch, } = useAppContext();
    const { message } = App.useApp();
    const urlParams = new URLSearchParams(location.search);
    const authString = urlParams.get('auth');
    const upgradeToken = urlParams.get('upgrade');
    const newSubscription = urlParams.get('newSubscription');
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [upgrading, setUpgrading] = useState(upgradeToken !== null);
    const [currentStep, setCurrentStep] = useState(0);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [signUpData, setSignUpData] = useState({
        withEmail: false,
        loginDetails: {
            email: '',
            password: '',
            passwordConfirm: '',
        },
        surveyAnswers: {
            origin: '',
            field: '',
            dataConsent: true,
        },
    });
    const nextStep = (loginDetails) => {
        setSignUpData(Object.assign(Object.assign({}, signUpData), { loginDetails }));
        setCurrentStep(currentStep + 1);
    };
    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };
    const updateSurveyAnswers = (surveyAnswers) => {
        setSignUpData(Object.assign(Object.assign({}, signUpData), { surveyAnswers }));
    };
    useEffect(() => {
        if (upgradeToken === null || authString === null) {
            return;
        }
        void (async () => {
            setUpgrading(true);
            const upgradeResult = await UserAPI.upgradeCurrentUser(upgradeToken);
            if (upgradeResult.isFail()) {
                setRedirectUrl('/login');
                const error = upgradeResult.err();
                if (error === 'invalid-token') {
                    void message.info(txt('invalidUpgrade'), 7);
                    global.logger.error('Invalid upgrade token');
                }
                else {
                    void message.error(txt('upgradeFail'));
                    global.logger.error('User account upgrade failed', {}, error);
                }
                return;
            }
            const sessionResult = await openByAuthString(authString, clearSession, triggerVersionMismatch);
            if (sessionResult.isFail()) {
                setRedirectUrl('/login');
                void message.success(txt('needToLogIn'), 4);
            }
            else if (newSubscription !== null) {
                updateSession(sessionResult.get());
                setRedirectUrl('/account/credit?newSubscription');
            }
            else {
                updateSession(sessionResult.get());
                void message.success(txt('successUpgrade'), 4);
                setRedirectUrl('/projects');
            }
        })();
    }, []);
    if (upgrading) {
        return (_jsxs(_Fragment, { children: [_jsx(Helmet, { title: txt('helmetSignUp') }), _jsx(Loading, { size: "lg", message: txt('upgradingAccount') }), redirectUrl !== null ? _jsx(Redirect, { push: true, to: redirectUrl }) : null] }));
    }
    const handleSubmit = async () => {
        if (isAlertVisible) {
            setIsAlertVisible(false);
        }
        const { email, password } = signUpData.loginDetails;
        const { origin, field } = signUpData.surveyAnswers;
        const result = await openByRegistering(email, password, getCurrentUiLocale(), origin, field, clearSession, triggerVersionMismatch);
        if (result.isSuccess()) {
            updateSession(result.get());
            setRedirectUrl('/projects');
        }
        else if (result.err() === 'duplicate-email') {
            setCurrentStep(0);
            setIsAlertVisible(true);
        }
        else if (result.err() === 'invalid-email') {
            void message.error(txt('invalidEmail'), 5);
        }
        else {
            void message.error(txt('signupFail'), 5);
        }
    };
    const steps = [
        {
            key: '0',
            title: txt('loginDetails'),
            content: (_jsx(SignUpFormEmail, { onContinue: nextStep, signUpValues: signUpData.loginDetails, isAlertVisible: isAlertVisible, isLoginEnabled: isLoginEnabled, onBackToSignUp: () => setSignUpData(Object.assign(Object.assign({}, signUpData), { withEmail: false })) })),
        },
        {
            key: '1',
            title: txt('additionalDetails'),
            content: (_jsx(SurveyForm, { onSubmit: handleSubmit, surveyAnswers: signUpData.surveyAnswers, onChange: updateSurveyAnswers })),
        },
    ];
    return (_jsxs(IntroScreen, { redirectURL: redirectUrl, children: [_jsx(Helmet, { title: txt('helmetSignUp') }), !signUpData.withEmail ? (_jsxs(_Fragment, { children: [_jsx(SignUpFormGeneral, { isAlertVisible: isAlertVisible, onContinueWithEmail: (email) => setSignUpData(Object.assign(Object.assign({}, signUpData), { withEmail: true, loginDetails: Object.assign(Object.assign({}, signUpData.loginDetails), { email }) })), initialEmail: signUpData.loginDetails.email }), _jsxs("div", { className: styles.signUpFooter, children: [_jsx("span", { children: txt('alreadyHaveAccount') }), _jsx(Button, { className: clsx(styles.signUpButton, 'ant-btn-link'), type: "link", onClick: () => setRedirectUrl('/login'), children: txt('login') })] })] })) : (_jsxs(_Fragment, { children: [_jsx(Steps, { size: "small", className: styles.signUpSteps, items: steps, current: currentStep }), _jsx("div", { children: steps[currentStep].content }), _jsxs("div", { className: styles.signUpFooter, children: [currentStep === 0 ? (_jsx("span", { children: txt('alreadyHaveAccount') })) : null, _jsx(Button, { className: clsx(styles.signUpButton, 'ant-btn-link'), type: "link", onClick: currentStep === 0 ? () => setRedirectUrl('/login') : prevStep, children: currentStep === 0 ? txt('login') : txt('backLabel') })] })] }))] }));
};
export default SignUpScreen;
