import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { json } from '@newtontechnologies/beey-api-js-client/receivers';
import { nanoid } from 'nanoid';
import { INTERPUNCTIONS_REGEX_G } from 'screens/DashboardScreen/pages/EditorPage/DocumentEditor/text-utils';
const userlexToApiFormat = (userlex) => (userlex.map((entry) => ({
    text: entry.correctTranscription,
    incorrectTranscription: entry.incorrectTranscription,
})));
const userlexFromApiFormat = (apiUserlex) => apiUserlex.map((entry) => ({
    key: nanoid(6),
    correctTranscription: entry.text,
    incorrectTranscription: entry.incorrectTranscription,
}));
export const fetchCurrentUserlex = async (connection, locale) => connection.authFetch()
    .url(apiV2.currentTeam.lexicon.url({ language: locale.code }))
    .receive(json().map(userlexFromApiFormat)
    .catchHttpError((response) => (response.status === 422 ? { error: 'not-found' } : 'unknown'))
    .finally(() => 'unexpected'))
    .fetchStrict();
export const putCurrentUserlex = async (connection, locale, userlex) => connection.authFetch()
    .url(apiV2.currentTeam.lexicon.url({ language: locale.code }))
    .postJson(userlexToApiFormat(userlex))
    .receive(json().map(userlexFromApiFormat)
    .finally(() => 'unexpected'))
    .fetchStrict();
export const normalizeIncorrectTranscription = (value) => {
    return value.trim().toLowerCase().replaceAll(INTERPUNCTIONS_REGEX_G, '');
};
