import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { App, Button, Form, Popconfirm, Spin, } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { localeTxt, txt } from 'libs/i18n';
import SingleFileUpload from 'components/SingleFileUpload';
import { deleteSpeakerIdModel, fetchSpeakerIdModels, uploadSpeakerIdModel, } from 'api/admin/teams-api';
import { useSession } from 'components/Authenticated';
import IconButton from 'components/IconButton';
import { fetchCurrentUserTranscriptionOptions } from 'api/user-api';
import LanguageSelect from 'components/LanguageSelect';
import styles from '../../style.module.less';
import voicePrintStyles from './style.module.less';
const VoiceprintsCard = ({ teamId }) => {
    const [form] = Form.useForm();
    const { session: { connection } } = useSession();
    const { message } = App.useApp();
    const [languages, setLanguages] = useState([]);
    const [modelsList, setModelsList] = useState('loading');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const updateLanguages = async () => {
            const options = await fetchCurrentUserTranscriptionOptions(connection);
            setLanguages(options.map((option) => option.locale));
        };
        void updateLanguages();
    }, []);
    const updateList = async () => {
        setModelsList('loading');
        setModelsList(await fetchSpeakerIdModels(connection, teamId));
    };
    useEffect(() => {
        void updateList();
    }, [teamId]);
    const handleSubmit = async (values) => {
        setIsLoading(true);
        const result = await uploadSpeakerIdModel(connection, teamId, values.model, values.language);
        if (result.isSuccess()) {
            await updateList();
            void message.success(txt('uploadFinishedMsg'));
        }
        else {
            void message.error(txt('unexpectedError'));
        }
        setIsLoading(false);
    };
    const deleteModel = async (language) => {
        const result = await deleteSpeakerIdModel(connection, teamId, language);
        if (result.isSuccess()) {
            await updateList();
            void message.success(txt('done'));
        }
        else {
            void message.error(txt('unexpectedError'));
        }
    };
    const renderModelInfo = (model) => {
        return (_jsxs(_Fragment, { children: [_jsx("span", { className: voicePrintStyles.modelName, children: localeTxt(model.language) }), _jsx(Popconfirm, { title: txt('deleteModel'), okType: "primary", okButtonProps: { danger: true }, onConfirm: () => deleteModel(model.language), okText: txt('yesDelete'), cancelText: txt('cancel'), children: _jsx(IconButton, { danger: true, children: _jsx(DeleteOutlined, {}) }) }), _jsx("ul", { children: Object.entries(model).map(([key, value]) => (key !== 'language' && (_jsx("li", { children: `${key}: ${String(value)}` })))) })] }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.installedModels, children: [_jsxs("div", { className: voicePrintStyles.installedModels, children: [txt('installedModels'), ":"] }), modelsList === 'loading' ? (_jsx(Spin, {})) : (modelsList.map(renderModelInfo))] }), _jsxs(_Fragment, { children: [_jsx("span", { children: txt('addOrUpdateModel') }), _jsxs(Form, { onFinish: handleSubmit, labelCol: { sm: { span: 9 } }, labelWrap: true, form: form, labelAlign: "left", className: styles.form, children: [_jsx(Form.Item, { name: "model", valuePropName: "uploadFile", children: _jsx(SingleFileUpload, { label: txt('speakerIdModel'), accept: ".json" }) }), _jsx(Form.Item, { name: "language", label: txt('langOfTranscription'), rules: [
                                    {
                                        required: true,
                                        message: txt('languageRequired'),
                                    },
                                ], children: _jsx(LanguageSelect, { locales: languages }) }), _jsx("div", { className: voicePrintStyles.saveButton, children: _jsxs(Button, { htmlType: "submit", type: "primary", disabled: isLoading, children: [isLoading ? _jsx(LoadingOutlined, {}) : null, txt('save')] }, "submit") })] })] })] }));
};
export default VoiceprintsCard;
