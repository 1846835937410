import { jsx as _jsx } from "react/jsx-runtime";
import 'libs/init-globals';
import 'libs/dayjs-locale';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createRoot } from 'react-dom/client';
import 'antd/dist/reset.css';
import { BrowserRouter, Route } from 'react-router-dom';
import BeeyApp from 'App';
import 'resources/editor-font.css';
import 'resources/open-sans-font.css';
import 'mutation-events';
import './style.less';
// NOTE: For purpose of debugging, you can enable whyDidYouRender
// const whyDidYouRender = require(
//  '@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min'
// );
// whyDidYouRender(React);
const queryClient = new QueryClient();
const Root = () => {
    return (_jsx(QueryClientProvider, { client: queryClient, children: _jsx(BeeyApp, {}) }));
};
createRoot(document.getElementById('app')).render(_jsx(BrowserRouter, { children: _jsx(Route, { path: "/", component: Root }) }));
