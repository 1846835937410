import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef, } from 'react';
import Draggable from 'react-draggable';
import { getDialogPosition } from 'libs/dialog-position';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { DeleteOutlined, TeamOutlined } from '@ant-design/icons';
import { fetchSpeaker } from 'api/speaker-api';
import { Speaker } from 'api/speaker';
import { Tabs, Tooltip, Button, App, Space, } from 'antd';
import clsx from 'clsx';
import { useSession } from 'components/Authenticated';
import * as clientEnv from 'libs/client-env';
import { txt } from 'libs/i18n';
import IconButton from 'components/IconButton';
import { parseSpeaker } from 'libs/parse-speakers';
import FormModal from 'components/FormModal';
import { SparkleIcon } from 'components/CustomIcons';
import SpeakerSelectForm from './SpeakerSelectForm';
import styles from './style.module.less';
import SpeakerIdTab from './SpeakerIdTab';
const SpeakerSelectDialog = ({ speakerSuggestionWebSocket, diarizationCode = null, editorController, onSpeakerSelected, onClose, replacedSpeaker, transcriptionLanguage, isCaptionMode, defaultSpeakerColor, replaceEverywhere, speakerNode, projectId, }) => {
    var _a, _b, _c, _d;
    const { session } = useSession();
    const { message } = App.useApp();
    const [formVisible, setFormVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [newSpeaker, setNewSpeaker] = useState({ firstName: '', lastName: '', role: '' });
    const [selectOptions, setSelectOptions] = useState([]);
    const [isSpeakerInDb, setIsSpeakerInDb] = useState(false);
    const [bounds, setBounds] = useState({
        left: 0, top: 0, bottom: 0, right: 0,
    });
    const [disabled, setDisabled] = useState(true);
    const draggleRef = useRef(null);
    const asyncRef = useRef(null);
    const speakerSelectPosChanged = useRef(false);
    const speakerOptions = useRef([]);
    const resolveSuggestionQueue = useRef([]);
    const isLostConnectionHandled = useRef(false);
    const speakerSuggestionWS = speakerSuggestionWebSocket;
    const xmlParser = new DOMParser();
    const isRightToLeft = (_a = transcriptionLanguage === null || transcriptionLanguage === void 0 ? void 0 : transcriptionLanguage.isRightToLeft()) !== null && _a !== void 0 ? _a : false;
    const direction = isRightToLeft ? 'rtl' : 'ltr';
    const handleSuggestionMessage = ({ data }) => {
        const resolveSuggestion = resolveSuggestionQueue.current.shift();
        if (resolveSuggestion !== undefined) {
            resolveSuggestion(data);
        }
    };
    speakerSuggestionWS === null || speakerSuggestionWS === void 0 ? void 0 : speakerSuggestionWS.setOnMessage(handleSuggestionMessage);
    const parseSpeakersFromList = (speakers) => {
        const ret = [];
        for (let i = 0; i < speakers.length; i += 1) {
            const document = xmlParser.parseFromString(speakers[i], 'application/xml');
            const speakerElement = document.getElementsByTagName('s')[0];
            const parsedSpeaker = Speaker.fromXMLElement(speakerElement);
            const { dbid, language, firstName, lastName, } = parsedSpeaker;
            const attributes = speakerElement.getElementsByTagName('a');
            let isRole = false;
            if (dbid === null) {
                global.logger.error('Invalid speaker. Dbid is required', {
                    speakerTrsx: speakers[i],
                });
                continue;
            }
            for (let j = 0; j < attributes.length; j += 1) {
                const a = attributes[j];
                let role = '';
                if (a.getAttribute('name') !== 'role') {
                    continue;
                }
                role = a.textContent === null ? '' : a.textContent;
                isRole = true;
                ret.push(new Speaker(firstName, lastName, role, `${dbid}-${j})`, dbid, diarizationCode, false, language, parsedSpeaker.unmaintainedMetadata));
            }
            if (!isRole) {
                ret.push(new Speaker(firstName, lastName, null, dbid, dbid, diarizationCode, false, language, parsedSpeaker.unmaintainedMetadata));
            }
        }
        return ret;
    };
    const parseSpeakersWs = (responseMessage) => {
        var _a;
        const speakers = (_a = JSON.parse(responseMessage)) !== null && _a !== void 0 ? _a : [];
        return parseSpeakersFromList(speakers);
    };
    const getSpeakerByOptionValue = (value) => {
        if (value === null)
            return null;
        const documentSpeaker = editorController.speakers.documentSpeakers.find((speaker) => speaker.id === value || speaker.dbid === value);
        if (documentSpeaker !== undefined) {
            return documentSpeaker;
        }
        const speakerFromDbOptions = speakerOptions.current.find((speaker) => speaker.id === value);
        if (speakerFromDbOptions !== undefined) {
            return speakerFromDbOptions;
        }
        return null;
    };
    const getSuggestions = async (prefix) => {
        try {
            speakerSuggestionWS === null || speakerSuggestionWS === void 0 ? void 0 : speakerSuggestionWS.send(prefix);
        }
        catch (_a) {
            // NOTE: return dummy response as fallback.
            return '[]';
        }
        const responseMessage = await new Promise((resolve) => {
            resolveSuggestionQueue.current.push(resolve);
        });
        return responseMessage;
    };
    const saveSpeakerModalPos = () => {
        if (draggleRef.current === null) {
            return;
        }
        const speakerSelectRect = draggleRef.current.getBoundingClientRect();
        const speakerSelectPos = {
            widthFraction: speakerSelectRect.x / window.innerWidth,
            heightFraction: speakerSelectRect.y / window.innerHeight,
        };
        clientEnv.setSpeakerSelectPos(speakerSelectPos);
    };
    const handleClose = () => {
        setSelectOptions([]);
        if (speakerSelectPosChanged.current) {
            saveSpeakerModalPos();
        }
        onClose();
    };
    const getUniqueOptions = (dbSpeakers, docSpeakers) => {
        const labelMap = new Map();
        const addOption = (speaker, savedInDb) => {
            const label = speaker.composeLabel();
            // NOTE: Empty labels marks a dummy speaker. Do not include in the list.
            if (label === '')
                return;
            const option = {
                value: String(speaker.id),
                label,
                savedInDb,
            };
            if (!labelMap.has(label)) {
                labelMap.set(label, option);
            }
        };
        // NOTE: This ensures that last used speaker is on top.
        [...docSpeakers].reverse().forEach((speaker) => {
            addOption(speaker, false);
        });
        dbSpeakers.forEach((dbSpeaker) => {
            addOption(dbSpeaker, true);
            if (getSpeakerByOptionValue(dbSpeaker.id) === null) {
                speakerOptions.current.push(dbSpeaker);
            }
        });
        return Array.from(labelMap.values())
            .sort((a, b) => (a.savedInDb === b.savedInDb ? 0 : (a.savedInDb ? 1 : -1)));
    };
    const promiseOptions = async (inputVal) => {
        if (inputVal === undefined || inputVal === '')
            return [];
        try {
            const filteredDocumentSpeakers = editorController.speakers.documentSpeakers.filter((speaker) => {
                // NOTE: with empty search field, hide unknown speakers.
                if (inputVal === '' && speaker.isUnknown)
                    return false;
                return speaker.matches(inputVal);
            });
            const responseMessage = await Promise.race([
                getSuggestions(inputVal),
                new Promise((resolve, reject) => {
                    setTimeout(() => { reject(new Error('timeout')); }, 10000);
                }),
            ]);
            const dbSpeakers = parseSpeakersWs(responseMessage);
            // NOTE: If speaker is both in the database and document, show only document speaker.
            const options = getUniqueOptions(dbSpeakers, filteredDocumentSpeakers);
            setSelectOptions(options);
            return options;
        }
        catch (error) {
            if (error instanceof Error && error.message === 'timeout') {
                // when multiple requests from the same modal time out, handle only the first
                if (isLostConnectionHandled.current)
                    return [];
                isLostConnectionHandled.current = true;
                void message.warning(txt('lostConnection'));
                global.logger.error('reviving speaker suggestion websocket');
                speakerSuggestionWS === null || speakerSuggestionWS === void 0 ? void 0 : speakerSuggestionWS.revive();
                // recovering from lost connection during typing would be a nigtmare,
                // partly because of the way antd AsyncCreatableSelect caches suggestions.
                // Evade the problem by forcing the user to open it again,
                // this time with connected websocket.
                handleClose();
            }
            global.logger.error('failed to load speaker suggestion', {}, error);
            return [];
        }
    };
    const fetchSpeakerFromDb = async (speakerDbid) => {
        if (speakerDbid === null) {
            setIsSpeakerInDb(false);
            return;
        }
        const result = await fetchSpeaker(session.connection, speakerDbid);
        if (result.isSuccess()) {
            setIsSpeakerInDb(true);
        }
        else {
            setIsSpeakerInDb(false);
        }
    };
    useEffect(() => {
        if (replacedSpeaker !== null) {
            void fetchSpeakerFromDb(replacedSpeaker.dbid);
        }
    }, []);
    const getSpeakerDialogPos = () => {
        var _a;
        const defaultFractionalPos = {
            widthFraction: 0.4,
            heightFraction: 0.5,
        };
        const userFractionalPos = clientEnv.getSpeakerSelectPos(defaultFractionalPos);
        const targetRect = (_a = draggleRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (targetRect !== undefined) {
            return getDialogPosition({
                width: targetRect.width,
                height: targetRect.height,
            }, defaultFractionalPos, userFractionalPos);
        }
        return getDialogPosition({ width: 360, height: 80 }, defaultFractionalPos, userFractionalPos);
    };
    const handleSpeakerSelected = (speaker, replacingEverywhere) => {
        saveSpeakerModalPos();
        onSpeakerSelected(speaker, replacingEverywhere);
        onClose();
    };
    const onNewSpeakerCreated = (speakerName) => {
        setFormVisible(true);
        if (speakerName !== null) {
            setNewSpeaker(parseSpeaker(speakerName));
        }
    };
    const handleOptionSelected = (speakerOption, replacingEverywhere) => {
        var _a;
        // NOTE: SpeakerValue is id for document speakers, dbid for db speakers
        // and target value for new speakers.
        const speakerValue = (_a = speakerOption === null || speakerOption === void 0 ? void 0 : speakerOption.value) !== null && _a !== void 0 ? _a : null;
        const existingSpeaker = getSpeakerByOptionValue(speakerValue);
        if (existingSpeaker) {
            handleSpeakerSelected(existingSpeaker, replacingEverywhere);
        }
        else {
            onNewSpeakerCreated(speakerValue);
        }
    };
    const handleStopDrag = () => {
        speakerSelectPosChanged.current = true;
    };
    const handleInputChange = (inputVal, action) => {
        if (action.action !== 'input-blur' && action.action !== 'menu-close') {
            setInputValue(inputVal);
        }
    };
    const formatCreateLabel = (inputVal) => (_jsx("span", { children: `${txt('createSpeaker')} '${inputVal}'` }));
    const deleteSpeaker = (e, speaker) => {
        e.stopPropagation();
        editorController.speakers.deleteSpeakerFromDatabase(speaker, false);
    };
    const formatOptionLabel = (option) => {
        const speakerValue = option.value;
        const speaker = getSpeakerByOptionValue(speakerValue);
        return (_jsxs("div", { className: styles.optionLabel, children: [_jsx("div", { children: option.label }), speaker && option.savedInDb && (_jsx(IconButton, { danger: true, onClick: (e) => deleteSpeaker(e, speaker), tooltip: txt('deleteFromDb'), tooltipPlacement: "topRight", children: _jsx(DeleteOutlined, {}) }))] }));
    };
    const handleFormClose = () => {
        setFormVisible(false);
    };
    const handleSaveToDb = async (speaker) => {
        onSpeakerSelected(speaker, true);
        await fetchSpeakerFromDb(speaker.dbid);
    };
    const onStart = (_event, uiData) => {
        var _a;
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = (_a = draggleRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
        saveSpeakerModalPos();
    };
    const removeSpeaker = () => {
        if (speakerNode !== null) {
            editorController.handleRemoveSpeaker(speakerNode);
        }
        handleClose();
    };
    const generateReplaceTabs = (_, i) => {
        const id = i === 0 ? txt('replace') : txt('replaceAll');
        return ({
            label: id,
            key: id,
            children: (_jsxs(_Fragment, { children: [id === txt('replaceAll') ? _jsx("div", { className: styles.replaceInfo, children: txt('replaceAllInfo') }) : null, _jsxs("div", { className: clsx(styles.modalContent, { [styles.form]: formVisible }), children: [replacedSpeaker !== null ? (_jsx("span", { className: clsx(styles.label, { [styles.formInfo]: formVisible }), children: id === txt('replaceAll') ? txt('replaceAllWithSpeaker') : txt('replaceWithSpeaker') })) : null, formVisible
                                ? (_jsx(SpeakerSelectForm, { initialValues: newSpeaker, onSubmit: (speaker) => handleSpeakerSelected(speaker, id === txt('replaceAll')), diarizationCode: diarizationCode, language: transcriptionLanguage, isCaptionMode: isCaptionMode, defaultSpeakerColor: defaultSpeakerColor, onClose: handleFormClose, isDatabaseSpeaker: false, direction: direction })) : (_jsx(AsyncCreatableSelect, { inputValue: inputValue, className: clsx([styles.selectInput, direction]), classNamePrefix: "react-select", formatCreateLabel: formatCreateLabel, formatOptionLabel: formatOptionLabel, onInputChange: handleInputChange, defaultOptions: selectOptions, onChange: (newValue) => handleOptionSelected(newValue, id === txt('replaceAll')), ref: (ref) => { asyncRef.current = ref; }, cacheOptions: false, autoFocus: true, loadOptions: promiseOptions, onMenuOpen: () => promiseOptions(inputValue), placeholder: txt('selectSpeaker'), noOptionsMessage: () => txt('selectSpeaker') }))] })] })),
        });
    };
    const editTab = {
        label: txt('editLabel'),
        key: txt('editLabel'),
        children: (_jsx(SpeakerSelectForm, { editedSpeaker: replacedSpeaker, initialValues: { firstName: (_b = replacedSpeaker === null || replacedSpeaker === void 0 ? void 0 : replacedSpeaker.firstName) !== null && _b !== void 0 ? _b : '', lastName: (_c = replacedSpeaker === null || replacedSpeaker === void 0 ? void 0 : replacedSpeaker.lastName) !== null && _c !== void 0 ? _c : '', role: (_d = replacedSpeaker === null || replacedSpeaker === void 0 ? void 0 : replacedSpeaker.role) !== null && _d !== void 0 ? _d : '' }, onSubmit: (speaker) => handleSpeakerSelected(speaker, true), diarizationCode: diarizationCode, language: transcriptionLanguage, isCaptionMode: isCaptionMode, isDatabaseSpeaker: isSpeakerInDb, defaultSpeakerColor: defaultSpeakerColor, onClose: handleClose, direction: direction })),
    };
    const removeTab = {
        label: _jsx("div", { children: _jsx(DeleteOutlined, {}) }),
        key: 'deleteSpeaker',
        children: (_jsxs("div", { className: styles.removeSpeaker, children: [_jsx("div", { children: txt('removeSpeaker') }), _jsx(Button, { onClick: removeSpeaker, type: "primary", danger: true, children: txt('remove') })] })),
    };
    const showSpeakerId = replacedSpeaker !== null && speakerNode !== null && session.login.hasClaim('voiceSamples:edit');
    const speakerIdTab = {
        label: (_jsxs(Space, { size: "small", children: [_jsx(SparkleIcon, {}), txt('recognition')] })),
        key: 'speakerId',
        children: (replacedSpeaker !== null && speakerNode !== null && (_jsx(SpeakerIdTab, { speaker: replacedSpeaker, transcriptionLanguage: transcriptionLanguage, projectId: projectId, speakerNode: speakerNode, onSaveToDb: handleSaveToDb, editorController: editorController }))),
    };
    const tabsContent = [...['replace', 'replaceAll'].map(generateReplaceTabs), editTab, ...(showSpeakerId ? [speakerIdTab] : []), removeTab];
    const modalHeader = (_jsx("div", { onFocus: () => { }, onBlur: () => { }, onMouseOver: () => {
            if (disabled) {
                setDisabled(false);
            }
        }, onMouseOut: () => {
            setDisabled(true);
        }, className: clsx(styles.header, { [styles.headerAssignSpeaker]: !replacedSpeaker }), children: _jsxs("div", { className: clsx(styles.speakerName), children: [_jsx("div", { children: replacedSpeaker ? replacedSpeaker.composeLabel() : txt('assignSpeaker') }), isSpeakerInDb
                    ? (_jsx(Tooltip, { title: txt('speakerInDatabase'), children: _jsx(TeamOutlined, {}) })) : null] }) }));
    return (_jsx(FormModal, { title: modalHeader, open: true, 
        // NOTE: Modal default position must be overriden for the draggable default position to work.
        style: { top: 0, left: 0, position: 'absolute' }, footer: false, className: styles.dialog, onCancel: handleClose, 
        // eslint-disable-next-line react/no-unstable-nested-components
        modalRender: (modal) => (_jsx(Draggable, { disabled: disabled, bounds: bounds, nodeRef: draggleRef, onStart: (event, uiData) => onStart(event, uiData), defaultPosition: getSpeakerDialogPos(), onStop: handleStopDrag, children: _jsx("div", { ref: draggleRef, children: modal }) })), children: replacedSpeaker ? (_jsx(Tabs, { defaultActiveKey: replaceEverywhere ? txt('replaceAll') : txt('replace'), items: tabsContent })) : (tabsContent[0].children) }));
};
export default SpeakerSelectDialog;
