import { BeeyLocale } from './locale';
const translationLocaleCodes = [
    'ar-EG', // Arabic (Egypt)
    'bg-BG', // Bulgarian (Bulgaria)
    'bs-BA', // Bosnian (Bosnia and Herzegovina)
    'cs-CZ', // Czech (Czech Republic)
    'da-DK', // Danish (Denmark)
    'de-DE', // German (Germany)
    'en-GB', // English (Great Britain)
    'en-US', // English (United States)
    'es-ES', // Spanish (Spain)
    'fr-FR', // French (France)
    'fi-FI', // Finnish (Finland)
    'hr-HR', // Croatian (Croatia)
    'hu-HU', // Hungarian (Hungary)
    'it-IT', // Italian (Italy)
    'ja-JP', // Japanese (Japan)
    'mk-MK', // Macedonian (Macedonia)
    'nb-NO', // Norwegian (Norway)
    'nl-NL', // Dutch (Netherlands)
    'pl-PL', // Polish (Poland)
    'ro-RO', // Romanian (Romania)
    'sr-RS', // Serbian (Serbia)
    'ru-RU', // Russian (Russia)
    'sk-SK', // Slovak (Slovakia)
    'sv-SE', // Swedish (Sweden)
    'sl-SI', // Slovenian (Slovenia)
    'uk-UA', // Ukrainian (Ukraine)
];
const isTranslationLocaleCode = (code) => translationLocaleCodes.find((locale) => locale === code) !== undefined;
export class TranslationLocale extends BeeyLocale {
    static fromCode(code) {
        const locale = BeeyLocale.fromCode(code);
        if (locale === null) {
            throw new Error(`DEV ERROR: Invalid locale code: ${code} in translationLocaleCodes`);
        }
        return new TranslationLocale(locale.lang, locale.script, locale.country);
    }
    static fromLocale(locale) {
        if (!isTranslationLocaleCode(locale.code)) {
            return null;
        }
        return this.fromCode(locale.code);
    }
}
export const getTranslationLocales = () => (translationLocaleCodes.map(TranslationLocale.fromCode));
export const defaultTranslationLocale = TranslationLocale.fromCode('en-US');
