import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import * as clientEnv from 'libs/client-env';
import { useBeeyTutorial } from 'screens/DashboardScreen/beeyTutorial';
import { txt } from 'libs/i18n';
import TutorialModal from 'components/Tutorial/TutorialModal';
import { useHistory } from 'react-router';
import tutorialModalstyles from 'components/Tutorial/TutorialModal/style.module.less';
import tadaEmoji from 'components/Tutorial/TutorialModal/img/tada.png';
import { getRectFromElement } from 'libs/positions';
import clsx from 'clsx';
import { useSession } from 'components/Authenticated';
import { App } from 'antd';
import { useSubModeTutorial } from '../subModeTutorial';
import MediaPlayer from '../MediaPlayer';
import DocumentEditor from '../DocumentEditor';
import { usePlaybackContext } from '../WithMediaPlayback';
import styles from './style.module.less';
import { computeEditorLayout, computeMainLayout } from './editor-positions';
import useResizeObserver from '../MediaPlayer/use-resize-observer';
import DocumentToolbar from '../DocumentEditor/DocumentToolbar';
const TOOLBAR_MIN_HEIGHT = 15;
const TOOLBAR_MAX_HEIGHT = 35;
const EditorContent = ({ mediaSource, project, projectFiles, secondaryProject, onProjectUpdated, editorController, secondaryEditorController, mediaProcessingStatus, messageStream, projectErrorStatus = 'no-error', projectQueuePosition = null, onTranscriptionLoadFailed, isCaptionMode, onLeaveEditorRequested, enqueueProjectUpdate, }) => {
    var _a;
    const { playerState, playback } = usePlaybackContext();
    const editorPositionReference = useRef(null);
    const [editorWidth, setEditorWidth] = useState(clientEnv.getEditorWidth());
    const [editorBounds, setEditorBounds] = useState(null);
    const [isToolbarHidden, setIsToolbarHidden] = useState(clientEnv.getIsToolbarHidden());
    const beeyTutorialContext = useBeeyTutorial();
    const subModeTutorialContext = useSubModeTutorial();
    const history = useHistory();
    const { session } = useSession();
    const { notification } = App.useApp();
    const forceToolbarHidden = isToolbarHidden && subModeTutorialContext.retrieveTutorialState() !== 'running';
    useResizeObserver({
        callback: () => {
            if (editorPositionReference.current !== null) {
                setEditorBounds(getRectFromElement(editorPositionReference.current));
            }
        },
        elementRef: editorPositionReference,
    });
    const setupEditorMode = () => {
        var _a;
        const currentTrsxFiles = (_a = projectFiles === null || projectFiles === void 0 ? void 0 : projectFiles.files.currentTrsx) !== null && _a !== void 0 ? _a : [];
        // NOTE: Secondary project is checked for backward comaptibility and can be deleted later.
        return (secondaryProject !== null || currentTrsxFiles.length > 1)
            ? { layout: 'double', showSecondEditor: true }
            : { layout: 'single' };
    };
    const [editorMode, setEditorMode] = useState(setupEditorMode);
    const { resolution } = playback;
    let aspectRatio = resolution && resolution.width / resolution.height;
    if (aspectRatio === null && mediaSource.remoteHasVideo) {
        // FIXME @chocoman: this is a dirty workaround of a bug that
        // video was not showing in streams.
        aspectRatio = 16 / 9;
    }
    const { editor, toolbar } = computeMainLayout(editorBounds, forceToolbarHidden ? TOOLBAR_MIN_HEIGHT : TOOLBAR_MAX_HEIGHT);
    const positions = computeEditorLayout(editor, aspectRatio, editorWidth, playerState, editorMode.layout === 'double' && editorMode.showSecondEditor);
    const changeEditorWidth = (editorWidthSelected) => {
        setEditorWidth(editorWidthSelected);
    };
    const finishTutorial = () => {
        beeyTutorialContext.tutorialLastStep();
        history.push('/projects');
    };
    const handleToggleToolbar = () => {
        setIsToolbarHidden(!isToolbarHidden);
        clientEnv.setIsToolbarHidden(!isToolbarHidden);
    };
    const currentTrsxFiles = (_a = projectFiles === null || projectFiles === void 0 ? void 0 : projectFiles.files.currentTrsx) !== null && _a !== void 0 ? _a : [];
    const activeCurrentTrsx = currentTrsxFiles.find((trsx) => trsx.isActive);
    const nonActiveCurrentTrsx = currentTrsxFiles.find((trsx) => !trsx.isActive);
    const isSecondary = currentTrsxFiles.length > 1;
    const handleToggleSecondaryEditor = () => {
        if (editorMode.layout === 'double') {
            setEditorMode(Object.assign(Object.assign({}, editorMode), { showSecondEditor: !editorMode.showSecondEditor }));
        }
    };
    const isVideoMinimized = playerState.status === 'ready' && playerState.video !== 'no-video' && !playerState.video.isMaximized;
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: editorPositionReference, className: `${styles.positionReference} sub-mode-tutorial-step-split` }), _jsxs("div", { onDrop: (e) => {
                    if (e.dataTransfer.files.length !== 0) {
                        // prevent dragging files such as images to editor, but allow plain text
                        e.preventDefault();
                    }
                }, children: [_jsxs(TutorialModal, { skipTutorial: beeyTutorialContext.tutorialLastStep, tutorialAction: finishTutorial, tutorialActionLabel: txt('beeyTutorialFinishButton'), skipTutorialLabel: txt('beeyTutorialTranscribeLater'), visible: beeyTutorialContext.currentStep === 7, children: [_jsx("h2", { children: txt('beeyTutorialFinalHeading') }), _jsx("p", { children: txt('beeyTutorialFinalStep') }), _jsx("img", { src: tadaEmoji, className: tutorialModalstyles.image, alt: "tada emoji" })] }), _jsxs(TutorialModal, { className: styles.lastTutorialTooltip, skipTutorial: subModeTutorialContext.tutorialLastStep, tutorialAction: subModeTutorialContext.tutorialFinish, tutorialActionLabel: txt('submodeFinishButton'), skipTutorialLabel: txt('tutorialFinish'), visible: subModeTutorialContext.currentStep === 7, children: [_jsx("h2", { children: txt('beeyTutorialFinalHeading') }), _jsx("p", { className: tutorialModalstyles.text, children: txt('subModeTutorialFinalStep') }), _jsx("p", { className: "tutorial__tip", children: txt('subModeTutorialFinalTip') })] }), _jsx(MediaPlayer, { position: positions.video, className: clsx({ [styles.minVideo]: isVideoMinimized }), connection: session.connection, project: project, forceVideoCanvas: isCaptionMode, language: editorController.getLanguage() }), toolbar.display === 'block' && (_jsx(DocumentToolbar, { position: toolbar, onToggleToolbar: handleToggleToolbar, isToolbarHidden: forceToolbarHidden, editorController: editorController, isCaptionMode: isCaptionMode, project: project, onEditorWidthSelected: changeEditorWidth, editorMode: editorMode, onToggleSecondaryEditor: handleToggleSecondaryEditor })), editorMode.layout === 'double' && secondaryEditorController !== null && (_jsx(DocumentEditor, { position: positions.secondaryDocument, className: clsx(styles.secondaryEditor, styles.document, styles[editorWidth]), 
                        // NOTE : secondaryProject is handled for backward compatibility
                        // and will be deleted later, so no need to handle typecast :)
                        project: isSecondary ? project : secondaryProject, onProjectUpdated: async () => { }, editorController: secondaryEditorController, mediaProcessingStatus: "completed", messageStream: messageStream, projectErrorStatus: "no-error", projectQueuePosition: null, isPlayerReady: playerState.status === 'ready', onTranscriptionLoadFailed: () => { }, isCaptionMode: false, tutorialContinue: () => { }, tutorialGoNext: async () => { }, tutorialCurrentStep: 0, onLeaveEditorRequested: () => { }, session: session, enqueueProjectUpdate: async () => { }, onScroll: (position) => editorController.syncScroll(position), notification: notification, 
                        // NOTE: This is for backward comaptibility and can be deleted later:
                        isObsolete: secondaryProject !== null, currentTrsxFileId: nonActiveCurrentTrsx === null || nonActiveCurrentTrsx === void 0 ? void 0 : nonActiveCurrentTrsx.id, isSecondary: true })), _jsx(DocumentEditor, { position: positions.document, className: clsx(styles.document, styles[editorWidth]), project: project, onProjectUpdated: onProjectUpdated, editorController: editorController, mediaProcessingStatus: mediaProcessingStatus, messageStream: messageStream, projectErrorStatus: projectErrorStatus, projectQueuePosition: projectQueuePosition, isPlayerReady: playerState.status === 'ready', onTranscriptionLoadFailed: onTranscriptionLoadFailed, isCaptionMode: isCaptionMode, tutorialContinue: beeyTutorialContext.tutorialContinue, tutorialGoNext: beeyTutorialContext.tutorialGoNext, tutorialCurrentStep: beeyTutorialContext.currentStep, onLeaveEditorRequested: onLeaveEditorRequested, session: session, enqueueProjectUpdate: enqueueProjectUpdate, onScroll: (position) => (secondaryEditorController === null || secondaryEditorController === void 0 ? void 0 : secondaryEditorController.syncScroll(position)), notification: notification, 
                        // NOTE: This is for backward comaptibility and can be deleted later:
                        isObsolete: secondaryProject !== null, currentTrsxFileId: activeCurrentTrsx === null || activeCurrentTrsx === void 0 ? void 0 : activeCurrentTrsx.id, isSecondary: false })] })] }));
};
export default EditorContent;
