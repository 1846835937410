import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ColorPicker, Flex, Form, Input, Switch, } from 'antd';
import { txt } from 'libs/i18n';
import { fetchCurrentTeamTags } from 'api/team-api';
import { useSession } from 'components/Authenticated';
import { useEffect, useState } from 'react';
import { deriveTagColor } from 'libs/tag-style';
import styles from './style.module.less';
export const MAX_TAG_LENGTH = 64;
const TagFormItems = ({ editedTag, action }) => {
    const { session } = useSession();
    const [allTeamTags, setAllTeamTags] = useState([]);
    useEffect(() => {
        const fetchTags = async () => {
            const teamTags = await fetchCurrentTeamTags(session.connection);
            const tags = teamTags.list.map((tg) => {
                const { tag, abbreviation, tagRepresentativeId, color, } = tg;
                return ({
                    title: tag,
                    key: tag,
                    id: tagRepresentativeId,
                    abbreviation,
                    color: color !== null && color !== void 0 ? color : deriveTagColor(tag),
                    showFullVersion: true,
                });
            });
            setAllTeamTags(tags);
        };
        void fetchTags();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "title", label: txt('name'), rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: txt('noTagName'),
                    },
                    {
                        validator: async (_, value) => {
                            if (value.length > MAX_TAG_LENGTH) {
                                return Promise.reject(txt('max64chars'));
                            }
                            if ((editedTag === null || editedTag === void 0 ? void 0 : editedTag.title) === value) {
                                return Promise.resolve();
                            }
                            const duplicitTag = allTeamTags.find((tg) => tg.title.trim() === value.trim());
                            if (duplicitTag !== undefined) {
                                return Promise.reject(txt('duplicitTag'));
                            }
                            if (value.trim().startsWith('#')) {
                                return Promise.reject(txt('startsWithHash'));
                            }
                            return Promise.resolve();
                        },
                    },
                ], children: _jsx(Input, { autoFocus: true, disabled: action === 'edit' }) }), _jsx(Form.Item, { name: "abbreviation", label: txt('abbreviation'), rules: [
                    {
                        max: 2,
                        message: txt('max2chars'),
                    },
                    {
                        type: 'string',
                        whitespace: true,
                        message: txt('minOnechar'),
                    },
                    {
                        validator: async (_, value) => {
                            if (value === '') {
                                return Promise.reject(txt('minOnechar'));
                            }
                            return Promise.resolve();
                        },
                    },
                ], children: _jsx(Input, {}) }), _jsx(Form.Item, { name: "color", label: txt('tagColor'), getValueFromEvent: (clr) => {
                    return `#${clr.toHex()}`;
                }, children: _jsx(ColorPicker, {}) }), _jsxs(Flex, { gap: "middle", align: "baseline", children: [_jsx("span", { children: txt('showAsOption') }), _jsx(Form.Item, { name: "isPinned", className: styles.switch, children: _jsx(Switch, { size: "small", defaultChecked: true }) })] }), _jsx(Form.Item, { className: styles.formItemHidden, name: "key", children: _jsx(Input, { type: "hidden" }) })] }));
};
export default TagFormItems;
