import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { App, Button, Form, Input, } from 'antd';
import FormModal from 'components/FormModal';
import * as ProjectAPI from 'api/project-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const { TextArea } = Input;
const ProjectMetadataModal = ({ visible, onCancel, onMetadataChange, enqueueProjectUpdate, notes, project, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const projectNotes = Form.useWatch('notes', form);
    const author = Form.useWatch('author', form);
    const saveMetadata = async () => {
        await enqueueProjectUpdate(async (initialProject) => {
            const noteUpdateResult = await ProjectAPI.updateProjectNotes(session.connection, initialProject, projectNotes);
            if (noteUpdateResult.isSuccess()) {
                onCancel();
                return noteUpdateResult.get();
            }
            void message.error(txt('failedToSaveNotes'), 2);
            return initialProject;
        });
        // NOTE: The requests must be sent sequentially to avoid accessToken conflict.
        const result = await ProjectAPI.editProjectDescription(session.connection, project, { author });
        if (result.isSuccess()) {
            void message.success(txt('metadataSaved'));
            onMetadataChange({ author, notes: projectNotes });
        }
        else {
            void message.error(txt('unexpectedError'));
        }
    };
    return (_jsx(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], className: styles.projectNotesModal, title: txt('aboutProject'), open: visible, onCancel: onCancel, footer: [
            _jsx(Button, { onClick: onCancel, children: txt('close') }, "back"),
            _jsx(Button, { type: "primary", onClick: saveMetadata, children: txt('save') }, "submit"),
        ], children: _jsxs(Form, { form: form, labelCol: { span: 5 }, initialValues: {
                author: project.description.author,
                notes,
            }, children: [_jsx(Form.Item, { name: "author", label: txt('author'), children: _jsx(Input, {}) }), _jsx(Form.Item, { name: "notes", label: txt('notes'), children: _jsx(TextArea, { id: "textarea-project-notes", autoSize: { minRows: 6, maxRows: 15 }, autoFocus: true }) })] }) }));
};
export default ProjectMetadataModal;
