import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Input, Button, App, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { resetPasswordRequest, resetPassword, fetchPasswordSettings } from 'api/user-api';
import IntroScreen from 'components/IntroScreen';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const ResetPasswordScreen = ({ location }) => {
    const [redirectURL, setRedirectUrl] = useState(null);
    const [requiredLength, setRequiredLength] = useState(6);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const loginTo = '/projects';
    const url = window.location.href;
    const createPassword = url.includes('createpassword');
    useEffect(() => {
        const getRequiredPwdLength = async () => {
            const passwordSettings = await fetchPasswordSettings();
            setRequiredLength(passwordSettings.requiredLength);
        };
        void getRequiredPwdLength();
    }, []);
    const handleSubmit = async (values) => {
        const result = await resetPasswordRequest(values.email.trim());
        if (result.isSuccess()) {
            void message.success(txt('resetRequest'), 5);
        }
        else if (result.err() === 'invalid-email') {
            void message.error(txt('notExists'), 2);
        }
        else {
            void message.error(txt('unexpectedError'));
        }
        setIsLoading(false);
        form.resetFields(['email']);
    };
    const handleReset = async (password, resetToken) => {
        setIsLoading(true);
        const result = await resetPassword(password, resetToken);
        if (result.isSuccess()) {
            void message.success(txt('resetOk'), 5);
        }
        else if (result.err() === 'invalid-token') {
            void message.error(txt('resetTokenInvalid'), 5);
        }
        else {
            void message.error(txt('unexpectedError'));
        }
        setIsLoading(false);
        form.resetFields(['password', 'passwordConfirm']);
        setRedirectUrl(loginTo);
    };
    const setResetTokenFromUrl = (values) => {
        try {
            const urlParams = new URLSearchParams(location.search);
            const resetToken = urlParams.get('resetToken');
            void handleReset(values.password, resetToken);
        }
        catch (error) {
            // NOTE: Old Edge crashed completely because it lacks URLSearchParams.
            global.logger.error('Setting localization failed', {}, error);
        }
    };
    if (redirectURL !== null) {
        return _jsx(Redirect, { push: true, to: redirectURL });
    }
    const getSubmitButton = (label) => (_jsx("div", { className: styles.controls, children: _jsxs(Button, { className: styles.button, type: "primary", size: "large", htmlType: "submit", disabled: isLoading, children: [isLoading ? _jsx(LoadingOutlined, {}) : null, label] }) }));
    return (_jsx(IntroScreen, { isLogoDisplayed: false, children: _jsxs(_Fragment, { children: [_jsx("h1", { children: createPassword ? txt('createPsw') : txt('resetPsw') }), (location.search !== '')
                    ? (_jsxs(_Fragment, { children: [createPassword ? null : _jsx("p", { children: txt('pleaseNew') }), _jsxs(Form, { form: form, onFinish: setResetTokenFromUrl, children: [_jsx(Form.Item, { name: "password", className: styles.formInput, hasFeedback: true, rules: [
                                            {
                                                required: true,
                                                message: txt('yourPassword'),
                                            },
                                            {
                                                min: requiredLength,
                                                whitespace: true,
                                                message: `${txt('minChars')}${requiredLength}`,
                                            },
                                            {
                                                max: 128,
                                                message: txt('maxCharacters'),
                                            },
                                        ], children: _jsx(Input.Password, { type: "password", placeholder: txt('pw') }) }), _jsx(Form.Item, { name: "passwordConfirm", className: styles.formInput, dependencies: ['password'], hasFeedback: true, rules: [
                                            {
                                                required: true,
                                                message: txt('yourPassword'),
                                            },
                                            {
                                                min: requiredLength,
                                                whitespace: true,
                                                message: `${txt('minChars')}${requiredLength}`,
                                            },
                                            {
                                                max: 128,
                                                message: txt('maxCharacters'),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    // eslint-disable-next-line prefer-promise-reject-errors
                                                    return Promise.reject(txt('noMatch'));
                                                },
                                            }),
                                        ], children: _jsx(Input.Password, { type: "password", placeholder: txt('confirmPW') }) }), getSubmitButton(txt('confirmPW'))] })] }))
                    : (_jsxs(_Fragment, { children: [_jsx("p", { children: txt('resetPswText') }), _jsxs(Form, { form: form, initialValues: { email: '' }, onFinish: handleSubmit, children: [_jsx(Form.Item, { name: "email", className: styles.formInput, rules: [
                                            {
                                                required: true,
                                                transform: (value) => value.trim(),
                                                type: 'email',
                                                message: txt('pleaseEmail'),
                                            },
                                        ], children: _jsx(Input, { placeholder: txt('email'), autoFocus: true, size: "large" }) }), getSubmitButton(txt('resetPsw'))] })] })), _jsx("div", { className: styles.loginLink, children: _jsx(Button, { type: "link", onClick: () => setRedirectUrl('/login'), children: txt('login') }) })] }) }));
};
export default ResetPasswordScreen;
