import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Space } from 'antd';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import { txt } from 'libs/i18n';
import { BYTES_IN_GIGABYTE } from 'libs/utils';
import { useEffect, useState } from 'react';
import { fetchCurrentTeamMembers } from 'api/team-api';
import { useSession } from 'components/Authenticated';
import { ExpandedContentWithProjects } from '../TeamExtendedContent';
import styles from '../style.module.less';
const STORAGEINFO_TEAMOWNER_TABLE_ID = 'storageInfo-teamowner-table'; // Must be unique across all tables in Beey!!
const StorageInfoForTeamOwner = ({ memberProjects, onFetchMemberTranscriptionLogState, }) => {
    const { session } = useSession();
    const [expandedRowProperties, setExpandedRowProperties] = useState({
        key: [],
        email: '',
    });
    const [teamStorage, setTeamStorage] = useState('loading');
    const tableView = useTableView(STORAGEINFO_TEAMOWNER_TABLE_ID, {
        pageSize: 10,
    });
    useEffect(() => {
        const fetchMembersUsedStorage = async () => {
            setTeamStorage('loading');
            const members = await fetchCurrentTeamMembers(session.connection);
            setTeamStorage(members);
        };
        void fetchMembersUsedStorage();
    }, []);
    const handleRowExpanded = (expanded, member) => {
        setExpandedRowProperties({
            key: expanded ? [member.id] : [],
            email: expanded ? member.email : '',
        });
        void onFetchMemberTranscriptionLogState(member.id, []);
    };
    const totalStorageFooter = () => {
        if (teamStorage !== 'loading') {
            const totalStorage = teamStorage.list.reduce((acc, member) => acc + member.storageSharedBytes, 0) / BYTES_IN_GIGABYTE;
            return (_jsx("div", { className: styles.footer, children: _jsxs(Space, { children: [_jsx("span", { className: styles.total, children: txt('total') }), _jsxs("span", { children: [totalStorage.toFixed(2), ' ', "GB"] })] }) }));
        }
        return null;
    };
    const columnsStorage = [
        {
            title: txt('teamMembers'),
            key: 'Email',
            render: (member) => member.email,
        },
        {
            title: txt('storageStatistics'),
            dataIndex: 'storageSharedBytes',
            render: (bytes) => `${(bytes / BYTES_IN_GIGABYTE).toFixed(2)} GB`,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx("h3", { className: styles.tableTitle, children: txt('storageSpace') }), _jsx(ResponsiveTable, { bordered: true, className: styles.table, columns: columnsStorage, dataSource: teamStorage === 'loading' ? [] : teamStorage.list, rowKey: (teamMember) => teamMember.id, tableView: tableView, recordCount: teamStorage === 'loading' ? 0 : teamStorage.totalCount, loading: teamStorage === 'loading', footer: () => totalStorageFooter(), expandable: {
                    expandedRowRender: (record) => ExpandedContentWithProjects(record.email, record.id, memberProjects, [], false),
                    onExpand: handleRowExpanded,
                    rowExpandable: (member) => member.id !== -1,
                    expandedRowKeys: expandedRowProperties.key,
                }, pageSizeOptions: ['10', '20', '50', '100'] })] }));
};
export default StorageInfoForTeamOwner;
