import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Flex, Popconfirm, } from 'antd';
import { txt } from 'libs/i18n';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { deriveTagColor } from 'libs/tag-style';
import { useState } from 'react';
import IconButton from 'components/IconButton';
import { deleteCurrentTeamTag, updateCurrentTeamTag } from 'api/team-api';
import { useSession } from 'components/Authenticated';
import BeeyTag from 'components/BeeyTag';
const ConfirmDelete = () => (_jsxs("p", { children: [_jsxs("strong", { children: [txt('deleteTag'), ' ', "?"] }), _jsx("br", {}), txt('deleteTagFromAllProjectsPart1'), ' ', _jsx("strong", { children: txt('deleteTagFromAllProjectsPart2') }), _jsx("br", {})] }));
const TagCheckbox = ({ tag, onTagCheckChange, onRefetchProjects, onDeleteTag, }) => {
    const { check } = tag;
    const [modalOpen, setModalOpen] = useState(false);
    const { session } = useSession();
    const handleTagCheckChange = () => {
        const updatedTag = check === 'partial' || check === 'off' ? Object.assign(Object.assign({}, tag), { check: 'on' }) : Object.assign(Object.assign({}, tag), { check: 'off' });
        onTagCheckChange(updatedTag);
    };
    const handleEditTag = async (editedTag) => {
        var _a, _b, _c, _d, _e;
        onRefetchProjects();
        const updatedTag = Object.assign(Object.assign({}, tag), { color: (_a = editedTag.color) !== null && _a !== void 0 ? _a : deriveTagColor(editedTag.title), title: editedTag.title, abbreviation: (_b = editedTag.abbreviation) !== null && _b !== void 0 ? _b : editedTag.title.slice(0, 2), isPinned: editedTag.isPinned, id: tag.id });
        onTagCheckChange(updatedTag);
        await updateCurrentTeamTag(session.connection, tag.id !== undefined ? tag.id : 0, (_c = editedTag.abbreviation) !== null && _c !== void 0 ? _c : editedTag.title.slice(0, 2), (_d = editedTag.color) !== null && _d !== void 0 ? _d : deriveTagColor(editedTag.title), (_e = editedTag.isPinned) !== null && _e !== void 0 ? _e : true);
        setModalOpen(false);
    };
    const handleDeleteTag = async () => {
        await deleteCurrentTeamTag(session.connection, tag.id !== undefined ? tag.id : 0);
        onDeleteTag();
        onRefetchProjects();
    };
    return (_jsxs(Flex, { justify: "space-between", children: [_jsx(BeeyTag, { tag: tag, checked: check === 'on', partiallyChecked: check === 'partial', isCheckable: true, onTagCheckChange: handleTagCheckChange, openAddEditModal: modalOpen, onModalOpen: setModalOpen, modalAction: "edit", onTagEdit: handleEditTag }), _jsxs(Flex, { children: [_jsx(Popconfirm, { title: _jsx(ConfirmDelete, {}), okText: txt('delete'), okType: "primary", okButtonProps: { danger: true }, onConfirm: handleDeleteTag, cancelText: txt('cancel'), children: _jsx(DeleteOutlined, {}) }), _jsx(IconButton, { onClick: () => setModalOpen(true), children: _jsx(EditOutlined, {}) })] })] }));
};
export default TagCheckbox;
