import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from 'react-query';
import { fetchTranscriptionLog } from 'api/user-api';
import { Modal, Button } from 'antd';
import { useSession } from 'components/Authenticated';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import dayjs from 'dayjs';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const USER_PROJECTS_TABLE_ID = 'user-projects-table'; // Must be unique across all tables in Beey!!
const ProjectsOverviewForTeamMember = ({ modalVisible, month, onModalClosed, }) => {
    const { session } = useSession();
    const tableView = useTableView(USER_PROJECTS_TABLE_ID, { pageSize: 10 });
    const { status: transcriptionLogStatus, data: transcriptionLog } = useQuery('projectsList', () => fetchTranscriptionLog(session.connection));
    const monthNumber = month.split('/')[0];
    const selectedList = transcriptionLogStatus === 'success'
        ? transcriptionLog.list
            .filter((item) => dayjs(item.created).format('MM') === monthNumber.padStart(2, '0'))
        : [];
    const columns = [
        {
            title: txt('projectName'),
            dataIndex: 'projectName',
            key: 'projectName',
            align: 'center',
        },
        {
            title: txt('credit'),
            dataIndex: 'transcribedMinutes',
            key: 'transcribedMinutes',
            align: 'center',
            width: '15%',
            render: (minutes) => Math.round(minutes),
        },
        {
            title: txt('creationDate'),
            dataIndex: 'created',
            key: 'created',
            align: 'center',
            width: '30%',
            render: (date) => dayjs(date).format('DD.MM. YYYY'),
        },
    ];
    return (_jsxs(Modal, { open: modalVisible, className: styles.projectsOverview, onCancel: onModalClosed, footer: (_jsx(Button, { onClick: onModalClosed, children: txt('close') })), children: [_jsxs("h4", { children: [txt('projectOverview'), ' ', month] }), _jsx(ResponsiveTable, { tableView: tableView, className: styles.table, bordered: true, columns: columns, dataSource: transcriptionLogStatus === 'loading' ? [] : selectedList, rowKey: (item) => item.projectId, recordCount: transcriptionLogStatus === 'loading' ? 0 : selectedList.length, loading: transcriptionLogStatus === 'loading', paginationDisplaySize: "small", pageSizeOptions: ['5', '10', '20'] })] }));
};
export default ProjectsOverviewForTeamMember;
