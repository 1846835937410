import dayjs from 'dayjs';
import { BeeyLocale } from 'libs/locales/locale';
export const mediaHasVideo = (mediaInfo) => (mediaInfo !== null && mediaInfo.hasVideo);
export const mediaIsPackaged = (project) => (
// NOTE: This logic would be better, but we cannot use it, because
// backend sometimes fills wrong value in isPackaged.
// mediaInfo !== null && mediaInfo.isPackaged
project.recordingId !== null);
export const mediaIsStreaming = (project) => (project.indexFileId !== null);
export const createProjectFromApi = (apiProject) => {
    const start = apiProject.start === null ? null : dayjs(apiProject.start);
    if (apiProject.transcriptionConfig === null) {
        return Object.assign(Object.assign({}, apiProject), { start, transcriptionConfig: null });
    }
    const projectLanguage = BeeyLocale.const(apiProject.transcriptionConfig.language);
    return Object.assign(Object.assign({}, apiProject), { start, transcriptionConfig: Object.assign(Object.assign({}, apiProject.transcriptionConfig), { language: projectLanguage }) });
};
export const isTranscriptionFinished = (project) => (project.currentTrsxId !== null || project.initialTrsxId !== null);
