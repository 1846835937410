import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, } from 'react';
import { Button, Form, Checkbox, } from 'antd';
import './style.less';
import { txt } from 'libs/i18n';
import BeeyTag from 'components/BeeyTag';
const TagsFilter = ({ onTagsFiltered, onTagsCanceled, selectedTags, onDropdownVisibility, teamTags, }) => {
    const [filteredTags, setFilteredTags] = useState([]);
    const [form] = Form.useForm();
    const tags = teamTags.map((tag) => ({
        title: tag.tag,
        key: tag.tag,
        color: tag.color,
        showFullVersion: true,
    }));
    useEffect(() => {
        if (selectedTags.length === 0) {
            setFilteredTags([]);
            form.setFieldsValue({ tags: [], allTags: false });
        }
    }, [selectedTags]);
    const handleCheckAll = (e) => {
        if (e.target.checked) {
            form.setFieldsValue({ tags: teamTags.map((tag) => tag.tag) });
            setFilteredTags(teamTags.map((tag) => tag.tag));
        }
        else {
            form.setFieldsValue({ tags: [] });
            setFilteredTags([]);
        }
    };
    const handleFilterTags = () => {
        onTagsFiltered(filteredTags);
        onDropdownVisibility(false);
    };
    const handleClearFilter = () => {
        onTagsCanceled();
        onDropdownVisibility(false);
        form.setFieldsValue({ tags: [], allTags: false });
    };
    return (_jsx("div", { className: "saved-tags", children: _jsxs("div", { className: "saved-tags__list", children: [_jsxs(Form, { form: form, initialValues: {
                        tags: selectedTags,
                        allTags: false,
                    }, children: [_jsx(Form.Item, { name: "allTags", valuePropName: "checked", children: _jsx(Checkbox, { className: "saved-tags__all", onChange: handleCheckAll, children: txt('checkAll') }) }), _jsx(Form.Item, { name: "tags", children: _jsx(Checkbox.Group, { className: "saved-tags__checkboxes", onChange: (tgs) => {
                                    setFilteredTags([...filteredTags, ...tgs]);
                                }, children: tags.map((tag) => (_jsx(Checkbox, { value: tag.key, children: _jsx(BeeyTag, { tag: tag, isCheckable: false }) }, tag.key))) }) })] }), _jsxs("div", { className: "saved-tags__buttons", children: [_jsx(Button, { size: "small", type: "link", onClick: handleClearFilter, children: txt('clearFilter') }), _jsx(Button, { type: "primary", size: "small", onClick: handleFilterTags, children: txt('filter') })] })] }) }));
};
export default TagsFilter;
