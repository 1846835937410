import { apiV2, wsV1 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { json, text, blob, } from '@newtontechnologies/beey-api-js-client/receivers';
import SpeakerSuggestionWebSocket from './speaker-suggestion-websocket';
export const createSuggestionWebSocket = async (connection) => new SpeakerSuggestionWebSocket(`${wsV1.speakerSuggestion.url()}?Authorization=${await connection.retrieveAuthString()}`);
export const addSpeaker = async (connection, speakerXml) => connection.authFetch()
    .url(apiV2.speakers.url())
    .post(speakerXml)
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const fetchSpeaker = async (connection, speakerId) => connection.authFetch()
    .url(apiV2.speakers.id(speakerId).url())
    .receive(text()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const updateSpeaker = async (connection, speakerXml) => connection.authFetch()
    .url(apiV2.speakers.url())
    .put(speakerXml)
    .receive(text()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const deleteSpeaker = async (connection, speakerId) => connection.authFetch()
    .url(apiV2.speakers.id(speakerId).url())
    .delete()
    .receive(text()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const listSpeakers = async (connection, skip, count, prefix) => connection.authFetch()
    .url(apiV2.speakers.url({ skip, count, search: prefix }))
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const fetchSpeakerRoleSuggestions = async (connection, prefix, count = 100) => connection.authFetch()
    .url(apiV2.speakers.roles.url({ skip: 0, count, search: prefix }))
    .receive(json({ camelcaseResponse: false }))
    .fetch();
export const addSpeakerRole = async (connection, role) => connection.authFetch()
    .url(apiV2.speakers.roles.url({ value: role }))
    .post()
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const fetchSpeakerRoleList = async (connection) => connection.authFetch()
    .url(apiV2.speakers.roles.url())
    .receive(json({ camelcaseResponse: false })
    .map((response) => response.map((role) => ({
    role: role.Value,
    key: role.Id,
})))
    .finally(() => 'unexpected'))
    .fetchStrict();
export const fetchSpeakerRoleSuggest = async (connection) => connection.authFetch()
    .url(apiV2.speakers.roles.suggest.url())
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const updateSpeakerRole = async (connection, roleId, newRole) => connection.authFetch()
    .url(apiV2.speakers.roles.id(roleId).url({ value: newRole }))
    .put()
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const deleteSpeakerRole = async (connection, roleId) => connection.authFetch()
    .url(apiV2.speakers.roles.id(roleId).url())
    .delete()
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
export const exportSpeakers = async (connection) => connection.authFetch()
    .url(apiV2.speakers.export.url())
    .receive(blob())
    .fetch();
export const exportRoles = async (connection) => connection.authFetch()
    .url(apiV2.speakers.roles.export.url())
    .receive(blob())
    .fetch();
export const saveVoiceSample = async (connection, projectId, speakerId, sampleStart, sampleLength) => connection.authFetch()
    .url(apiV2.speakers.voiceSamples.cutNew.url())
    .postJson({
    ProjectId: projectId,
    SpeakerId: speakerId,
    StartSec: sampleStart,
    LengthSec: sampleLength,
})
    .receive(json()
    .catchHttpError((response) => (response.status === 422 ? { error: 'invalid-id' } : 'unknown'))
    .finally(() => 'unexpected'))
    .fetchStrict();
export const fetchVoiceSamples = async (connection, speakerId) => connection.authFetch()
    .url(apiV2.speakers.voiceSamples.url({ speakerId }))
    .receive(json())
    .fetch();
export const fetchVoiceSampleMedia = async (connection, sampleId) => connection.authFetch()
    .url(apiV2.speakers.voiceSamples.id(sampleId).data.url())
    .receive(blob())
    .fetch();
export const deleteVoiceSample = async (connection, sampleId) => connection.authFetch()
    .url(apiV2.speakers.voiceSamples.id(sampleId).url())
    .delete()
    .receive(json()
    .finally(() => 'unexpected'))
    .fetchStrict();
