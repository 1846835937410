import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Popconfirm, Tooltip, Form, Input, App, } from 'antd';
import { SectionTagIcon } from 'components/CustomIcons';
import IconButton from 'components/IconButton';
import { EditOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import ResponsiveTable, { useTableView, empiricalScrollHeightAdjust, } from 'components/ResponsiveTable';
import AddEditModal from 'components/AddEditModal';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import AddButton from 'components/AddButton';
import BeeyTag from 'components/BeeyTag';
import { deriveTagColor } from 'libs/tag-style';
import * as TeamAPI from 'api/team-api';
import { nanoid } from 'nanoid';
import TagFormItems from 'components/AddEditModal/TagFormItems';
import styles from './style.module.less';
const TAGS_TABLE_ID = 'tags-table'; // Must be unique across all tables in Beey!!
const SCROLL_HEIGHT_ADJUST = empiricalScrollHeightAdjust(175);
const TagsAdministration = ({ contentHeight, tagsType, tags, totalCount, onUpdateTags, }) => {
    var _a;
    const { session, updateTeamSettings } = useSession();
    const { message } = App.useApp();
    const tableView = useTableView(TAGS_TABLE_ID, {
        pageSize: 10,
    });
    const [modal, setModal] = useState({
        action: 'add',
        visible: false,
        initialValues: null,
    });
    const { currentPage, pageSize } = tableView.getViewParams();
    const modalTitle = (_jsxs(_Fragment, { children: [_jsx(SectionTagIcon, { className: styles.icon }), modal.action === 'add' ? txt('addNewTag') : txt('editTag')] }));
    const openAddModal = () => {
        setModal({ action: 'add', visible: true, initialValues: null });
    };
    const handleAddTag = async (tag) => {
        var _a, _b, _c;
        if (tagsType === 'section' && tags !== 'loading') {
            updateTeamSettings({
                sectionTags: [{
                        key: nanoid(5), title: tag.title,
                    }, ...tags],
            });
        }
        else {
            const result = await TeamAPI.addCurrentTeamTag(session.connection, tag.title, (_a = tag.abbreviation) !== null && _a !== void 0 ? _a : null, (_b = tag.color) !== null && _b !== void 0 ? _b : null, (_c = tag.isPinned) !== null && _c !== void 0 ? _c : true);
            if (result.isSuccess()) {
                void (onUpdateTags === null || onUpdateTags === void 0 ? void 0 : onUpdateTags(pageSize, currentPage));
                void message.success(txt('tagAddedSuccessfully'));
            }
            else {
                void message.error(txt('failedToAddTag'));
            }
        }
    };
    const handleEditTag = async (editedTag) => {
        var _a, _b, _c;
        if (tagsType === 'section' && tags !== 'loading') {
            updateTeamSettings({
                sectionTags: tags.map((tag) => (tag.key === editedTag.key
                    ? { key: editedTag.key, title: editedTag.title }
                    : { key: tag.key, title: tag.title })),
            });
        }
        else {
            const result = await TeamAPI.updateCurrentTeamTag(session.connection, Number(editedTag.key), (_a = editedTag.abbreviation) !== null && _a !== void 0 ? _a : editedTag.title.slice(0, 2), (_b = editedTag.color) !== null && _b !== void 0 ? _b : deriveTagColor(editedTag.title), (_c = editedTag.isPinned) !== null && _c !== void 0 ? _c : true);
            if (result.isSuccess()) {
                void (onUpdateTags === null || onUpdateTags === void 0 ? void 0 : onUpdateTags(pageSize, currentPage));
                void message.success(txt('tagEdittedSuccessfully'));
            }
            else {
                void message.error(txt('failedToEditTag'));
            }
        }
    };
    const handleDeleteTag = async (tag) => {
        if (tagsType === 'section' && tags !== 'loading') {
            updateTeamSettings({
                sectionTags: tags.filter((t) => t.key !== tag.key),
            });
        }
        else {
            const result = await TeamAPI.deleteCurrentTeamTag(session.connection, Number(tag.key));
            if (result.isSuccess()) {
                const isLastTag = totalCount !== undefined ? totalCount % pageSize === 1 : false;
                if (currentPage > 1 && isLastTag) {
                    tableView.setCurrentPage(currentPage - 1);
                }
                void (onUpdateTags === null || onUpdateTags === void 0 ? void 0 : onUpdateTags(pageSize, currentPage));
                void message.success(txt('tagDeletedSuccessfully'));
            }
            else {
                void message.error(txt('failedToDeleteTag'));
            }
        }
    };
    const openEditModal = (record) => {
        var _a;
        const { title, key, abbreviation, isPinned, } = record;
        const color = (_a = record.color) !== null && _a !== void 0 ? _a : deriveTagColor(title);
        setModal({
            action: 'edit',
            visible: true,
            initialValues: {
                title,
                key,
                abbreviation: abbreviation === null ? title.slice(0, 2) : abbreviation,
                color,
                isPinned,
            },
        });
    };
    const allColumns = [
        {
            title: txt(`${tagsType}Tags`),
            key: 'Title',
            dataIndex: 'title',
            className: 'name-cell',
            render: (tag, record) => (tagsType === 'project'
                ? (_jsx(BeeyTag, { tag: record, isCheckable: false }))
                : _jsx("span", { children: tag })),
        },
        {
            title: txt('abbreviation'),
            key: 'Abbreviation',
            dataIndex: 'abbreviation',
            render: (abbreviation, record) => abbreviation !== null && abbreviation !== void 0 ? abbreviation : record.title.slice(0, 2),
        },
        {
            title: txt('pinnedOnProject'),
            key: 'IsPinned',
            dataIndex: 'isPinned',
            align: 'center',
            render: (isPinned) => (isPinned
                ? _jsx(CheckCircleOutlined, { style: { color: '#52C41A' } })
                : null),
        },
        {
            title: '',
            key: 'Edit',
            width: '40px',
            render: (record) => {
                return (_jsx(IconButton, { onClick: () => openEditModal(record), children: _jsx(EditOutlined, {}) }));
            },
        },
        {
            title: '',
            key: 'Delete',
            width: '40px',
            render: (record) => {
                return (_jsx(Popconfirm, { title: txt('deletePermanently'), okText: txt('delete'), okType: "primary", okButtonProps: { danger: true }, onConfirm: () => handleDeleteTag(record), cancelText: txt('cancel'), children: _jsx(Tooltip, { title: txt('delete'), children: _jsx(IconButton, { danger: true, children: _jsx(DeleteOutlined, {}) }) }) }));
            },
        },
    ];
    const columns = tagsType === 'project' ? allColumns : allColumns.filter((column) => column.key !== 'Abbreviation' && column.key !== 'IsPinned');
    return (_jsxs("div", { id: tagsType === 'project' ? 'table-project-tags' : 'table-editor-tags', children: [_jsx(AddButton, { title: txt('addNewTag'), onClick: () => openAddModal(), id: `${tagsType}Tag` }), _jsx(ResponsiveTable, { onViewChange: () => {
                    if (tagsType === 'project') {
                        void (onUpdateTags === null || onUpdateTags === void 0 ? void 0 : onUpdateTags(pageSize, currentPage));
                    }
                }, pagination: tagsType === 'project' ? {} : false, bordered: false, className: styles.table, columns: columns, loading: tags === 'loading', dataSource: tags !== 'loading' ? tags : [], rowKey: (tagList) => tagList.title, tableView: tableView, recordCount: totalCount, scroll: { y: contentHeight - SCROLL_HEIGHT_ADJUST }, stripeRows: true }), _jsx(AddEditModal, { action: modal.action, visible: modal.visible, title: modalTitle, initialValues: modal.initialValues, labelColSpan: 10, wrapperColSpan: 14, onCancel: () => setModal(Object.assign(Object.assign({}, modal), { visible: false })), onAdd: (values) => handleAddTag(values), onEdit: (values) => handleEditTag(values), children: tagsType === 'project' ? (_jsx(TagFormItems, { editedTag: (_a = modal.initialValues) !== null && _a !== void 0 ? _a : undefined, action: modal.action })) : (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: txt('name'), name: "title", rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: txt('noTagName'),
                                },
                                {
                                    validator: async (_, value) => {
                                        if (modal.action === 'edit') {
                                            return Promise.resolve();
                                        }
                                        if (value !== undefined) {
                                            const duplicitTag = tags !== 'loading' ? tags.find((tag) => tag.title.trim() === value.trim()) : undefined;
                                            if (duplicitTag !== undefined) {
                                                return Promise.reject(txt('duplicitTag'));
                                            }
                                            if (value.trim().startsWith('#')) {
                                                return Promise.reject(txt('startsWithHash'));
                                            }
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ], children: _jsx(Input, { autoFocus: true }) }), _jsx(Form.Item, { className: styles.formItemHidden, name: "key", children: _jsx(Input, { type: "hidden" }) })] })) })] }));
};
export default TagsAdministration;
